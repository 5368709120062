import { COLOR_PLACEMENT } from '@ui/types/types';
import styled from 'styled-components';

export const CstContainer = styled.div`
    margin: 35px 0 0 0;
    display: flex;
    align-items: center;
    gap: 20px;
`;

interface CstTabProps {
    $isSelected: boolean;
}

export const CstTab = styled.div<CstTabProps>`
    padding: 0 0 6px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 26px;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: ${(props) =>
        props.$isSelected ? '#2a2a30' : 'transparent'};

    transition: 300ms all;
`;

export const CstTabIcon = styled.div`
    font-size: 40px;
`;

export const CstTabText = styled.div`
    font-size: 12px;
    color: #2a2a30;
    font-family: 'Poppins-SemiBold';
`;

export const CstTrueColor = styled.div`
    height: 53px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const CstTrueColor2 = styled.div`
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface CstTrueColorCircleProps {
    $backgroundColor: string;
}

export const CstTrueColorCircle = styled.div<CstTrueColorCircleProps>`
    width: 40px;
    height: 40px;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    border-radius: 50%;
`;

interface CstCircleProps {
    $trueColor: string;
    $contrastColor: string;
    $colorPlacement: COLOR_PLACEMENT;
}

export const CstTrueColorCircle2 = styled.div<CstCircleProps>`
    position: relative;
    width: 40px;
    height: 40px;
    background-color: ${({ $contrastColor, $trueColor, $colorPlacement }) =>
        $colorPlacement === COLOR_PLACEMENT.FOREGROUND
            ? $contrastColor
            : $trueColor};
    border-radius: 50%;

    &::after {
        content: '';
        width: 20px;
        height: 20px;
        background-color: ${({ $contrastColor, $trueColor, $colorPlacement }) =>
            $colorPlacement === COLOR_PLACEMENT.FOREGROUND
                ? $trueColor
                : $contrastColor};
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
