import styled from 'styled-components';

export const NpPage = styled.div`
    padding: 100px 0;
    min-height: 100vh;
`;

export const NpHeader = styled.h3`
    font-family: 'Poppins-SemiBold';
    color: #2a2a30;
`;

export const NpItem = styled.div`
    padding: 20px;
    border: 1px solid black;
    border-radius: 4px;

    font-size: 16px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';
`;

export const NpContainer = styled.div`
    margin: 27px 0 0 0;
    padding: 40px;
    border: 1px solid #e7e7e7;
    min-height: 100vh;
    border-radius: 24px;
    background-color: #ffffff;

    ${NpItem}:not(:first-child) {
        margin: 20px 0 0 0;
    }
`;
