/* eslint-disable @typescript-eslint/no-explicit-any */
import AuthInfo from '@ui/types/AuthInfo';
import LoginData, {
    PasswordResetRequestData,
    RegisterData,
    PasswordResetData,
    UpdatePasswordData,
} from '@ui/types/LoginData';
import { api } from './api';

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        authlogin: builder.mutation<AuthInfo, LoginData>({
            query: (loginData) => ({
                url: 'users/login',
                method: 'POST',
                body: loginData,
            }),
        }),
        authrefresh: builder.mutation<AuthInfo, AuthInfo>({
            query: (authInfo) => ({
                url: 'users/refresh',
                method: 'POST',
                body: authInfo,
            }),
        }),

        // authrevoke requires a authorization header to be set
        // (not currently in use)
        authrevoke: builder.mutation<null, null>({
            query: () => ({
                url: 'users/revoke',
                method: `POST`,
            }),
        }),

        authRegister: builder.mutation<AuthInfo, RegisterData>({
            query: (registerData) => ({
                url: 'users/signup',
                method: 'POST',
                body: registerData,
            }),
        }),

        passwordResetRequest: builder.mutation<any, PasswordResetRequestData>({
            query: (passwordResetRequestData) => ({
                url: `users/passwordResetRequest`,
                method: 'POST',
                body: passwordResetRequestData,
            }),
        }),

        resetOldPassword: builder.mutation<any, PasswordResetData>({
            query: (resetOldPassword) => ({
                url: 'users/passwordReset',
                method: 'POST',
                body: resetOldPassword,
            }),
        }),

        updatePassword: builder.mutation<any, UpdatePasswordData>({
            query: (updatePassword) => ({
                url: 'users/changePassword',
                method: 'POST',
                body: updatePassword,
            }),
        }),
    }),
});

export const {
    useAuthloginMutation,
    useAuthrefreshMutation,
    useAuthrevokeMutation,
    useAuthRegisterMutation,
    usePasswordResetRequestMutation,
    useResetOldPasswordMutation,
    useUpdatePasswordMutation,
} = authApi;
