import { CreateHue, EditHue, Hue } from '@ui/types/Hue';
import { UserCreatedHue } from '@ui/types/types';
import { api } from './api';

export const hueApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getHues: builder.query<UserCreatedHue[], void>({
            query: () => ({ url: `hues/getHuesByUser` }),
            providesTags: (result = []) => [
                ...result.map(({ id }) => ({ type: 'Hues', id } as const)),
                { type: 'Hues', id: 'LIST' },
            ],
        }),
        getHueById: builder.query<Hue, number>({
            query: (id) => ({ url: `hues/${id}` }),
            providesTags: (_hue, _error, id) => [{ type: 'Hues', id }],
        }),
        addHue: builder.mutation<Hue, CreateHue>({
            query: (hue) => ({
                url: `hues`,
                method: 'POST',
                body: hue,
            }),
            invalidatesTags: [{ type: 'Hues', id: 'LIST' }],
        }),
        updateHue: builder.mutation<void, EditHue>({
            query: (hue) => ({
                url: `hues/${hue['id']}`,
                method: 'PUT',
                body: hue,
            }),
            invalidatesTags: (_, _error, hue) => [{ type: 'Hues', id: hue.id }],
        }),
        deleteHue: builder.mutation<void, number>({
            query: (id) => ({ url: `hues/${id}`, method: 'DELETE' }),
            invalidatesTags: (_, _error, id) => [
                { type: 'Hues', id },
                { type: 'Hues', id: 'LIST' },
            ],
        }),
    }),
});

export const {
    useGetHuesQuery,
    useGetHueByIdQuery,
    useAddHueMutation,
    useDeleteHueMutation,
    useUpdateHueMutation,
} = hueApi;
