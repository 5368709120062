import styled from 'styled-components';

export const CcpContainer = styled.div`
    margin-top: 9px;
`;

export const CcpHeader = styled.div`
    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-Bold';
`;

export const TagContainer = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 8px;

    flex-wrap: wrap;
`;

export const EditHueDropdown = styled.div`
    width: 242px;
`;

export const EditHueTag = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    color: #2a2a30;
    padding: 8px;
    background: rgba(0, 0, 0, 0.02);
    border: 1px solid #d9d9d9;
    border-radius: 4px;
`;
