import { Toaster } from 'react-hot-toast';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoginPage } from '@ui/pages/LoginPage/LoginPage';
import { RegistrationPage } from '@ui/pages/RegistrationPage';
import { ForgotPassword } from '@ui/pages/ForgotPassword/pages/ForgotPassword';
import { ResetPassword } from '@ui/pages/ForgotPassword/pages/ResetPassword';
import { DashboardPage } from '@ui/pages/DashboardPage';
import { CreateHuePage } from '@ui/pages/CreateHuePage';
import { Team } from '@ui/pages/Team';
import { ProfilePage } from '@ui/pages/ProfilePage/pages';
import { ProjectPage } from '@ui/pages/ProjectPage/pages/Project';
import { CreateNewProject } from '@ui/pages/ProjectPage/pages/CreateProject';
import { Help } from '@ui/pages/Help';
import { SidebarLayout } from '@ui/components/SidebarLayout';
import { ColorLayout } from '@ui/components/ColorLayout';
import { AuthLayout } from '@ui/components/AuthLayout';
import { ColorSettingsPage } from '@ui/pages/ColorStudio/pages/ColorSettingsPage';
import { NotificationsPage } from '@ui/pages/NotificationsPage';
import { ColorRules } from '@ui/pages/ColorStudio/pages/ColorRules';
import { LoginPersistLayout } from '@ui/components/LoginPersistLayout';
import { ROUTES } from '@ui/types/types';

const router = createBrowserRouter([
    {
        element: <LoginPersistLayout />,
        children: [
            {
                path: ROUTES.ROOT,
                element: <LoginPage />,
            },
            {
                path: ROUTES.SIGN_UP,
                element: <RegistrationPage />,
            },
            {
                path: ROUTES.FORGOT_PASSWORD,
                element: <ForgotPassword />,
            },
            {
                path: ROUTES.PASSWORD_RESET,
                element: <ResetPassword />,
            },

            {
                element: <AuthLayout />,
                children: [
                    {
                        element: <SidebarLayout />,
                        children: [
                            {
                                path: ROUTES.DASHBOARD,
                                element: <DashboardPage />,
                            },
                            {
                                path: ROUTES.CREATE_HUE,
                                element: <CreateHuePage />,
                            },
                            {
                                path: ROUTES.PROFILE,
                                element: <ProfilePage />,
                            },
                            {
                                path: ROUTES.TEAM,
                                element: <Team />,
                            },

                            {
                                path: `${ROUTES.PROJECT}/:projectId`,
                                element: <ProjectPage />,
                            },
                            {
                                path: `${ROUTES.CREATE_PROJECT}`,
                                element: <CreateNewProject />,
                            },

                            {
                                path: ROUTES.NOTIFICATIONS,
                                element: <NotificationsPage />,
                            },
                            {
                                path: ROUTES.HELP,
                                element: <Help />,
                            },
                        ],
                    },
                    {
                        element: <ColorLayout />,
                        children: [
                            {
                                path: `${ROUTES.COLOR_STUDIO}/:projectId/:paletteId/:hueId`,
                                element: <ColorSettingsPage />,
                            },
                            {
                                path: `${ROUTES.COLOR_STUDIO}/:projectId/:paletteId/:hueId/rules`,
                                element: <ColorRules />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);

const App = () => {
    return (
        <div>
            <Toaster />
            <div className="App">
                <RouterProvider router={router} />
            </div>
        </div>
    );
};

export default App;
