import styled from 'styled-components';

export const CnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
`;

export const CnName = styled.div`
    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-SemiBold';
`;

export const CnEditText = styled.div`
    cursor: pointer;
    font-size: 14px;
    color: blue;
    font-family: 'Poppins-Regular';
    text-decortation: underline;
`;

export const CnEditNameInput = styled.div`
    position: relative;
    width: 400px;
    height: 42px;
    border: 1px solid #2a2a30;
    border-radius: 2px;
    transition: 300ms all;

    &:hover {
        border-color: #4096ff;
    }

    &:focus-within {
        border-color: #4096ff;
        box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
        outline: 0;
    }

    input {
        width: 100%;
        padding: 12px 14px 12px 14px;
        color: #2a2a30;
        font-family: 'Poppins-Medium';
        font-size: 14px;

        background-color: transparent;
        border: 0;

        &:focus {
            border: 0;
            border-color: transparent;
            outline: 0;
        }

        &::placeholder {
            color: #2a2a30;
            font-family: 'Poppins-Medium';
            font-size: 14px;
        }
    }
`;
