import * as Styles from './style';

interface IButtonProps {
    type?: 'submit' | 'button';
    isDisabled?: boolean;
    children: React.ReactNode;
    onClick?: () => void;
    className?: string;
}

export const Button = (props: IButtonProps) => {
    const { type, isDisabled, children, onClick, ...rest } = props;

    return (
        <Styles.PrimaryButton
            type={type ?? 'button'}
            disabled={isDisabled}
            {...rest}
            onClick={() => onClick?.()}
        >
            {children}
        </Styles.PrimaryButton>
    );
};

export const SecondaryButton = (props: IButtonProps) => {
    const { type, isDisabled, children, onClick, ...rest } = props;

    return (
        <Styles.SecondaryButton
            type={type ?? 'button'}
            disabled={isDisabled}
            {...rest}
            onClick={() => onClick?.()}
        >
            {children}
        </Styles.SecondaryButton>
    );
};
