import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLOR_SETTINGS_TABS } from '@ui/types/types';
import * as Styles from './style';
import { useAppSelector } from '@ui/app/hooks';

interface ColorStudioTabProps {
    activeTab: COLOR_SETTINGS_TABS;
    handleTabChange: (value: COLOR_SETTINGS_TABS) => void;
}

export const ColorStudioTab = (props: ColorStudioTabProps) => {
    const { activeTab, handleTabChange } = props;

    const { trueColor, contrastColor, colorPlacement, aaHexOL, aaaHexOL } =
        useAppSelector((state) => state.colorStudio);

    return (
        <Styles.CstContainer>
            <Styles.CstTab
                $isSelected={activeTab === COLOR_SETTINGS_TABS.SETTINGS}
                onClick={() => handleTabChange(COLOR_SETTINGS_TABS.SETTINGS)}
            >
                <Styles.CstTabIcon>
                    <FontAwesomeIcon icon={faGear} className="icon" />
                </Styles.CstTabIcon>

                <Styles.CstTabText>SETTINGS</Styles.CstTabText>
            </Styles.CstTab>
            <Styles.CstTab
                $isSelected={activeTab === COLOR_SETTINGS_TABS.TRUE_COLOR}
                onClick={() => handleTabChange(COLOR_SETTINGS_TABS.TRUE_COLOR)}
            >
                {aaHexOL || aaaHexOL ? (
                    <Styles.CstTrueColor>
                        <Styles.CstTrueColorCircle
                            $backgroundColor={trueColor.hex}
                        />

                        <Styles.CstTrueColorCircle2
                            $trueColor={trueColor.hex}
                            $contrastColor={contrastColor.hex}
                            $colorPlacement={colorPlacement}
                        />
                    </Styles.CstTrueColor>
                ) : (
                    <Styles.CstTrueColor2>
                        <Styles.CstTrueColorCircle
                            $backgroundColor={trueColor.hex}
                        />
                    </Styles.CstTrueColor2>
                )}

                <Styles.CstTabText>TC</Styles.CstTabText>
            </Styles.CstTab>
        </Styles.CstContainer>
    );
};
