import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch } from '@ui/app/hooks';
import { useAddProjectMutation } from '@ui/services/project';
import { resetNewHue } from '@ui/slices/newHueSlice';
import RightArrow from '@ui/images/right-arrow-icon.svg';
import CreateProjectIcon from '@ui/images/create-project-icon.svg';
import { TextInput } from '@ui/components/TextInput';
import { BtnLoader } from '@ui/components/Loaders';

import * as Styles from './style';

interface CreateProjectData {
    projectName: string;
}

const initialValues: CreateProjectData = {
    projectName: '',
};

const validationSchema = Yup.object().shape({
    projectName: Yup.string()
        .required('Name is required')
        .min(2, 'Name is too short')
        .max(40, 'Name is too long'),
});

export const CreateNewProject = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [apiAddProject, { isLoading }] = useAddProjectMutation();

    const handleCreateProject = async (values: CreateProjectData) => {
        const { projectName } = values;

        try {
            await apiAddProject({
                name: projectName,
            }).unwrap();

            toast.success('Project created');
        } catch (err) {
            toast.error('Something went wrong');
        }
    };

    const goBack = () => {
        navigate('/dashboard');
    };

    useEffect(() => {
        dispatch(resetNewHue());
    }, []);

    return (
        <Styles.ChpContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleCreateProject}
            >
                {() => (
                    <Form>
                        <Styles.ChpHeader>
                            Craft Your New Project: Embark on Your <br />{' '}
                            Creative Journey
                        </Styles.ChpHeader>
                        <Styles.ChpSubHeader>
                            Enter a project name
                        </Styles.ChpSubHeader>

                        <Styles.ChpLine />

                        <div>
                            <Styles.ChpLabelContainer>
                                <Styles.ChpLabelBox>
                                    <img
                                        src={CreateProjectIcon}
                                        alt="project"
                                    />
                                </Styles.ChpLabelBox>
                                <Styles.ChpLabelText htmlFor="projectName">
                                    Your Project Name
                                </Styles.ChpLabelText>
                            </Styles.ChpLabelContainer>

                            <Styles.ChpFormInputContainer>
                                <div>
                                    <Styles.ChpTextInput>
                                        <TextInput
                                            type="text"
                                            name="projectName"
                                            id="projectName"
                                            placeholder="Enter Project Name"
                                            showIcon={false}
                                        />
                                    </Styles.ChpTextInput>
                                </div>
                                <div></div>
                                <div></div>
                            </Styles.ChpFormInputContainer>

                            <Styles.CpErrors>
                                <ErrorMessage
                                    name="projectName"
                                    component="div"
                                />
                            </Styles.CpErrors>
                        </div>

                        <Styles.ChpLine />

                        <Styles.ChpBtnContainer>
                            <Styles.ChpCancelBtn type="button" onClick={goBack}>
                                Cancel
                            </Styles.ChpCancelBtn>

                            <Styles.ChpCreateBtn
                                type="submit"
                                isDisabled={isLoading}
                            >
                                {isLoading ? (
                                    <BtnLoader />
                                ) : (
                                    <>
                                        <div>Create</div>
                                        <img src={RightArrow} alt="arrow" />
                                    </>
                                )}
                            </Styles.ChpCreateBtn>
                        </Styles.ChpBtnContainer>
                    </Form>
                )}
            </Formik>
        </Styles.ChpContainer>
    );
};

CreateNewProject.displayName = 'NewProjectPage';
