import NotFoundIcon from '@ui/images/not-found-icon.svg';
import * as Styles from './style';

interface NotFoundProps {
    header?: string;
    subHeader?: string;
}

export const NotFound = (props: NotFoundProps) => {
    const { header, subHeader } = props;

    return (
        <Styles.NfContainer>
            <img src={NotFoundIcon} alt="not found" />

            <Styles.NfHeader>{header ?? 'Not found'}</Styles.NfHeader>
            <Styles.NfSubHeader>
                {subHeader ?? 'We were not able to find'}
            </Styles.NfSubHeader>
        </Styles.NfContainer>
    );
};
