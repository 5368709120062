import { BtnLoader } from '@ui/components/Loaders';
import * as Styles from './style';

interface DeletePaletteProps {
    handleCancel: () => void;
    handleConfirm: () => void;
    isDeleting: boolean;
}

export const DeletePalette = (props: DeletePaletteProps) => {
    const { handleConfirm, handleCancel, isDeleting } = props;

    return (
        <Styles.DpContainer>
            <Styles.DpHeader>Delete Palette</Styles.DpHeader>

            <Styles.DpLine />

            <Styles.DpConfirm>
                Are you sure you want to delete palette
            </Styles.DpConfirm>

            <Styles.DpBtns>
                <Styles.DpBtn onClick={handleCancel}>Cancel</Styles.DpBtn>
                <Styles.DpDeleteBtn
                    onClick={handleConfirm}
                    isDisabled={isDeleting}
                >
                    {isDeleting ? <BtnLoader /> : 'Confirm'}
                </Styles.DpDeleteBtn>
            </Styles.DpBtns>
        </Styles.DpContainer>
    );
};
