import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import * as Styles from './style';
import { useState } from 'react';
import { CustomDropdown } from '@ui/components/CustomDropdown';
import { CSS_PROPERTIES } from '../../../../data/css';
import { addCssProperty, removeCssProperty } from '@ui/slices/colorStudioSlice';

export const ColorCssProperties = () => {
    const [inputVisible, setInputVisible] = useState(false);

    const { cssProperties } = useAppSelector((state) => state.colorStudio);

    const dispatch = useAppDispatch();

    const handleCssPropertySelect = (value: string | number) => {
        if (typeof value === 'string') {
            if (!cssProperties.includes(value)) {
                dispatch(addCssProperty(value));
            }
        }

        setInputVisible(false);
    };

    const removeTag = (value: string) => {
        dispatch(removeCssProperty(value));
    };

    const showInput = () => {
        setInputVisible(true);
    };

    console.log('cssProperties ', cssProperties);

    return (
        <Styles.CcpContainer>
            <Styles.CcpHeader>CSS Properties:</Styles.CcpHeader>
            <Styles.TagContainer>
                {cssProperties.map((property) => (
                    <Styles.EditHueTag
                        key={property}
                        onClick={() => removeTag(property)}
                    >
                        <span>{property}</span>
                        <CloseOutlined />
                    </Styles.EditHueTag>
                ))}
                {inputVisible ? (
                    <Styles.EditHueDropdown>
                        <CustomDropdown
                            placeholder="Select Css Property"
                            options={CSS_PROPERTIES}
                            onChange={handleCssPropertySelect}
                        />
                    </Styles.EditHueDropdown>
                ) : (
                    <Styles.EditHueTag onClick={showInput}>
                        <span>New Property</span>
                        <PlusOutlined />
                    </Styles.EditHueTag>
                )}
            </Styles.TagContainer>
        </Styles.CcpContainer>
    );
};
