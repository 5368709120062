import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Chart from 'react-apexcharts';

import { HueCard } from '@ui/components/HueCard/HueCard';
import { useGetHuesQuery } from '@ui/services/hue';
import { useGetProjectsQuery } from '@ui/services/project';
import RightArrow from '@ui/images/right-arrow-icon.svg';

import { useGetProfileQuery } from '@ui/services/profile';
import { useGetAdditionalUsersQuery } from '@ui/services/team';

import { NotFound } from '@ui/components/NotFound';
import { useGetTotalApiCallsQuery } from '@ui/services/stats';
import { HueLoader } from '@ui/components/Loaders/HueLoader';
import * as Styles from './style';
import { ProjectItemLoader } from '@ui/components/Loaders/ProjectItemLoader';
import { ROUTES } from '@ui/types/types';

interface DateGraph {
    [date: string]: number;
}

export const DashboardPage = () => {
    const navigate = useNavigate();

    const { data: hues, isLoading: isHuesLoading } = useGetHuesQuery();
    const { data: projects, isLoading: isProjectsLoading } =
        useGetProjectsQuery();
    const { data: profile } = useGetProfileQuery();
    const { data: teamMembers } = useGetAdditionalUsersQuery();
    const { data: totalApiCalls } = useGetTotalApiCallsQuery();

    const [aXis, setAXis] = useState<string[]>([]);

    const [yAxis, setYAxis] = useState<number[]>([]);

    const [liveColor, setLiveColor] = useState<number>(0);

    const createNewHue = () => {
        navigate('/new');
    };

    const createNewProject = () => {
        navigate(ROUTES.CREATE_PROJECT);
    };

    const goToProject = (projectId: number) => {
        navigate(`/project/${projectId}`);
    };

    const createProjectGraph = () => {
        if (Array.isArray(projects)) {
            let aArr: string[] = [];
            let yArr: number[] = [];

            const obj: DateGraph = {};

            for (const element of projects) {
                const dateField = new Date(element.createdDt);

                const year = dateField.getUTCFullYear();
                const month = dateField.getUTCMonth() + 1;

                const date = `${year}-${month}`;

                if (!aArr.includes(date)) {
                    aArr = [...aArr, date];
                }

                if (obj[date]) {
                    obj[date] = obj[date] + 1;
                } else {
                    obj[date] = 1;
                }
            }

            setAXis(aArr);

            for (const key in obj) {
                if (obj[key]) {
                    yArr = [...yArr, obj[key]];
                }
            }

            setYAxis(yArr);
        }
    };

    const calculateTotalLiveColors = () => {
        if (Array.isArray(hues)) {
            let total = 0;

            for (const element of hues) {
                total = total + (element?.hueColors?.length ?? 0);
            }

            setLiveColor(total);
        }
    };

    useEffect(() => {
        if (Array.isArray(projects) && projects.length) {
            createProjectGraph();
        }
    }, [projects, projects?.length]);

    useEffect(() => {
        if (Array.isArray(hues) && hues.length) {
            calculateTotalLiveColors();
        }
    }, [hues, hues?.length]);

    const renderHues = () => {
        if (isHuesLoading) {
            return (
                <Styles.DpHueList>
                    <HueLoader />
                    <HueLoader />
                    <HueLoader />
                    <HueLoader />
                    <HueLoader />
                    <HueLoader />
                    <HueLoader />
                    <HueLoader />
                </Styles.DpHueList>
            );
        } else {
            if (Array.isArray(hues)) {
                if (hues?.length) {
                    return (
                        <Styles.DpHueList>
                            {hues?.map((hue) => {
                                return <HueCard key={hue.id} hue={hue} />;
                            })}
                        </Styles.DpHueList>
                    );
                }

                return <NotFound header="We could not find any hues" />;
            }

            return <NotFound header="We could not find any hues" />;
        }
    };

    const renderProjects = () => {
        if (isProjectsLoading) {
            return (
                <Styles.DpProjectList>
                    <ProjectItemLoader />
                    <ProjectItemLoader />
                    <ProjectItemLoader />
                    <ProjectItemLoader />
                    <ProjectItemLoader />
                    <ProjectItemLoader />
                    <ProjectItemLoader />
                    <ProjectItemLoader />
                </Styles.DpProjectList>
            );
        } else {
            if (Array.isArray(projects)) {
                if (projects?.length) {
                    return (
                        <Styles.DpProjectList>
                            {projects?.map((v) => (
                                <Styles.DpProjectListItem
                                    key={v?.id}
                                    onClick={() => goToProject(v?.id)}
                                >
                                    <Styles.DpProjectItemName>
                                        {v?.name}
                                    </Styles.DpProjectItemName>

                                    <Styles.DpProjectItemApiKey>
                                        {v?.apiKey}
                                    </Styles.DpProjectItemApiKey>
                                </Styles.DpProjectListItem>
                            ))}
                        </Styles.DpProjectList>
                    );
                }

                return <NotFound header="We could not find any projects" />;
            }

            return <NotFound header="We could not find any projects" />;
        }
    };

    return (
        <Styles.DpPage>
            <Styles.DpPageHeader>
                Hello {profile?.userFirstName}!
            </Styles.DpPageHeader>
            <Styles.DpPageSubHeader>
                You are currently the administrator on the Starter plan
            </Styles.DpPageSubHeader>

            <Styles.DpStats>
                <Styles.DpStatItem>
                    <Styles.DpStatItemHeader>
                        API CALLS/MO
                    </Styles.DpStatItemHeader>
                    <Styles.DpStatItemValue>
                        {totalApiCalls ?? 0}
                    </Styles.DpStatItemValue>
                </Styles.DpStatItem>
                <Styles.DpStatItem>
                    <Styles.DpStatItemHeader>
                        LIVE COLORS
                    </Styles.DpStatItemHeader>
                    <Styles.DpStatItemValue>{liveColor}</Styles.DpStatItemValue>
                </Styles.DpStatItem>
                <Styles.DpStatItem>
                    <Styles.DpStatItemHeader>
                        TEAM MEMBERS
                    </Styles.DpStatItemHeader>
                    <Styles.DpStatItemValue>
                        {Array.isArray(teamMembers?.activeManagedUsers)
                            ? teamMembers?.activeManagedUsers?.length
                            : 0}
                    </Styles.DpStatItemValue>
                </Styles.DpStatItem>
            </Styles.DpStats>

            <Styles.DpGraphSection>
                <Styles.DpPageHeader>
                    Emovisual impact snapshot
                </Styles.DpPageHeader>

                <Styles.DpPageSubHeader>
                    Take a look at the stats
                </Styles.DpPageSubHeader>

                <Styles.DpGraphs>
                    <Styles.DpGraphItem>
                        <Chart
                            options={{
                                chart: {
                                    id: 'basic-bar',
                                },
                                xaxis: {
                                    categories: aXis,
                                },
                            }}
                            series={[
                                {
                                    name: 'project',
                                    data: yAxis,
                                },
                            ]}
                            type="bar"
                            width="100%"
                            height="300px"
                        />
                    </Styles.DpGraphItem>
                </Styles.DpGraphs>
            </Styles.DpGraphSection>

            <Styles.DpManageHeader>
                <div>Your Projects</div>
                <Styles.DpCreateNewColor
                    type="button"
                    onClick={createNewProject}
                >
                    <div>Create New Project</div>
                    <img src={RightArrow} alt="arrow" />
                </Styles.DpCreateNewColor>
            </Styles.DpManageHeader>

            <Styles.DpProjectListContainer>
                {renderProjects()}
            </Styles.DpProjectListContainer>

            <Styles.DpLine />

            <Styles.DpManageHeader>
                <div>Manage your colors</div>
                <Styles.DpCreateNewColor type="button" onClick={createNewHue}>
                    <div>Create New Color</div>
                    <img src={RightArrow} alt="arrow" />
                </Styles.DpCreateNewColor>
            </Styles.DpManageHeader>

            <Styles.DpLine />

            <Styles.DpHueListContainer>
                {renderHues()}
            </Styles.DpHueListContainer>
        </Styles.DpPage>
    );
};

DashboardPage.displayName = 'DashboardPage';
