import styled from 'styled-components';

export const CrContainer = styled.div`
    margin: 46px 0 0 0;
`;

export const CrHeader = styled.div`
    font-size: 18px;
    font-family: 'Poppins-Bold';
    color: #2a2a30;
`;

export const CrSubHeader = styled.div`
    margin: 5px 0 0 0;
    font-size: 15px;
    font-family: 'Poppins-Regular';
    color: #2a2a30;
`;

export const CrTextAreaContainer = styled.div`
    margin-top: 24px;

    textarea {
        width: 100%;
        height: 222px;
        padding: 30px;
        border: 1px solid #2a2a30;
        border-radius: 6px;
        transition: 300ms all;
        resize: none;
        font-size: 14px;
        font-family: 'Poppins-Medium';
        color: #2a2a30;
        border-radius: 2px;

        &:hover {
            border-color: #4096ff;
        }

        &:focus {
            border-color: #4096ff;
            box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
            outline: 0;
        }
    }
`;

export const CrTextArea = styled.textarea``;
