import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { useAppSelector } from '@ui/app/hooks';
import { ROUTES } from '@ui/types/types';

export const LoginPersistLayout = () => {
    const { token } = useAppSelector((state) => state.auth);

    const { pathname } = useLocation();

    if (
        token &&
        [
            ROUTES.ROOT,
            ROUTES.SIGN_UP,
            ROUTES.FORGOT_PASSWORD,
            ROUTES.PASSWORD_RESET,
        ].includes(pathname)
    ) {
        return <Navigate to={ROUTES.DASHBOARD} />;
    }

    return <Outlet />;
};
