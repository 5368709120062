import { ColorRepresentations } from '@ui/components/ColorPicker/ColorRepresentations';
import { colorContrastRatioCalculator } from '@mdhnpm/color-contrast-ratio-calculator';
import { COLOR_CONTRAST_TYPES, COLOR_PLACEMENT } from '@ui/types/types';
import {
    calcForLargeText,
    calcForSmallText,
    getColorPlacement,
} from '@ui/utils/helpers';
import * as Styles from './style';

interface ColorContrastProps {
    bgColor: ColorRepresentations;
    contrastColor: ColorRepresentations;
    contrastType: COLOR_CONTRAST_TYPES;
    colorPlacement: COLOR_PLACEMENT;
}

export const ColorContrast = (props: ColorContrastProps) => {
    const { bgColor, contrastType, contrastColor, colorPlacement } = props;

    const ratio =
        colorPlacement === COLOR_PLACEMENT.FOREGROUND
            ? colorContrastRatioCalculator(bgColor.hex, contrastColor.hex)
            : colorContrastRatioCalculator(contrastColor.hex, bgColor.hex);

    return (
        <Styles.CcWrapper>
            <div>
                <Styles.CcCircle
                    $bgColor={getColorPlacement(
                        colorPlacement,
                        contrastColor,
                        bgColor
                    )}
                >
                    <Styles.CcCircle2
                        $bgColor={getColorPlacement(
                            colorPlacement,
                            bgColor,
                            contrastColor
                        )}
                    />
                </Styles.CcCircle>
            </div>
            <div>
                <Styles.CcTextContainer
                    $bgColor={getColorPlacement(
                        colorPlacement,
                        contrastColor,
                        bgColor
                    )}
                >
                    <Styles.CcSmallText
                        $fontColor={getColorPlacement(
                            colorPlacement,
                            bgColor,
                            contrastColor
                        )}
                    >
                        Small sample text.
                    </Styles.CcSmallText>

                    <Styles.CcLargeText
                        $fontColor={getColorPlacement(
                            colorPlacement,
                            bgColor,
                            contrastColor
                        )}
                    >
                        Large sample text.
                    </Styles.CcLargeText>
                </Styles.CcTextContainer>
            </div>
            <div>
                <Styles.CcHeader>COLOR CONTRAST RESULTS:</Styles.CcHeader>

                <Styles.CcText
                    $colorContrastResult={calcForSmallText(ratio, contrastType)}
                >
                    Contrast Ratio: <span>{ratio} : 1</span>
                </Styles.CcText>
                <Styles.CcText
                    $colorContrastResult={calcForSmallText(ratio, contrastType)}
                >
                    Regulat Text:{' '}
                    <span>{calcForSmallText(ratio, contrastType)}</span>
                </Styles.CcText>
                <Styles.CcText
                    $colorContrastResult={calcForLargeText(ratio, contrastType)}
                >
                    Large Text:{' '}
                    <span>{calcForLargeText(ratio, contrastType)}</span>
                </Styles.CcText>
                <Styles.CcText
                    $colorContrastResult={calcForLargeText(ratio, contrastType)}
                >
                    UI Components:{' '}
                    <span>{calcForLargeText(ratio, contrastType)}</span>
                </Styles.CcText>
            </div>
        </Styles.CcWrapper>
    );
};
