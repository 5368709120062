import styled from 'styled-components';

interface ModalBodyProps {
    $show: boolean;
}

export const ModalContainer = styled.div<ModalBodyProps>`
    display: ${({ $show }) => ($show ? 'block' : 'none')};
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #000000cf;
    z-index: 500;
`;

export const ModalBody = styled.div`
    max-height: 95%;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 700;

    border-radius: 24px;

    @media (max-width: 700px) {
        width: 95%;
    }
`;
