import { DropdownOptions } from '@ui/types/types';

export const LOCALES: DropdownOptions[] = [
    { name: 'Afghanistan', value: 'fa-AF' },
    { name: 'Albania', value: 'sq-AL' },
    { name: 'Algeria', value: 'ar-DZ' },
    { name: 'Andorra', value: 'ca-AD' },
    { name: 'Angola', value: 'pt-AO' },
    { name: 'Argentina', value: 'es-AR' },
    { name: 'Armenia', value: 'hy-AM' },
    { name: 'Australia', value: 'en-AU' },
    { name: 'Austria', value: 'de-AT' },
    { name: 'Azerbaijan', value: 'az-AZ' },
    { name: 'Bahrain', value: 'ar-BH' },
    { name: 'Bangladesh', value: 'bn-BD' },
    { name: 'Belarus', value: 'be-BY' },
    { name: 'Belgium', value: 'nl-BE' },
    { name: 'Belgium(french)', value: 'fr-BE' },
    { name: 'Belize', value: 'en-BZ' },
    { name: 'Benin', value: 'fr-BJ' },
    { name: 'Bhutan', value: 'dz-BT' },
    { name: 'Bolivia', value: 'es-BO' },
    { name: 'Bosnia and Herzegovina', value: 'bs-BA' },
    { name: 'Botswana', value: 'en-BW' },
    { name: 'Brazil', value: 'pt-BR' },
    { name: 'Brunei', value: 'ms-BN' },
    { name: 'Bulgaria', value: 'bg-BG' },
    { name: 'Burkina Faso', value: 'fr-BF' },
    { name: 'Burundi', value: 'rn-BI' },
    { name: 'Cambodia', value: 'km-KH' },
    { name: 'Cameroon', value: 'fr-CM' },
    { name: 'Canada', value: 'en-CA' },
    { name: 'Canada(french)', value: 'fr-CA' },
    { name: 'Cape Verde', value: 'pt-CV' },
    { name: 'Central African Republic', value: 'fr-CF' },
    { name: 'Chad', value: 'fr-TD' },
    { name: 'Chile', value: 'es-CL' },
    { name: 'China', value: 'zh-CN' },
    { name: 'Colombia', value: 'es-CO' },
    { name: 'Comoros', value: 'fr-KM' },
    { name: 'Congo, Democratic Republic of the', value: 'fr-CD' },
    { name: 'Congo, Republic of the', value: 'fr-CG' },
    { name: 'Costa Rica', value: 'es-CR' },
    { name: 'Croatia', value: 'hr-HR' },
    { name: 'Cuba', value: 'es-CU' },
    { name: 'Cyprus', value: 'el-CY' },
    { name: 'Czech Republic', value: 'cs-CZ' },
    { name: 'Denmark', value: 'da-DK' },
    { name: 'Djibouti', value: 'fr-DJ' },
    { name: 'Dominica', value: 'en-DM' },
    { name: 'Dominican Republic', value: 'es-DO' },
    { name: 'Ecuador', value: 'es-EC' },
    { name: 'Egypt', value: 'ar-EG' },
    { name: 'El Salvador', value: 'es-SV' },
    { name: 'Equatorial Guinea', value: 'es-GQ' },
    { name: 'Eritrea', value: 'ti-ER' },
    { name: 'Estonia', value: 'et-EE' },
    { name: 'Eswatini', value: 'en-SZ' },
    { name: 'Ethiopia', value: 'am-ET' },
    { name: 'Fiji', value: 'en-FJ' },
    { name: 'Finland', value: 'fi-FI' },
    { name: 'France', value: 'fr-FR' },
    { name: 'Gabon', value: 'fr-GA' },
    { name: 'Gambia', value: 'en-GM' },
    { name: 'Georgia', value: 'ka-GE' },
    { name: 'Germany', value: 'de-DE' },
    { name: 'Ghana', value: 'en-GH' },
    { name: 'Greece', value: 'el-GR' },
    { name: 'Grenada', value: 'en-GD' },
    { name: 'Guatemala', value: 'es-GT' },
    { name: 'Guinea', value: 'fr-GN' },
    { name: 'Guinea-Bissau', value: 'pt-GW' },
    { name: 'Guyana', value: 'en-GY' },
    { name: 'Haiti', value: 'fr-HT' },
    { name: 'Honduras', value: 'es-HN' },
    { name: 'Hungary', value: 'hu-HU' },
    { name: 'Iceland', value: 'is-IS' },
    { name: 'India(Hindi)', value: 'hi-IN' },
    { name: 'India(English)', value: 'en-IN' },
    { name: 'Indonesia', value: 'id-ID' },
    { name: 'Iran', value: 'fa-IR' },
    { name: 'Iraq', value: 'ar-IQ' },
    { name: 'Ireland', value: 'en-IE' },
    { name: 'Israel', value: 'he-IL' },
    { name: 'Italy', value: 'it-IT' },
    { name: 'Jamaica', value: 'en-JM' },
    { name: 'Japan', value: 'ja-JP' },
    { name: 'Jordan', value: 'ar-JO' },
    { name: 'Kazakhstan', value: 'kk-KZ' },
    { name: 'Kenya', value: 'sw-KE' },
    { name: 'Kenya(English)', value: 'en-KE' },
    { name: 'Kiribati', value: 'en-KI' },
    { name: 'Kuwait', value: 'ar-KW' },
    { name: 'Kyrgyzstan', value: 'ky-KG' },
    { name: 'Laos', value: 'lo-LA' },
    { name: 'Latvia', value: 'lv-LV' },
    { name: 'Lebanon', value: 'ar-LB' },
    { name: 'Lesotho', value: 'en-LS' },
    { name: 'Liberia', value: 'en-LR' },
    { name: 'Libya', value: 'ar-LY' },
    { name: 'Liechtenstein', value: 'de-LI' },
    { name: 'Lithuania', value: 'lt-LT' },
    { name: 'Luxembourg', value: 'lb-LU' },
    { name: 'Madagascar', value: 'fr-MG' },
    { name: 'Malawi', value: 'en-MW' },
    { name: 'Malaysia', value: 'ms-MY' },
    { name: 'Maldives', value: 'dv-MV' },
    { name: 'Mali', value: 'fr-ML' },
    { name: 'Malta', value: 'mt-MT' },
    { name: 'Marshall Islands', value: 'en-MH' },
    { name: 'Mauritania', value: 'ar-MR' },
    { name: 'Mauritius', value: 'en-MU' },
    { name: 'Mexico', value: 'es-MX' },
    { name: 'Micronesia', value: 'en-FM' },
    { name: 'Moldova', value: 'ro-MD' },
    { name: 'Monaco', value: 'fr-MC' },
    { name: 'Mongolia', value: 'mn-MN' },
    { name: 'Montenegro', value: 'sr-ME' },
    { name: 'Morocco', value: 'ar-MA' },
    { name: 'Mozambique', value: 'pt-MZ' },
    { name: 'Myanmar', value: 'my-MM' },
    { name: 'Namibia', value: 'en-NA' },
    { name: 'Nauru', value: 'en-NR' },
    { name: 'Nepal', value: 'ne-NP' },
    { name: 'Netherlands', value: 'nl-NL' },
    { name: 'New Zealand', value: 'en-NZ' },
    { name: 'Nicaragua', value: 'es-NI' },
    { name: 'Niger', value: 'fr-NE' },
    { name: 'Nigeria', value: 'en-NG' },
    { name: 'North Macedonia', value: 'mk-MK' },
    { name: 'Norway', value: 'no-NO' },
    { name: 'Oman', value: 'ar-OM' },
    { name: 'Pakistan', value: 'ur-PK' },
    { name: 'Palau', value: 'en-PW' },
    { name: 'Panama', value: 'es-PA' },
    { name: 'Papua New Guinea', value: 'en-PG' },
    { name: 'Paraguay', value: 'es-PY' },
    { name: 'Peru', value: 'es-PE' },
    { name: 'Philippines', value: 'en-PH' },
    { name: 'Poland', value: 'pl-PL' },
    { name: 'Portugal', value: 'pt-PT' },
    { name: 'Qatar', value: 'ar-QA' },
    { name: 'Romania', value: 'ro-RO' },
    { name: 'Russia', value: 'ru-RU' },
    { name: 'Rwanda', value: 'rw-RW' },
    { name: 'Saint Kitts and Nevis', value: 'en-KN' },
    { name: 'Saint Lucia', value: 'en-LC' },
    { name: 'Saint Vincent and the Grenadines', value: 'en-VC' },
    { name: 'Samoa', value: 'en-WS' },
    { name: 'San Marino', value: 'it-SM' },
    { name: 'Sao Tome and Principe', value: 'pt-ST' },
    { name: 'Saudi Arabia', value: 'ar-SA' },
    { name: 'Senegal', value: 'fr-SN' },
    { name: 'Serbia', value: 'sr-RS' },
    { name: 'Seychelles', value: 'fr-SC' },
    { name: 'Sierra Leone', value: 'en-SL' },
    { name: 'Singapore', value: 'en-SG' },
    { name: 'Slovakia', value: 'sk-SK' },
    { name: 'Slovenia', value: 'sl-SI' },
    { name: 'Solomon Islands', value: 'en-SB' },
    { name: 'Somalia', value: 'so-SO' },
    { name: 'South Africa', value: 'en-ZA' },
    { name: 'South Korea', value: 'ko-KR' },
    { name: 'Spain', value: 'es-ES' },
    { name: 'Sri Lanka', value: 'si-LK' },
    { name: 'Sudan', value: 'ar-SD' },
    { name: 'Suriname', value: 'nl-SR' },
    { name: 'Sweden', value: 'sv-SE' },
    { name: 'Switzerland(German)', value: 'de-CH' },
    { name: 'Switzerland(French)', value: 'fr-CH' },
    { name: 'Switzerland(Italian)', value: 'it-CH' },
    { name: 'Syria', value: 'ar-SY' },
    { name: 'Taiwan', value: 'zh-TW' },
    { name: 'Tajikistan', value: 'tg-TJ' },
    { name: 'Tanzania', value: 'sw-TZ' },
    { name: 'Thailand', value: 'th-TH' },
    { name: 'Togo', value: 'fr-TG' },
    { name: 'Tonga', value: 'en-TO' },
    { name: 'Trinidad and Tobago', value: 'en-TT' },
    { name: 'Tunisia', value: 'ar-TN' },
    { name: 'Turkey', value: 'tr-TR' },
    { name: 'Turkmenistan', value: 'tk-TM' },
    { name: 'Tuvalu', value: 'en-TV' },
    { name: 'Uganda', value: 'en-UG' },
    { name: 'Ukraine', value: 'uk-UA' },
    { name: 'United Arab Emirates', value: 'ar-AE' },
    { name: 'United Kingdom', value: 'en-GB' },
    { name: 'United States', value: 'en-US' },
    { name: 'Uruguay', value: 'es-UY' },
    { name: 'Uzbekistan', value: 'uz-UZ' },
    { name: 'Vanuatu', value: 'en-VU' },
    { name: 'Vatican City', value: 'it-VA' },
    { name: 'Venezuela', value: 'es-VE' },
    { name: 'Vietnam', value: 'vi-VN' },
    { name: 'Yemen', value: 'ar-YE' },
    { name: 'Zambia', value: 'en-ZM' },
    { name: 'Zimbabwe', value: 'en-ZW' },
];
