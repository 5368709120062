import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetPaletteHuesQuery } from '@ui/services/palette';
import { ROUTES, UserCreatedHueColors } from '@ui/types/types';
import * as Styles from './style';

interface PaletteItemProps {
    palettedId: number;
    paletteName: string;
    openDeletePaletteModal: (palettedId: number) => void;
    projectId: string;
}

export const PaletteItem = (props: PaletteItemProps) => {
    const { palettedId, paletteName, projectId, openDeletePaletteModal } =
        props;

    const { data: hue } = useGetPaletteHuesQuery(palettedId);

    const renderColors = (hueColors: UserCreatedHueColors[]) => {
        if (Array.isArray(hueColors)) {
            if (hueColors.length > 4) {
                const colors = hueColors
                    .slice(0, 4)
                    .map((item, index) => (
                        <Styles.PiColor
                            key={item.id}
                            $backgroundColor={item?.hex}
                            $index={index}
                        />
                    ));

                return (
                    <>
                        {colors}
                        <Styles.PiColor
                            key={4}
                            $backgroundColor={'#ffffff'}
                            $index={4}
                        >
                            <span>+{hueColors.length - 4}</span>
                        </Styles.PiColor>
                    </>
                );
            }

            return hueColors.map((item, index) => (
                <Styles.PiColor
                    key={item.id}
                    $backgroundColor={item?.hex}
                    $index={index}
                />
            ));
        }

        return null;
    };

    const renderHueList = () => {
        if (Array.isArray(hue)) {
            return hue.map((v, k) => (
                <Styles.PiHueListItem key={k}>
                    <Styles.PiHueName>{v.name}</Styles.PiHueName>
                    <Styles.PiColorContainer
                        to={`${ROUTES.COLOR_STUDIO}/${projectId}/${palettedId}/${v.id}`}
                    >
                        {renderColors(v.colors)}
                    </Styles.PiColorContainer>
                </Styles.PiHueListItem>
            ));
        }

        return null;
    };

    return (
        <Styles.PiContainer>
            <Styles.PiTrashIcon
                onClick={() => openDeletePaletteModal(palettedId)}
            >
                <FontAwesomeIcon icon={faTrash} className="icon" />
            </Styles.PiTrashIcon>
            <Styles.PiHeader>{paletteName}</Styles.PiHeader>
            <Styles.PiHueList>{renderHueList()}</Styles.PiHueList>
        </Styles.PiContainer>
    );
};
