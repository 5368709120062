import styled from 'styled-components';

export const CshContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 21px;
    flex-wrap: wrap;

    font-size: 33px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';

    @media (max-width: 1000px) {
        font-size: 16px;
    }
`;

export const CshTextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
`;

export const CshText = styled.div`
    font-size: 33px;
    color: #2a2a30;
    font-family: 'Poppins-ExtraLight';

    @media (max-width: 1000px) {
        font-size: 16px;
    }
`;
