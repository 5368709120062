import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '@ui/components/Button';

export const LfContainer = styled.div`
    width: 504px;
    padding: 30px 32px 58px 32px;
    background: rgba(255, 255, 255, 0.9);
    opacity: 0.9;
    box-shadow: 0px 14px 50px 0px rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(25px);
    border-radius: 10px;

    @media (max-width: 600px) {
        width: 100%;
        padding: 30px 20px 58px 20px;
    }
`;

export const LfLogo = styled.img`
    width: 90px;
    height: 90px;
    object-fit: cover;
    border: 5px solid #ffffff;
    border-radius: 50%;
`;

export const LfHeader = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;

    span {
        color: #2a2a30;
        font-family: 'Poppins-SemiBold';
        font-size: 40px;
    }

    @media (max-width: 400px) {
        span {
            font-size: 30px;
        }
    }
`;

export const LfHeaderText = styled.div`
    position: absolute;
    top: -36px;
    left: 155px;

    color: #2a2a30;
    font-family: 'Qwitcher Grypen';
    font-size: 100px;

    @media (max-width: 400px) {
        top: -30px;
        left: 115px;
        font-size: 75px;
    }
`;

export const LfSubHeader = styled.div`
    color: #2a2a30;
    font-family: 'Poppins-Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    opacity: 0.98;

    @media (max-width: 450px) {
        br {
            display: none;
        }
    }
`;

export const LoginFormInnerContainer = styled.div`
    margin-top: 28px;
`;

export const LfControl = styled.div``;

export const LfControl2 = styled.div`
    margin-top: 16px;
`;

export const LfFieldControl = styled.div`
    margin-top: 6px;
`;

export const LoginLabel = styled.label`
    color: #2a2a30;
    font-family: 'Poppins-Medium';
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
`;

export const LfForgotPassword = styled(Link)`
    display: block;
    margin: 24px 0 0 0;
    color: #2a2a30;
    font-family: 'Poppins-Medium';
    font-size: 12px;
    line-height: 22px;
`;

export const LfBtn = styled(Button)`
    margin: 30px 0 0 auto;
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

export const LFErrMsg = styled.div`
    color: #df27e8;
    margin-top: 8px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
`;

export const LfSignupText = styled.div`
    margin-top: 17px;
    display: flex;
    align-items: center;
    gap: 3px;
    color: #2a2a30;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    line-height: 22px;

    a {
        color: #2a2a30;
        font-family: 'Poppins-Medium';
        font-size: 14px;
        line-height: 22px;
    }
`;
