import { Button } from '@ui/components/Button';
import styled from 'styled-components';

export const TpPage = styled.div`
    min-height: 100vh;
    padding: 100px 0;
`;

export const TpHeader = styled.div`
    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-Bold';
`;

export const TpHeader2 = styled(TpHeader)`
    margin: 56px 0 0 0;
`;

export const TpAddBtn = styled(Button)`
    width: 150px;
`;

export const TpTotal = styled.div`
    margin: 20px 0 0 0;
    text-align: center;
`;

export const TpTable = styled.table`
    margin-top: 100px;
    width: 100%;
`;

export const TpTableHeader = styled.th`
    text-align: left;
`;

export const TpTableRow = styled.tr`
    margin: 20px 0 0 0;

    td {
        padding: 10px 0;
    }
`;

export const TpAddMember = styled.div`
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid #cccccc;

    h3 {
        text-align: center;
    }

    label {
        font-size: 16px;
        font-weight: 600;
    }
`;

export const TpAddMemberBtns = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin: 150px 0 0 0;
`;

export const TpiContainer = styled.div`
    margin: 20px 0 0 0;

    input {
        width: 100%;
        height: 50px;
        padding: 7px 11px;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        transition: 300ms all;

        &:hover {
            border-color: #4096ff;
            border-inline-end-width: 1px;
        }

        &:focus {
            border-color: #4096ff;
            box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
            border-inline-end-width: 1px;
            outline: 0;
        }
    }
`;

export const TpiName = styled.div`
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr);
    grid-gap: 20px;
    align-items: center;
`;

export const TpiName2 = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr);
    grid-gap: 20px;
    align-items: center;
`;

export const TpiImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
`;

export const TeamListItem = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr 1fr;
    align-items: center;
    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';

    @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        font-size: 14px;
    }
`;

export const TeamListCenterText = styled.div`
    text-align: center;
`;

export const TeamListRightAligned = styled.div`
    display: flex;
    justify-content: flex-end;

    @media (max-width: 800px) {
        justify-content: flex-start;
    }
`;

export const TeamList = styled.div`
    margin: 30px 0 0 0;

    ${TeamListItem}:not(:first-child) {
        margin: 20px 0 0 0;
    }
`;
