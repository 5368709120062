import styled from 'styled-components';

export const RcContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const RcText = styled.div`
    font-size: 15px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';
`;

export const RcSwitchLabel = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
`;

export const RcSlider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &::before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }
`;

export const RcInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${RcSlider} {
        background-color: #2a2a30;
    }

    &:focus + ${RcSlider} {
        box-shadow: 0 0 1px #2a2a30;
    }

    &:checked + ${RcSlider}::before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
`;
