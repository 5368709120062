import { HsvaColor } from '@uiw/react-color';
import tinycolor from 'tinycolor2';

export type ColorRepresentations = {
    hex: string;
    hsva: HsvaColor;
    rgba: tinycolor.ColorFormats.RGBA;
};

export enum ColorActionKind {
    HEX,
    RGBA,
    HSVA,
}

export type ColorAction =
    | { kind: ColorActionKind.HEX; hex: string }
    | { kind: ColorActionKind.RGBA; rgba: tinycolor.ColorFormats.RGBA }
    | { kind: ColorActionKind.HSVA; hsva: HsvaColor };

export function colorToHsva(rgbaOrHex: tinycolor.ColorFormats.RGBA | string) {
    const newHsva = tinycolor(rgbaOrHex).toHsv();
    return {
        h: newHsva.h,
        s: newHsva.s * 100,
        v: newHsva.v * 100,
        a: newHsva.a,
    };
}

export function fromHsva(hsva: HsvaColor): ColorRepresentations {
    return {
        hex: tinycolor(hsva).toHexString(),
        hsva: hsva,
        rgba: tinycolor(hsva).toRgb(),
    };
}

export function fromHex(hex: string): ColorRepresentations {
    return {
        hex: hex,
        hsva: colorToHsva(hex),
        rgba: tinycolor(hex).toRgb(),
    };
}

export function fromRgba(
    rgba: tinycolor.ColorFormats.RGBA
): ColorRepresentations {
    return {
        hex: tinycolor(rgba).toHexString(),
        hsva: colorToHsva(rgba),
        rgba: rgba,
    };
}

export function reducer(
    state: ColorRepresentations,
    action: ColorAction
): ColorRepresentations {
    switch (action.kind) {
        case ColorActionKind.RGBA:
            return {
                hex: tinycolor(action.rgba).toHexString(),
                hsva: colorToHsva(action.rgba),
                rgba: action.rgba,
            };
        case ColorActionKind.HEX:
            return {
                hex: action.hex,
                hsva: colorToHsva(action.hex),
                rgba: tinycolor(action.hex).toRgb(),
            };
        case ColorActionKind.HSVA:
            return {
                hex: tinycolor(action.hsva).toHexString(),
                hsva: action.hsva,
                rgba: tinycolor(action.hsva).toRgb(),
            };
        default:
            throw new Error('Unknown color type');
    }
}
