import { useParams } from 'react-router-dom';
import { CustomDropdown } from '@ui/components/CustomDropdown';
import { CustomRadioBtn } from '@ui/components/CustomRadioBtn';
import { CustomColorPicker } from '@ui/components/CustomColorPicker';
import { BtnLoader } from '@ui/components/Loaders';
import { AddColorFromStudio, COLOR_PLACEMENT } from '@ui/types/types';
import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import toast from 'react-hot-toast';
import {
    useAddColorFromStudioMutation,
    useUpdateColorFromStudioMutation,
} from '@ui/services/color';
import * as Styles from './style';
import { ColorName } from '../ColorName';
import { ColorCssProperties } from '../ColorCssProperties';
import { ColorRepresentations } from '@ui/components/ColorPicker/ColorRepresentations';
import {
    handleColorPlacement,
    setContrastColor,
} from '@ui/slices/colorStudioSlice';
import { ColorOptimization } from '../ColorOptimization';
import { useGetProfileQuery } from '@ui/services/profile';
import { EditHue } from '@ui/types/Hue';
import { useGetHueByIdQuery, useUpdateHueMutation } from '@ui/services/hue';
import { setColorId } from '@ui/slices/colorSlice';

export const ColorSettings = () => {
    const { hueId, paletteId } = useParams();

    const {
        id,
        isDefault,
        name,
        trueColor,
        rationale,
        aaColor,
        aaaColor,
        colorDescriptors,
        colorTags,
        contrastColor,
        colorPlacement,
        aaHexOL,
        aaaHexOL,
        defaultColor,
        cssProperties,
        isGradient,
        gradientHex,
    } = useAppSelector((state) => state.colorStudio);

    const { data: profile } = useGetProfileQuery();

    const { data: hue } = useGetHueByIdQuery(parseInt(hueId!));

    const dispatch = useAppDispatch();

    const [addColorFromStudio, { isLoading: addColorLoading }] =
        useAddColorFromStudioMutation();

    const [updateColorFromStudio, { isLoading: updateColorLoading }] =
        useUpdateColorFromStudioMutation();

    const [updateHue, { isLoading: updateHueLoading }] = useUpdateHueMutation();

    const handleSaveColorSettings = async () => {
        const colorArgs: AddColorFromStudio = {
            isDefault: isDefault,
            name: name,
            rationale: rationale,
            hex: trueColor.hex,
            hueId: parseInt(hueId!),
            colorDescriptors: colorDescriptors.toString(),
            colorTags: colorTags.toString(),
            aaHex: aaHexOL ? aaColor.hex : trueColor.hex,
            aaaHex: aaaHexOL ? aaaColor.hex : trueColor.hex,
            colorSetting: {
                aaHexOL: aaHexOL,
                aaaHexOL: aaaHexOL,
                colorPlacement: colorPlacement,
                defaultColor: defaultColor,
                contrastHex: contrastColor.hex,
            },
            userEmail: profile?.userEmail as string,
            isGradient: isGradient,
            gradientHex: gradientHex.hex,
        };

        const savingHue: EditHue = {
            id: parseInt(hueId!),
            name: hue?.name as string,
            paletteId: parseInt(paletteId!),
            cssProperties: cssProperties,
        };
        try {
            await updateHue(savingHue).unwrap();
        } catch (error) {
            toast.error(`Error saving hue css property`);
            return;
        }

        try {
            if (id === -1) {
                const addResponse = await addColorFromStudio(
                    colorArgs
                ).unwrap();

                toast.success('Color added');

                dispatch(setColorId(addResponse.id));
            } else {
                await updateColorFromStudio({
                    ...colorArgs,
                    id: id,
                }).unwrap();

                toast.success('Color Updated');
            }
        } catch {
            toast.error('Something went wrong');
        }
    };

    return (
        <Styles.CsPage>
            <Styles.CsContainer>
                <ColorName />
                <ColorCssProperties />
                <Styles.CsHeader>COLOR SETTINGS:</Styles.CsHeader>

                <Styles.CsLine />

                <Styles.CsSubHeader>
                    Before you start picking your colors, it is important to
                    decide
                </Styles.CsSubHeader>

                <ColorOptimization />

                <Styles.CsopContainer>
                    <Styles.CsopHeader>DEFAULT COLOR:</Styles.CsopHeader>

                    <Styles.CsDesc>
                        While the true color with no optimization is the default
                        color on your websites, there are some cases where you
                        want to set a different color correction or optimization
                        as your default color (EX: US Government websites).
                    </Styles.CsDesc>

                    <Styles.CsDropdownContainer>
                        <Styles.CsDropdownItem>
                            <CustomDropdown
                                options={[
                                    {
                                        name: 'True Color',
                                        value: 'True Color',
                                    },
                                    { name: 'AA', value: 'AA' },
                                    { name: 'AAA', value: 'AAA' },
                                ]}
                                onChange={(value) =>
                                    console.log('value ', value)
                                }
                                placeholder="Select Default Color"
                            />
                        </Styles.CsDropdownItem>
                    </Styles.CsDropdownContainer>
                </Styles.CsopContainer>

                <Styles.CsopContainer>
                    <Styles.CsopHeader>COLOR PLACEMENT:</Styles.CsopHeader>

                    <Styles.CsDesc>
                        To ensure that your color is optimized, you need to
                        specify whether it is in the background or foreground in
                        relation to another color.
                    </Styles.CsDesc>

                    <Styles.CsRadioBtn>
                        <CustomRadioBtn
                            label="Background"
                            id={COLOR_PLACEMENT.BACKGROUND}
                            value={COLOR_PLACEMENT.BACKGROUND}
                            checked={
                                colorPlacement === COLOR_PLACEMENT.BACKGROUND
                            }
                            handleChange={() =>
                                dispatch(
                                    handleColorPlacement(
                                        COLOR_PLACEMENT.BACKGROUND
                                    )
                                )
                            }
                        />
                        <CustomRadioBtn
                            label="Foreground"
                            id={COLOR_PLACEMENT.FOREGROUND}
                            value={COLOR_PLACEMENT.FOREGROUND}
                            checked={
                                colorPlacement === COLOR_PLACEMENT.FOREGROUND
                            }
                            handleChange={() =>
                                dispatch(
                                    handleColorPlacement(
                                        COLOR_PLACEMENT.FOREGROUND
                                    )
                                )
                            }
                        />
                    </Styles.CsRadioBtn>
                </Styles.CsopContainer>

                <Styles.CsopContainer>
                    <Styles.CsopHeader>CONTRAST COLOR:</Styles.CsopHeader>

                    <Styles.CsDesc>
                        Pick the color that will interact with your hue.
                    </Styles.CsDesc>
                </Styles.CsopContainer>

                <Styles.CsCcpContainer>
                    <CustomColorPicker
                        color={contrastColor}
                        handleColorChange={(value: ColorRepresentations) => {
                            dispatch(setContrastColor(value));
                        }}
                        showHue={true}
                        showResetBtn={false}
                    />
                </Styles.CsCcpContainer>

                <Styles.TcSaveBtnContainer>
                    <Styles.TcSaveBtn
                        onClick={handleSaveColorSettings}
                        isDisabled={
                            addColorLoading ||
                            updateColorLoading ||
                            updateHueLoading
                        }
                    >
                        {addColorLoading ||
                        updateColorLoading ||
                        updateHueLoading ? (
                            <BtnLoader />
                        ) : (
                            'SAVE'
                        )}
                    </Styles.TcSaveBtn>
                </Styles.TcSaveBtnContainer>
            </Styles.CsContainer>
        </Styles.CsPage>
    );
};
