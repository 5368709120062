import { useState } from 'react';
import { TEAM_MEMBERS } from '@ui/types/Team';
import { CustomDropdown } from '@ui/components/CustomDropdown';
import { BtnLoader } from '@ui/components/Loaders';
import { ProjectUsers } from '@ui/types/types';
import * as Styles from './style';

interface AddTeamMembersProps {
    handleCancel: () => void;
    handleAdd: (email: string) => void;
    teamMembers: TEAM_MEMBERS | undefined;
    isAdding: boolean;
    projectUsers: ProjectUsers[] | undefined;
}

export const AddProjectMember = (props: AddTeamMembersProps) => {
    const { handleAdd, handleCancel, teamMembers, isAdding, projectUsers } =
        props;

    const [email, setEmail] = useState('');

    const getTeamMembers = () => {
        const projectUsersEmail = projectUsers?.map((v) => v.email);

        const notMembers = teamMembers?.activeManagedUsers?.filter(
            (v) => !projectUsersEmail?.includes(v?.userEmail)
        );

        if (Array.isArray(notMembers)) {
            return notMembers?.map((v) => ({
                name: `${v.firstName} ${v.lastName}`,
                value: v.userEmail,
            }));
        }

        return [];
    };

    return (
        <Styles.AtmContainer>
            <Styles.AtmHeader>
                <h3>Add Member</h3>
            </Styles.AtmHeader>

            <Styles.AtmHeader2>
                Add your team members to this project
            </Styles.AtmHeader2>

            <Styles.AtmInputContainer>
                <CustomDropdown
                    placeholder="Select From Your Team Members"
                    options={getTeamMembers()}
                    onChange={(value) => {
                        if (typeof value === 'string') {
                            setEmail(value);
                        }
                    }}
                />
            </Styles.AtmInputContainer>

            <Styles.AtmEmail>{email}</Styles.AtmEmail>

            <Styles.AtmBtns>
                <Styles.AtmBtn onClick={handleCancel}>Cancel</Styles.AtmBtn>
                <Styles.AtmBtn
                    onClick={() => handleAdd(email)}
                    isDisabled={isAdding || !email}
                >
                    {isAdding ? <BtnLoader /> : 'Add Project Member'}
                </Styles.AtmBtn>
            </Styles.AtmBtns>
        </Styles.AtmContainer>
    );
};
