import * as Styles from './style';
import { RegisterForm } from './components/RegistrationForm';

export const RegistrationPage = () => {
    return (
        <Styles.RegisterPage>
            <Styles.RpContainer>
                <RegisterForm />
            </Styles.RpContainer>
        </Styles.RegisterPage>
    );
};
