import styled from 'styled-components';
import { Button } from '@ui/components/Button';

export const UpContainer = styled.div`
    padding: 20px 0;
    width: 500px;

    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const RpFormControl = styled.div``;

export const RpFormControl2 = styled.div`
    margin-top: 22px;
`;

export const RpFileFormControl = styled(RpFormControl2)`
    input {
        display: none;
    }
`;

export const RpFieldControl = styled.div`
    margin-top: 11px;
`;

export const RpLabel = styled.label`
    font: normal normal bold 16px/19px Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
`;

export const RpRegisterBtn = styled(Button)`
    margin-top: 21px;
`;

export const EpUploadPic = styled.div`
    margin-top: 10px;
    height: 100px;
    border-radius: 8px;
    padding: 7px 11px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const EpUploadPicPreview = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
`;

export const EpUploadCoverPreview = styled.img`
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 7px;
`;
