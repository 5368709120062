import * as Styles from './style';

type CustomModalProps = {
    show: boolean;
    children: React.ReactNode;
};

export const CustomModal = (props: CustomModalProps) => {
    const { show, children } = props;

    return (
        <Styles.ModalContainer $show={show}>
            <Styles.ModalBody>{children}</Styles.ModalBody>
        </Styles.ModalContainer>
    );
};
