import styled from 'styled-components';
import OnHueBackground from '@ui/images/onHueBackground.svg';

export const FpPage = styled.div`
    background-image: url(${OnHueBackground}),
        linear-gradient(to bottom right, #15467c, #00a3e1);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: 100vh;
`;

export const FpContainer = styled.div`
    min-height: 100vh;
    padding: 50px 0;
    width: 1440px;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 800px) {
        justify-content: center;
    }
`;
