import { useState } from 'react';
import { COLOR_SETTINGS_TABS } from '@ui/types/types';
import * as Styles from './style';
import { ColorStudioHeader } from '../../components/ColorStudioHeader';
import { ColorStudioTab } from '../../components/ColorStudioTab';
import { ColorSettings } from '../../components/ColorSettings';
import { TrueColor } from '../../components/TrueColor';

export const ColorSettingsPage = () => {
    const [activeTab, setActiveTab] = useState<COLOR_SETTINGS_TABS>(
        COLOR_SETTINGS_TABS.TRUE_COLOR
    );

    const handleTabChange = (value: COLOR_SETTINGS_TABS) => {
        setActiveTab(value);
    };

    const renderTabs = () => {
        switch (activeTab) {
            case COLOR_SETTINGS_TABS.SETTINGS:
                return <ColorSettings />;

            case COLOR_SETTINGS_TABS.TRUE_COLOR:
                return <TrueColor />;

            default:
                return <ColorSettings />;
        }
    };

    return (
        <Styles.CsPage>
            <ColorStudioHeader />
            <ColorStudioTab
                activeTab={activeTab}
                handleTabChange={handleTabChange}
            />

            <Styles.CsContent>{renderTabs()}</Styles.CsContent>
        </Styles.CsPage>
    );
};
