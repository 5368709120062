import {
    faHome,
    faUser,
    faBell,
    faUserGroup,
    faPalette,
    faBars,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OnHueLogoRound from '@ui/images/onHueLogoRound.svg';
import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import { toggleSidebar } from '@ui/slices/appStateSlice';
import * as Styles from './style';
import { ROUTES } from '@ui/types/types';

export const Sidebar = () => {
    const { showSideBar } = useAppSelector((state) => state.appState);

    const dispatch = useAppDispatch();

    const closeSideBar = () => {
        dispatch(toggleSidebar());
    };

    return (
        <Styles.SbWrapper $showSideBar={showSideBar}>
            <Styles.SbOnHueLogoWrapper onClick={closeSideBar}>
                <Styles.SbOnHueLogoContainer to={'/dashboard'}>
                    <Styles.SbOnHueLogo src={OnHueLogoRound} alt="onhue" />
                </Styles.SbOnHueLogoContainer>
                <Styles.SidebarHamburger>
                    <FontAwesomeIcon icon={faBars} className="icon" />
                </Styles.SidebarHamburger>
            </Styles.SbOnHueLogoWrapper>

            <Styles.SbContainer>
                <Styles.SbIconList>
                    <Styles.SbIconListItem
                        to={'/dashboard'}
                        onClick={closeSideBar}
                    >
                        <Styles.SbIconContainer>
                            <FontAwesomeIcon icon={faHome} className="icon" />
                        </Styles.SbIconContainer>

                        <div>Dashboard</div>
                    </Styles.SbIconListItem>

                    <Styles.SbIconListItem
                        to={'/profile'}
                        onClick={closeSideBar}
                    >
                        <Styles.SbIconContainer>
                            <FontAwesomeIcon icon={faUser} className="icon" />
                        </Styles.SbIconContainer>

                        <div>Profile</div>
                    </Styles.SbIconListItem>

                    <Styles.SbIconListItem
                        to={'/notifications'}
                        onClick={closeSideBar}
                    >
                        <Styles.SbIconContainer>
                            <FontAwesomeIcon icon={faBell} className="icon" />
                        </Styles.SbIconContainer>

                        <div>Notifications</div>
                    </Styles.SbIconListItem>

                    <Styles.SbIconListItem to={'/team'} onClick={closeSideBar}>
                        <Styles.SbIconContainer>
                            <FontAwesomeIcon
                                icon={faUserGroup}
                                className="icon"
                            />
                        </Styles.SbIconContainer>

                        <div>Team</div>
                    </Styles.SbIconListItem>

                    <Styles.SbIconListItem
                        to={ROUTES.HELP}
                        onClick={closeSideBar}
                    >
                        <Styles.SbIconContainer>
                            <FontAwesomeIcon
                                icon={faPalette}
                                className="icon"
                            />
                        </Styles.SbIconContainer>

                        <div>Help</div>
                    </Styles.SbIconListItem>
                </Styles.SbIconList>
            </Styles.SbContainer>
        </Styles.SbWrapper>
    );
};
