import { UserProfile } from '@ui/types/Profile';
import { api } from './api';

export const profileApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getProfile: builder.query<UserProfile, void>({
            query: () => ({
                url: `users/userProfileInformation`,
            }),
        }),
        updateProfile: builder.mutation<unknown, FormData>({
            query: (updateProfile) => ({
                url: 'users/updateUserInfo',
                method: 'POST',
                body: updateProfile,
            }),
        }),
    }),
});

export const { useGetProfileQuery, useUpdateProfileMutation } = profileApi;
