import UserPlaceholder from '@ui/images/user-placeholder.svg';
import * as Styles from './style';
import { BtnLoader } from '@ui/components/Loaders';

interface RemoveProjectMemberProps {
    profileImgSrc: string | null | undefined;
    selectedEmail: string;
    handleCancel: () => void;
    handleConfirm: () => void;
    isDeleting: boolean;
}

export const RemoveProjectMember = (props: RemoveProjectMemberProps) => {
    const {
        profileImgSrc,
        handleConfirm,
        handleCancel,
        selectedEmail,
        isDeleting,
    } = props;

    return (
        <Styles.RtmContainer>
            <Styles.RtmHeader>Remove member</Styles.RtmHeader>

            <Styles.RtmLine />

            <Styles.RtmConfirm>
                Are you sure you want to remove:{' '}
            </Styles.RtmConfirm>

            <Styles.RtmName>
                <Styles.RtmImage
                    src={profileImgSrc || UserPlaceholder}
                    alt="profile"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = UserPlaceholder;
                    }}
                />
                <div>{selectedEmail}</div>
            </Styles.RtmName>

            <Styles.RtmBtns>
                <Styles.RtmBtn onClick={handleCancel}>Cancel</Styles.RtmBtn>
                <Styles.RtmBtn onClick={handleConfirm} isDisabled={isDeleting}>
                    {isDeleting ? <BtnLoader /> : 'Confirm'}
                </Styles.RtmBtn>
            </Styles.RtmBtns>
        </Styles.RtmContainer>
    );
};
