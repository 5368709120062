import { useState } from 'react';
import { MinusOutlined } from '@ant-design/icons';
import { Color } from '@ui/types/Color';
import { CustomDropdown } from '@ui/components/CustomDropdown';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Styles from './style';
import { LOCALES } from '../../../../data/locales';

interface ColorRulesRowProps {
    color: Color;
    onAddLocale: (colorId: number, locale: string) => void;
    onRemoveLocale: (colorId: number, locale: string) => void;
    openDeleteModal: (
        colorId: number,
        colorName: string,
        colorHex: string
    ) => void;
}

export const ColorRulesRow = (props: ColorRulesRowProps) => {
    const { color, onAddLocale, onRemoveLocale, openDeleteModal } = props;
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const showInput = () => {
        setInputVisible(true);
    };

    const handleLocaleInputChange = (value: string | number) => {
        if (typeof value === 'string') {
            setInputValue(value);
        }
    };

    const handleLocaleInputConfirm = () => {
        if (inputValue != '') {
            onAddLocale(color.id, inputValue);
        }
        setInputVisible(false);
        setInputValue('');
    };

    const handleTrash = () => {
        openDeleteModal(color.id, color.name, color.hex);
    };

    return (
        <Styles.ErrContainer>
            <Styles.ErrColorDot style={{ backgroundColor: `${color.hex}` }} />
            <Styles.ErrColorNameContainer>
                <Styles.ErrColorName>{color.name}</Styles.ErrColorName>
                {!color?.isDefault && (
                    <Styles.ErrTrashIcon onClick={handleTrash}>
                        <FontAwesomeIcon icon={faTrash} className="icon" />
                    </Styles.ErrTrashIcon>
                )}
            </Styles.ErrColorNameContainer>

            <div>
                {color.isDefault && <span>Primary color</span>}
                {!color.isDefault && (
                    <>
                        <div>Based on user locale. Shows in these locales:</div>
                        <Styles.ErrTagContainer>
                            {color.rules?.map((rule) => (
                                <Styles.ErrColorRule key={rule.locale}>
                                    {rule.locale}
                                    <MinusOutlined
                                        onClick={() => {
                                            onRemoveLocale(
                                                color.id,
                                                rule.locale
                                            );
                                        }}
                                    />
                                </Styles.ErrColorRule>
                            ))}
                            {inputVisible ? (
                                <Styles.ErrInput>
                                    <CustomDropdown
                                        options={LOCALES}
                                        placeholder="Select New Locale"
                                        onChange={handleLocaleInputChange}
                                        onBlur={handleLocaleInputConfirm}
                                    />
                                </Styles.ErrInput>
                            ) : (
                                <Styles.ErrNewLocaleBtn
                                    onClick={() => showInput()}
                                >
                                    New Locale
                                </Styles.ErrNewLocaleBtn>
                            )}
                        </Styles.ErrTagContainer>
                    </>
                )}
            </div>
        </Styles.ErrContainer>
    );
};
