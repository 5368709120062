import styled from 'styled-components';

export const CoContainer = styled.div`
    margin-top: 30px;
`;

export const CoHeader = styled.div`
    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-Medium';
`;

export const CoDesc = styled.div`
    margin-top: 5px;
    font-size: 15px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';
`;

export const CoCheckBox = styled.div`
    margin-top: 23px;
    display: flex;
    align-items: center;
    gap: 100px;

    flex-wrap: wrap;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
`;
