import styled from 'styled-components';

export const NfContainer = styled.div`
    margin-top: 22px;
`;

export const NfHeader = styled.div`
    margin-top: 24px;
    font-size: 16px;
    color: #2a2a30;
    font-family: 'Poppins-Bold';
`;

export const NfSubHeader = styled.div`
    margin-top: 4px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    font-family: 'Poppins-Medium';
`;
