import * as Styles from './style';
import { useGetProfileQuery } from '@ui/services/profile';

export const About = () => {
    const { data: profile } = useGetProfileQuery();

    return (
        <Styles.PaContainer>
            <Styles.PaName>My Profile</Styles.PaName>

            {profile?.userBannerImagePath && (
                <Styles.PaProfileCoverContainer>
                    <Styles.PaProfileCover
                        alt="cover"
                        src={profile.userBannerImagePath}
                    />
                </Styles.PaProfileCoverContainer>
            )}

            <Styles.PaProfilePicContainer>
                <div>
                    <Styles.PaProfilePic src={profile?.userProfileImagePath} />
                </div>
                <div>
                    <Styles.PaName>
                        {profile?.userFirstName} {profile?.userLastName}
                    </Styles.PaName>
                    <Styles.PaDesignation>
                        {' '}
                        {profile?.userDesignation}
                    </Styles.PaDesignation>
                </div>
            </Styles.PaProfilePicContainer>

            <Styles.PaPersonalInfo>
                <Styles.PaHeader>Personal Information</Styles.PaHeader>

                <Styles.PaPersonalInfoContainer>
                    <div>
                        <div>
                            <Styles.PaPersonalInfoItemHeader>
                                First Name
                            </Styles.PaPersonalInfoItemHeader>
                            <Styles.PaPersonalInfoItemValue>
                                {profile?.userFirstName}
                            </Styles.PaPersonalInfoItemValue>
                        </div>

                        <Styles.PaPersonalInfoItem>
                            <Styles.PaPersonalInfoItemHeader>
                                Last Name
                            </Styles.PaPersonalInfoItemHeader>
                            <Styles.PaPersonalInfoItemValue>
                                {profile?.userLastName}
                            </Styles.PaPersonalInfoItemValue>
                        </Styles.PaPersonalInfoItem>

                        <Styles.PaPersonalInfoItem>
                            <Styles.PaPersonalInfoItemHeader>
                                Email
                            </Styles.PaPersonalInfoItemHeader>
                            <Styles.PaPersonalInfoItemValue>
                                {profile?.userEmail}
                            </Styles.PaPersonalInfoItemValue>
                        </Styles.PaPersonalInfoItem>
                    </div>
                    <div>
                        <div>
                            <Styles.PaPersonalInfoItemHeader>
                                Bio
                            </Styles.PaPersonalInfoItemHeader>
                            <Styles.PaPersonalInfoItemValue>
                                {profile?.userBio}
                            </Styles.PaPersonalInfoItemValue>
                        </div>

                        <Styles.PaPersonalInfoItem>
                            <Styles.PaPersonalInfoItemHeader>
                                Designation
                            </Styles.PaPersonalInfoItemHeader>
                            <Styles.PaPersonalInfoItemValue>
                                {profile?.userDesignation}
                            </Styles.PaPersonalInfoItemValue>
                        </Styles.PaPersonalInfoItem>
                    </div>
                    <div></div>
                </Styles.PaPersonalInfoContainer>
            </Styles.PaPersonalInfo>

            <Styles.PaPersonalInfo>
                <Styles.PaHeader>Subscription Information</Styles.PaHeader>
            </Styles.PaPersonalInfo>
        </Styles.PaContainer>
    );
};
