import tinycolor from 'tinycolor2';
import { ColorRepresentations } from '@ui/components/ColorPicker/ColorRepresentations';
import {
    COLOR_CONTRAST_RESULT,
    COLOR_CONTRAST_TYPES,
    COLOR_PLACEMENT,
} from '@ui/types/types';

export const getLocale = (): string => {
    const navigator = window.navigator;
    return navigator.userLanguage ?? navigator.language;
};

export const getContrastRatioForSmallText = (
    type: COLOR_CONTRAST_TYPES
): number => {
    return type === COLOR_CONTRAST_TYPES.AA ? 4.5 : 7;
};

export const getContrastRatioForLargeText = (
    type: COLOR_CONTRAST_TYPES
): number => {
    return type === COLOR_CONTRAST_TYPES.AA ? 3 : 4.5;
};

export const calcForSmallText = (
    ratio: number,
    contrastType: COLOR_CONTRAST_TYPES
): COLOR_CONTRAST_RESULT => {
    return ratio >= getContrastRatioForSmallText(contrastType)
        ? COLOR_CONTRAST_RESULT.PASS
        : COLOR_CONTRAST_RESULT.FAIL;
};

export const calcForLargeText = (
    ratio: number,
    contrastType: COLOR_CONTRAST_TYPES
): COLOR_CONTRAST_RESULT => {
    return ratio >= getContrastRatioForLargeText(contrastType)
        ? COLOR_CONTRAST_RESULT.PASS
        : COLOR_CONTRAST_RESULT.FAIL;
};

export const getColorPlacement = (
    colorPlacement: COLOR_PLACEMENT,
    primaryColor: ColorRepresentations,
    secondaryColor: ColorRepresentations
): string => {
    return colorPlacement === COLOR_PLACEMENT.FOREGROUND
        ? primaryColor.hex
        : secondaryColor.hex;
};

export const isHexColorValid = (hexColor: string): boolean => {
    const color = tinycolor(hexColor);
    return color.isValid();
};

export const ensureHashPrefix = (input: string): string => {
    // Check if the string starts with '#'
    if (input.startsWith('#')) {
        return input;
    } else {
        // Add '#' at the beginning of the string
        return `#${input}`;
    }
};
