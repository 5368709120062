import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import { useAppDispatch } from '@ui/app/hooks';
import { authLogout } from '@ui/slices/authSlice';
import NotificationIcon from '@ui/images/notification-icon.svg';
import UserPlaceholder from '@ui/images/user-placeholder.svg';
import ArrowWhite from '@ui/images/arrow-white.svg';
import { useGetProfileQuery } from '@ui/services/profile';
import { useClickOutside } from '@ui/utils/hooks/useClickOutside';
import { toggleSidebar } from '@ui/slices/appStateSlice';
import * as Styles from './style';

export const Navbar = () => {
    const [open, setOpen] = useState(false);

    const dispatch = useAppDispatch();

    const handleLogout = () => {
        dispatch(authLogout());
    };

    const { data: profile } = useGetProfileQuery();

    const dropdownRef = useRef(null);

    const closeProfileDropdown = () => {
        setOpen(false);
    };

    useClickOutside(dropdownRef, closeProfileDropdown);

    const openSideBar = () => {
        dispatch(toggleSidebar());
    };

    return (
        <Styles.NavbarWrapper>
            <Styles.NavbarContainer>
                <Styles.NavHamburger onClick={openSideBar}>
                    <FontAwesomeIcon icon={faBars} className="icon" />
                </Styles.NavHamburger>
                <Styles.NavbarUserInfo>
                    <Styles.NavbarNotification
                        src={NotificationIcon}
                        alt="notification"
                    />
                    <Styles.NavbarLine />

                    <Styles.NavbarProfilePic onClick={() => setOpen((v) => !v)}>
                        <Styles.NavbarUserName>
                            {profile?.userFirstName}
                        </Styles.NavbarUserName>
                        <Styles.NavbarImg
                            src={
                                profile?.userProfileImagePath ?? UserPlaceholder
                            }
                            alt="profile"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = UserPlaceholder;
                            }}
                        />
                        <Styles.NavbarDropdownArrow
                            src={ArrowWhite}
                            alt="dropdown"
                            open={open}
                        />
                        {open && (
                            <Styles.NavbarDropdown ref={dropdownRef}>
                                <Styles.NavbarDropdownContainer>
                                    <Styles.NavbarDropdownItem to={'/profile'}>
                                        {profile?.userFirstName}{' '}
                                        {profile?.userLastName}
                                    </Styles.NavbarDropdownItem>
                                    <Styles.NavbarDropdownItem to={'/profile'}>
                                        Edit profile
                                    </Styles.NavbarDropdownItem>
                                    <Styles.NavbarDropdownItem to={'#'}>
                                        Need Help?
                                    </Styles.NavbarDropdownItem>
                                </Styles.NavbarDropdownContainer>
                                <Styles.NavbarDropdownLine />
                                <Styles.NavbarLogout onClick={handleLogout}>
                                    Logout
                                </Styles.NavbarLogout>
                            </Styles.NavbarDropdown>
                        )}
                    </Styles.NavbarProfilePic>
                </Styles.NavbarUserInfo>
            </Styles.NavbarContainer>
        </Styles.NavbarWrapper>
    );
};
