import styled from 'styled-components';
import { Button } from '@ui/components/Button';

export const PaContainer = styled.div`
    padding: 20px 0;
`;

export const PaProfilePicContainer = styled.div`
    margin-top: 20px;
    display: grid;
    padding: 20px;
    grid-template-columns: 50px minmax(0, 1fr);
    grid-gap: 18px;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
`;

export const PaProfileCoverContainer = styled.div`
    margin-top: 20px;
`;

export const PaProfileCover = styled.img`
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
`;

export const PaProfilePic = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #d9d9d9;
    object-fit: cover;
`;

export const PaName = styled.div`
    font-size: 18px;
    color: #2a2a30;
    font-weight: 600;
`;

export const PaDesignation = styled.div`
    margin-top: 10px;
    font-size: 14px;
    color: #aaaaaa;
    font-weight: 400;
`;

export const PaHeader = styled.div`
    font-size: 18px;
    color: #2a2a30;
    font-weight: 600;
`;

export const PaPersonalInfo = styled.div`
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
`;

export const PaPersonalInfoContainer = styled.div`
    margin: 20px 0 0 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
`;

export const PaEditBtn = styled(Button)`
    width: 150px;
`;

export const PaPersonalInfoItem = styled.div`
    margin-top: 20px;
`;

export const PaPersonalInfoItemHeader = styled.div`
    font-size: 16px;
    color: gray;
`;

export const PaPersonalInfoItemValue = styled.div`
    margin-top: 10px;
    font-size: 18px;
    color: #2a2a30;
    font-weight: 600;
`;
