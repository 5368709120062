import { Button } from '@ui/components/Button';
import styled from 'styled-components';

export const SpContainer = styled.div`
    position: relative;
    width: 100%;
    min-height: 500px;
    padding: 20px 20px 90px 20px;
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    transition: 300ms all;

    &:hover {
        border-color: #4096ff;
    }
`;

export const SpContainerHeader = styled.div`
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    color: #2a2a30;
`;

export const SpPriceText = styled.div`
    margin-top: 10px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    color: #2a2a30;
`;

export const SpBuyBtn = styled(Button)`
    width: calc(100% - 40px);
    position: absolute;
    bottom: 20px;
`;

export const SpFeatureDesc = styled.div`
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #2a2a30;
    text-transform: capitalize;
`;

export const SpFeatureList = styled.ul`
    list-style-position: inside;
`;

export const SpSubFeatureDesc = styled.li`
    margin-top: 10px;

    font-weight: 400;
    font-size: 12px;
    color: #2a2a30;
`;
