import { Formik, Form, ErrorMessage } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { BtnLoader } from '@ui/components/Loaders';
import { useUpdateProjectMutation } from '@ui/services/project';
import { TextInput } from '@ui/components/TextInput';

import * as Styles from './style';

interface RenameProjectProps {
    handleCancel: () => void;
    handleConfirm: () => void;
    projectId: string;
    originalName: string | undefined;
}

interface CreateProjectData {
    projectName: string;
}

const validationSchema = Yup.object().shape({
    projectName: Yup.string()
        .required('Name is required')
        .min(2, 'Name is too short')
        .max(40, 'Name is too long'),
});

export const RenameProject = (props: RenameProjectProps) => {
    const { handleConfirm, handleCancel, originalName, projectId } = props;

    const [updateProject, { isLoading }] = useUpdateProjectMutation();

    const initialValues: CreateProjectData = {
        projectName: originalName ?? '',
    };

    const handleRenameProject = async (values: CreateProjectData) => {
        try {
            const { projectName } = values;

            await updateProject({
                name: projectName,
                projectId: parseInt(projectId),
            }).unwrap();

            handleConfirm();

            toast.success('Project updated');
        } catch (err) {
            console.log('err', err);
            toast.error('Something went wrong');
        }
    };

    return (
        <Styles.DpContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleRenameProject}
                enableReinitialize
            >
                {() => (
                    <Form>
                        <Styles.DpHeader>Rename Project</Styles.DpHeader>

                        <Styles.DpLine />

                        <Styles.DpConfirm>
                            Enter a new name for your project
                        </Styles.DpConfirm>

                        <div>
                            <TextInput
                                type="text"
                                name="projectName"
                                id="projectName"
                                placeholder="Enter Project Name"
                                showIcon={false}
                            />
                        </div>

                        <Styles.CpErrors>
                            <ErrorMessage name="projectName" component="div" />
                        </Styles.CpErrors>

                        <Styles.DpBtns>
                            <Styles.DpBtn type="button" onClick={handleCancel}>
                                Cancel
                            </Styles.DpBtn>
                            <Styles.DpBtn type="submit" isDisabled={isLoading}>
                                {isLoading ? <BtnLoader /> : 'Confirm'}
                            </Styles.DpBtn>
                        </Styles.DpBtns>
                    </Form>
                )}
            </Formik>
        </Styles.DpContainer>
    );
};
