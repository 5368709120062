import styled from 'styled-components';
import { Button } from '@ui/components/Button';

export const RfContainer = styled.div`
    width: 504px;
    padding: 30px 32px 58px 32px;
    background: rgba(255, 255, 255, 0.9);
    opacity: 0.9;
    box-shadow: 0px 14px 50px 0px rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(25px);
    border-radius: 10px;

    @media (max-width: 600px) {
        width: 100%;
        padding: 30px 20px 58px 20px;
    }
`;

export const RfWrapper = styled.div`
    margin-top: 28px;
`;

export const RfInputRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
`;

export const RfFormControl = styled.div``;

export const RfFormControl2 = styled.div`
    margin-top: 16px;
`;

export const RfFieldControl = styled.div`
    margin-top: 6px;
`;

export const RfLabel = styled.label`
    color: #2a2a30;
    font-family: 'Poppins-Medium';
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
`;

export const RfSubmitBtn = styled(Button)`
    margin: 30px 0 0 auto;
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

export const RfErrMsg = styled.div`
    color: #df27e8;
    margin-top: 8px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
`;

export const RfLogo = styled.img`
    width: 90px;
    height: 90px;
    object-fit: cover;
    border: 5px solid #ffffff;
    border-radius: 50%;
`;

export const RfHeader = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;

    span {
        color: #2a2a30;
        font-family: 'Poppins-SemiBold';
        font-size: 40px;
    }

    @media (max-width: 500px) {
        span {
            font-size: 30px;
        }
    }

    @media (max-width: 350px) {
        span {
            font-size: 20px;
        }
    }
`;

export const RfHeaderText = styled.div`
    position: absolute;
    top: -36px;
    left: 210px;

    color: #2a2a30;
    font-family: 'Qwitcher Grypen';
    font-size: 100px;

    @media (max-width: 500px) {
        top: -30px;
        left: 150px;
        font-size: 75px;
    }

    @media (max-width: 350px) {
        top: -13px;
        left: 110px;
        font-size: 40px;
    }
`;

export const RfSubHeader = styled.div`
    color: #2a2a30;
    font-family: 'Poppins-Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    opacity: 0.98;

    @media (max-width: 450px) {
        br {
            display: none;
        }
    }
`;

export const RfLoginText = styled.div`
    margin-top: 17px;
    display: flex;
    align-items: center;
    gap: 3px;
    color: #2a2a30;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    line-height: 22px;

    a {
        color: #2a2a30;
        font-family: 'Poppins-Medium';
        font-size: 14px;
        line-height: 22px;
    }
`;
