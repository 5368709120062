import styled from 'styled-components';
import { Button } from '@ui/components/Button';

export const ProPage = styled.div`
    padding: 100px 0;
    min-height: 100vh;
    color: #2a2a30;
`;

export const ProjectNameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ProjectName = styled.div`
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    color: #2a2a30;

    span {
        font-family: 'Poppins-Regular';
    }
`;

export const ProHeader = styled.h3`
    margin: 20px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ProPaletteContainer = styled.div`
    margin: 20px 0;
`;

export const ProPaletteList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
    grid-gap: 20px;
`;

export const ProPaletteItem = styled.div`
    height: 100px;
    cursor: pointer;
    border: 1px solid #dddddd;
    border-radius: 10px;
    background-color: white;
    padding: 10px;
    font: normal normal 300 18px/24px Roboto;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const ProAddMemBtn = styled(Button)`
    width: 150px;
`;

export const TeamListItem = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr 1fr;
    align-items: center;
    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';

    @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        font-size: 14px;
    }
`;

export const TeamListCenterText = styled.div`
    text-align: center;
`;

export const TeamListRightAligned = styled.div`
    display: flex;
    justify-content: flex-end;
    color: red;

    @media (max-width: 800px) {
        justify-content: flex-start;
    }
`;

export const TeamListContainer = styled.div`
    margin: 30px 0 0 0;
`;

export const TeamList = styled.div`
    ${TeamListItem}:not(:first-child) {
        margin: 20px 0 0 0;
    }
`;

export const TpiName = styled.div`
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr);
    grid-gap: 20px;
    align-items: center;
`;

export const TpiName2 = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr);
    grid-gap: 20px;
    align-items: center;
`;

export const TpiImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
`;

export const ApiKeyContainer = styled.div`
    margin: 30px 0 0 0;
`;

export const ProCreateKeyBtn = styled(Button)`
    margin: 30px 0 0 0;
    width: 150px;
`;

export const ProjectApiKey = styled.div`
    font-size: 16px;
    color: #2a2a30;
`;
