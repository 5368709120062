import styled from 'styled-components';
import { Button } from '@ui/components/Button';

export const ChpContainer = styled.div`
    background: #f5f5f5;
    min-height: 100vh;
    padding: 110px 0;
    color: #2a2a30;
`;

export const ChpHeader = styled.div`
    color: #2a2a30;
    font-family: 'Poppins-Bold';
    font-size: 28px;
`;

export const ChpSubHeader = styled.div`
    margin-top: 2px 0 0 0;
    color: rgba(0, 0, 0, 0.7);
    font-family: 'Poppins-Medium';
    font-size: 13px;
`;

export const ChpLine = styled.div`
    margin: 30px 0;
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
`;

export const ChpLabelContainer = styled.div`
    display: grid;
    grid-template-columns: 37px minmax(0, 1fr);
    grid-gap: 14px;
    align-items: center;
`;

export const ChpLabelBox = styled.div`
    width: 37px;
    height: 37px;
    border-radius: 8px;
    background: #d9d9d9;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 22px;
        height: 22px;
        object-fit: cover;
    }
`;

export const ChpLabelText = styled.label`
    cursor: pointer;
    color: #2a2a30;
    font-family: 'Poppins-SemiBold';
    font-size: 16px;
`;

export const ChpFormControl = styled.div`
    margin-top: 22px;
`;

export const ChpFormInputContainer = styled.div`
    margin: 10px 0 0 0;
    padding: 0 0 0 51px;
    display: grid;
    grid-template-columns: 220px 30px 220px;
    grid-gap: 21px;
    align-items: center;

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const ChpOrText = styled.div`
    color: #2a2a30;
    font-family: 'Poppins-Bold';
    font-size: 12px;
`;

export const ChpTextInput = styled.div`
    position: relative;
    border: 1px solid #2a2a30;
    border-radius: 2px;
    transition: 300ms all;

    &:hover {
        border-color: #4096ff;
    }

    &:focus-within {
        border-color: #4096ff;
        box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
        outline: 0;
    }

    input {
        width: 100%;
        padding: 9px 14px 9px 14px;
        color: #2a2a30;
        font-family: 'Poppins-Medium';
        font-size: 14px;

        background-color: transparent;
        border: 0;

        &:focus {
            border: 0;
            border-color: transparent;
            outline: 0;
        }

        &::placeholder {
            color: rgba(0, 0, 0, 0.5);
            font-family: 'Poppins-Medium';
            font-size: 14px;
        }
    }
`;

export const ChpBtnContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 44px;
`;

export const ChpCancelBtn = styled.button`
    height: 44px;
    color: #2a2a30;
    text-align: center;
    font-family: 'Poppins-Medium';
    font-size: 12px;
    border: 0;
    background: transparent;
    cursor: pointer;
`;

export const ChpCreateBtn = styled(Button)`
    width: 174px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

export const ChpFormErrors = styled.div`
    font: normal normal normal 18px/33px Roboto;
    color: red;
    margin-left: 5%;
`;

export const CpErrors = styled.div`
    color: #df27e8;
    margin-top: 8px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
`;
