import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleList } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import {
    useGetColorsFromHueQuery,
    useUpdateColorRulesMutation,
} from '@ui/services/color';
import {
    addColorLocaleRule,
    removeColorLocaleRule,
    resetColors,
    selectColorList,
    setColors,
} from '@ui/slices/colorListSlice';
import { selectHue } from '@ui/slices/hueSlice';
import { ColorRulesRow } from '../../components/ColorRulesRow';
import { ColorDeletion } from '../../components';
import * as Styles from './style';
import { useParams } from 'react-router-dom';
import { CustomModal } from '@ui/components/CustomModal';

export const ColorRules = () => {
    const { hueId } = useParams();

    const dispatch = useAppDispatch();
    const hue = useAppSelector(selectHue);
    const colorList = useAppSelector(selectColorList);

    const { data: colors, isLoading } = useGetColorsFromHueQuery(
        parseInt(hueId!)
    );

    const [apiSaveRules] = useUpdateColorRulesMutation();

    const [showDelete, setShowDelete] = useState(false);

    const [colorId, setColorId] = useState(-1);
    const [colorHex, setColorHex] = useState('#000000');
    const [colorName, setColorName] = useState('');

    useEffect(() => {
        if (Array.isArray(colors) && !isLoading) {
            dispatch(setColors(colors));
        }
    }, [colors, colors?.length, isLoading]);

    useEffect(() => {
        return () => {
            dispatch(resetColors());
        };
    }, []);

    const handleAddLocale = (colorId: number, locale: string) => {
        dispatch(addColorLocaleRule({ colorId, locale }));
    };

    const handleRemoveLocale = (colorId: number, locale: string) => {
        dispatch(removeColorLocaleRule({ colorId, locale }));
    };

    const saveRules = async () => {
        try {
            colorList.forEach((color) => {
                apiSaveRules({
                    colorId: color.id,
                    rules: color.rules ?? [],
                }).unwrap();
            });

            toast.success("'Successfully saved hue and color rules.'");
        } catch (error) {
            toast.error(`Error saving color rules: ${error}`);
        }
    };

    const openDeleteModal = (
        colorId: number,
        colorName: string,
        colorHex: string
    ) => {
        setShowDelete(true);
        setColorId(colorId);
        setColorName(colorName);
        setColorHex(colorHex);
    };

    const handleClose = () => {
        setShowDelete(false);
        setColorId(-1);
        setColorHex('#000000');
        setColorName('');
    };

    return (
        <Styles.Errd>
            <Styles.ErrdContainer>
                <Styles.ErrdRulesInformation>
                    <Styles.ErrdIcon>
                        <FontAwesomeIcon icon={faRectangleList} size="2x" />
                        <Styles.ErrdHeader1>
                            {hue.name} Rules
                        </Styles.ErrdHeader1>
                    </Styles.ErrdIcon>
                    <Styles.ErrdRulesListInfo>
                        <Styles.ErrHeader2>Your hue rules:</Styles.ErrHeader2>
                        <Styles.ErrdRulesList>
                            {colorList?.map((color) => (
                                <ColorRulesRow
                                    key={color.id}
                                    color={color}
                                    onAddLocale={handleAddLocale}
                                    onRemoveLocale={handleRemoveLocale}
                                    openDeleteModal={openDeleteModal}
                                />
                            ))}
                        </Styles.ErrdRulesList>
                    </Styles.ErrdRulesListInfo>
                </Styles.ErrdRulesInformation>
                <Styles.ErrdSaveBtnContainer>
                    <Styles.ErrdSaveBtn onClick={saveRules}>
                        SAVE
                    </Styles.ErrdSaveBtn>
                </Styles.ErrdSaveBtnContainer>
            </Styles.ErrdContainer>

            <CustomModal show={showDelete}>
                {showDelete && (
                    <ColorDeletion
                        colorId={colorId}
                        colorName={colorName}
                        colorHex={colorHex}
                        handleClose={handleClose}
                    />
                )}
            </CustomModal>
        </Styles.Errd>
    );
};
