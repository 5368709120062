import styled from 'styled-components';
import { Link } from 'react-router-dom';

type SbWrapperProps = {
    $showSideBar: boolean;
};

export const SbWrapper = styled.div<SbWrapperProps>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 220px;
    background-color: #e3e3e3;
    z-index: 200;
    transition: 300ms all;

    @media (max-width: 1000px) {
        width: 100%;
        transform: ${({ $showSideBar }) =>
            $showSideBar ? 'translateX(0)' : 'translateX(-100%)'};
    }
`;

export const SbContainer = styled.div`
    width: 100%;
    background-color: #e3e3e3;
`;

export const SbOnHueLogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SbOnHueLogoContainer = styled(Link)`
    cursor: pointer;
    display: block;
    padding: 24px 0 34px 17px;
`;

export const SbOnHueLogo = styled.img`
    width: 54px;
    height: 54px;
    object-fit: cover;
    border: 3px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
`;

export const SidebarHamburger = styled.div`
    cursor: pointer;
    width: 60px;
    height: 60px;

    display: none;
    align-items: center;
    justify-content: flex-start;

    font-size: 30px;
    color: #2a2a30;

    @media (max-width: 1000px) {
        display: flex;
    }
`;

export const SbIconList = styled.div``;

export const SbIconListItem = styled(Link)`
    display: block;
    height: 46px;
    padding: 11px 20px;
    transition: 300ms all;

    display: flex;
    align-items: center;
    gap: 20px;
    text-decoration: none;
    color: #515e71;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: #2a2a30;
    }

    font-size: 16px;
    font-family: 'Poppins-Regular';
`;

export const SbIconListItem2 = styled.a`
    display: block;
    height: 46px;
    padding: 11px 20px;
    transition: 300ms all;

    display: flex;
    align-items: center;
    gap: 20px;
    text-decoration: none;
    color: #515e71;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: #2a2a30;
    }

    font-size: 16px;
    font-family: 'Poppins-Regular';
`;

export const SbIconContainer = styled.div`
    cursor: pointer;
    width: 24px;
    height: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    .icon {
        color: #2a2a30;
        width: 20px;
        height: 20px;
    }
`;
