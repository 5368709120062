import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useResetOldPasswordMutation } from '@ui/services/auth';
import { PasswordResetData } from '@ui/types/LoginData';
import { BtnLoader } from '@ui/components/Loaders';
import { TextInput } from '@ui/components/TextInput';
import OnHueLogoRound from '@ui/images/onHueLogoRound.svg';
import RightArrow from '@ui/images/right-arrow-icon.svg';
import BlackLeftArrow from '@ui/images/black-left-arrow.svg';

import * as Styles from '../style';

interface ResetInitialValues {
    password: string;
    confirmPassword: string;
}

const initialValues: ResetInitialValues = {
    password: '',
    confirmPassword: '',
};

const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().required('Confirm password is required'),
});

export const ResetPasswordForm = () => {
    const { search } = useLocation();

    const queryParams = new URLSearchParams(search);

    const token = queryParams.get('token');

    const navigate = useNavigate();

    const [attemptResetPassword, { isLoading }] = useResetOldPasswordMutation();

    const handleSubmit = async (values: ResetInitialValues) => {
        const email = localStorage.getItem('recover-account-email');

        if (email) {
            try {
                const resetPasswordData: PasswordResetData = {
                    email,
                    token: token as string,
                    newPassword: values.password,
                };

                console.log('resetPasswordData ', resetPasswordData);

                await attemptResetPassword(resetPasswordData).unwrap();

                toast.success('Password updated!');
                localStorage.removeItem('recover-account-email');
                navigate('/');
            } catch (err) {
                console.log('err ', err);
                toast.error('Something went wrong');
            }
        } else {
            toast.error('Email not found');
        }
    };

    return (
        <Styles.RfContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <div>
                            <Styles.RfLogo src={OnHueLogoRound} alt="onhue" />
                            <Styles.RfHeader>Reset Password?</Styles.RfHeader>
                            <Styles.RfSubHeader>
                                Choose a new password to recover <br /> your
                                account
                            </Styles.RfSubHeader>

                            <Styles.RfFormContainer>
                                <Styles.RfFormControl>
                                    <Styles.RfLabel htmlFor="password">
                                        PASSWORD
                                    </Styles.RfLabel>

                                    <Styles.RfFieldControl>
                                        <TextInput
                                            type="password"
                                            name="password"
                                            id="password"
                                            showIcon={true}
                                        />
                                    </Styles.RfFieldControl>

                                    <Styles.RfErrMsg>
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                        />
                                    </Styles.RfErrMsg>
                                </Styles.RfFormControl>

                                <Styles.RfFormControl2>
                                    <Styles.RfLabel htmlFor="confirmPassword">
                                        CONFIRM PASSWORD
                                    </Styles.RfLabel>

                                    <Styles.RfFieldControl>
                                        <TextInput
                                            type="password"
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            showIcon={true}
                                        />
                                    </Styles.RfFieldControl>

                                    <Styles.RfErrMsg>
                                        <ErrorMessage
                                            name="confirmPassword"
                                            component="div"
                                        />
                                    </Styles.RfErrMsg>
                                </Styles.RfFormControl2>
                            </Styles.RfFormContainer>

                            <Styles.RfSubmitBtn
                                type="submit"
                                isDisabled={isLoading}
                            >
                                {isLoading ? (
                                    <BtnLoader />
                                ) : (
                                    <>
                                        <div>Reset Password</div>
                                        <img src={RightArrow} alt="arrow" />
                                    </>
                                )}
                            </Styles.RfSubmitBtn>
                        </div>

                        <Styles.RfReturnText to={'/'}>
                            <img src={BlackLeftArrow} alt="back" />
                            <span>Return to Sign in</span>
                        </Styles.RfReturnText>
                    </Form>
                )}
            </Formik>
        </Styles.RfContainer>
    );
};
