import { useState } from 'react';
import { RESET_PASSWORD_REQUEST_STEPS } from '@ui/types/types';
import * as Styles from '../../style';
import { ResetRequestForm } from '../../components/ResetRequestForm';
import { ResetRequestSuccess } from '../../components/ResetRequestSuccess';

export const ForgotPassword = () => {
    const [step, setStep] = useState<RESET_PASSWORD_REQUEST_STEPS>(
        RESET_PASSWORD_REQUEST_STEPS.REQUEST
    );

    const handleResetRequestSuccess = () => {
        setStep(RESET_PASSWORD_REQUEST_STEPS.SUCCESS);
    };

    const handleGoToEditEmailStep = () => {
        setStep(RESET_PASSWORD_REQUEST_STEPS.REQUEST);
    };

    const renderForm = () => {
        switch (step) {
            case RESET_PASSWORD_REQUEST_STEPS.REQUEST:
                return (
                    <ResetRequestForm
                        handleResetRequestSuccess={handleResetRequestSuccess}
                    />
                );

            case RESET_PASSWORD_REQUEST_STEPS.SUCCESS:
                return (
                    <ResetRequestSuccess
                        handleGoToEditEmailStep={handleGoToEditEmailStep}
                    />
                );

            default:
                return null;
        }
    };

    return (
        <Styles.FpPage>
            <Styles.FpContainer>{renderForm()}</Styles.FpContainer>
        </Styles.FpPage>
    );
};
