import styled from 'styled-components';
import { Button } from '@ui/components/Button';

export const DpPage = styled.div`
    padding: 100px 0 100px 0;
    min-height: 100vh;
    color: #2a2a30;
`;

export const DpPageHeader = styled.div`
    font-size: 32px;
    color: #2a2a30;
    font-family: 'Poppins-Medium';

    @media (max-width: 700px) {
        font-size: 24px;
    }
`;

export const DpPageSubHeader = styled.div`
    margin-top: 8px;
    font-size: 16px;
    color: #757575;
    font-family: 'Poppins-Regular';
`;

export const DpStats = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
`;

export const DpStatItem = styled.div`
    padding: 20px 0;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);

    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;

    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: 300ms all;

    &:hover {
        box-shadow: rgba(149, 157, 165, 0.35) 0px 10px 20px;
    }
`;

export const DpStatItemHeader = styled.div`
    text-align: center;
    font-size: 24px;
    color: #2a2a30;
    font-family: 'Poppins-Medium';
`;

export const DpStatItemValue = styled.div`
    text-align: center;
    font-size: 16px;
    color: #757575;
    font-family: 'Poppins-Regular';
`;

export const DpHeader = styled.h1`
    margin-top: 70px;
    font: normal normal 300 33px/44px Roboto;
`;

export const DpGraphSection = styled.div`
    margin-top: 30px;
`;

export const DpGraphs = styled.div`
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
`;

export const DpGraphItem = styled.div`
    padding: 20px;
    height: 340px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);

    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    text-align: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: 300ms all;

    &:hover {
        box-shadow: rgba(149, 157, 165, 0.35) 0px 10px 20px;
    }
`;

export const DpManageHeader = styled.div`
    margin: 30px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 32px;
    color: #2a2a30;
    font-family: 'Poppins-Medium';

    @media (max-width: 700px) {
        font-size: 24px;
    }

    @media (max-width: 500px) {
        flex-direction: column;
        gap: 20px;
    }
`;

export const DpHueListContainer = styled.div`
    margin-top: 30px;
`;

export const DpHueList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
    gap: 20px;
`;

export const DpCreateNewColor = styled(Button)`
    width: 203px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media (max-width: 500px) {
        width: 100%;
    }
`;

export const DpProjectListContainer = styled(DpHueListContainer)``;

export const DpProjectList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
    grid-gap: 20px;
`;

export const DpProjectListItem = styled.div`
    cursor: pointer;
    min-height: 100px;
    padding: 14px 12px 18px 12px;
    border-radius: 10px;

    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: 300ms all;

    &:hover {
        box-shadow: rgba(149, 157, 165, 0.35) 0px 10px 20px;
    }
`;

export const DpProjectItemName = styled.div`
    font-size: 14px;
    color: #2a2a30;
    font-family: 'Poppins-Bold';
`;

export const DpProjectItemApiKey = styled.div`
    margin-top: 10px;
    font-size: 12px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';
`;

export const DpLine = styled.div`
    margin: 30px 0 0 0;
    width: 100%;
    height: 1px;
    opacity: 0.3;
    background: #757575;
`;
