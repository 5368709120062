import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@ui/app/store';
import { Color } from '@ui/types/Color';

const initialState: Color = {
    id: -1,
    hueId: 0,
    isDefault: false,
    hex: '#000000',
    name: 'New Color',
    rationale: '',
    colorDescriptors: '',
    colorTags: '',
    aaaHex: '',
    aaHex: '',
    colorSetting: {
        aaaHexOL: false,
        aaHexOL: false,
        colorPlacement: null,
        contrastHex: '',
        defaultColor: null,
    },
    isGradient: false,
    gradientHex: '#000000',
};

export const colorSlice = createSlice({
    name: 'color',
    initialState,
    reducers: {
        resetColor: () => {
            return { ...initialState };
        },
        setColor: (_, action) => {
            return { ...action.payload };
        },
        setColorId: (state, action) => {
            state.isDefault = action.payload;
        },
        setHueId: (state, action) => {
            state.hueId = action.payload;
        },
        setHex: (state, action) => {
            state.hex = action.payload;
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setRationale: (state, action) => {
            state.rationale = action.payload;
        },
    },
});

export const {
    resetColor,
    setColor,
    setColorId,
    setHueId,
    setHex,
    setName,
    setRationale,
} = colorSlice.actions;

export const selectColor = (state: RootState) => state.color;

export default colorSlice.reducer;
