import * as Styles from './style';
import { LoginForm } from './components/LoginForm';

const LoginPage = () => {
    return (
        <Styles.LoginPage>
            <Styles.LpContainer>
                <LoginForm />
            </Styles.LpContainer>
        </Styles.LoginPage>
    );
};

export { LoginPage };
