import { SUBSCRIPTION_PLANS } from '@ui/types/Profile';
import { api } from './api';
import { CreateUserSubscription } from '@ui/types/types';

export const subscriptionApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSubscriptionPlans: builder.query<SUBSCRIPTION_PLANS[], void>({
            query: () => ({
                url: 'subscriptions/getSubscriptions',
                method: 'GET',
            }),
        }),

        createUserSubscription: builder.mutation<void, CreateUserSubscription>({
            query: (subscription) => ({
                url: `users/createUserSubscription?subscriptionPlanId=${subscription.subscriptionPlanId}`,
                method: 'POST',
                body: subscription,
            }),
        }),
    }),
});

export const {
    useGetSubscriptionPlansQuery,
    useCreateUserSubscriptionMutation,
} = subscriptionApi;
