import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@ui/app/store';

interface NewHue {
    projectName: string;
    projectId: number;
    paletteName: string;
    paletteId: number;
    hueName: string;
}

const initialState: NewHue = {
    projectName: '',
    projectId: -1,
    paletteName: '',
    paletteId: -1,
    hueName: '',
};

export const newHueSlice = createSlice({
    name: 'newHue',
    initialState,
    reducers: {
        resetNewHue: () => {
            return { ...initialState };
        },
        setProjectName: (state, action) => {
            state.projectName = action.payload;
            state.projectId = initialState.projectId;
            state.paletteId = initialState.paletteId;
        },
        setProjectId: (state, action) => {
            state.projectId = action.payload;
            state.projectName = initialState.projectName;
            state.paletteId = initialState.paletteId;
        },
        setPaletteName: (state, action) => {
            state.paletteName = action.payload;
            state.paletteId = initialState.paletteId;
        },
        setPaletteId: (state, action) => {
            state.paletteId = action.payload;
            state.paletteName = initialState.paletteName;
        },
        setHueName: (state, action) => {
            state.hueName = action.payload;
        },
    },
});

export const {
    resetNewHue,
    setProjectName,
    setProjectId,
    setPaletteName,
    setPaletteId,
    setHueName,
} = newHueSlice.actions;

export const selectNewHue = (state: RootState) => state.newHue;

export default newHueSlice.reducer;
