import { ensureHashPrefix, isHexColorValid } from '@ui/utils/helpers';
import * as Styles from './style';
import {
    ColorRepresentations,
    fromHex,
    fromHsva,
} from '../ColorPicker/ColorRepresentations';

interface CustomColorPickerProps {
    color: ColorRepresentations;
    handleColorChange: (color: ColorRepresentations) => void;
    showHue: boolean;
    showResetBtn: boolean;
    handleResetColor?: () => void;
}

export const CustomColorPicker = (props: CustomColorPickerProps) => {
    const {
        color,
        handleColorChange,
        showHue,
        showResetBtn,
        handleResetColor,
    } = props;

    return (
        <div>
            <Styles.CcpContainer>
                <div>
                    <Styles.CcpWrapper>
                        <Styles.CcpColorPreview $bgColor={color.hex} />
                        <div>
                            <Styles.CcpSaturation
                                hsva={color.hsva}
                                onChange={(color) => {
                                    handleColorChange(fromHsva(color));
                                }}
                            />
                        </div>
                    </Styles.CcpWrapper>

                    {showHue && (
                        <Styles.CcpHue
                            hue={color.hsva.h}
                            onChange={(newHue) => {
                                const modifiedColor = {
                                    ...color.hsva,
                                    h: newHue.h,
                                };

                                handleColorChange(fromHsva(modifiedColor));
                            }}
                        />
                    )}
                </div>
                <div>
                    <Styles.CcpHeader>HEX</Styles.CcpHeader>
                    <Styles.CcpInput>
                        <input
                            type="text"
                            value={color.hex}
                            onChange={(e) => {
                                const hexVal = e.target.value;

                                if (isHexColorValid(hexVal)) {
                                    handleColorChange(
                                        fromHex(ensureHashPrefix(hexVal))
                                    );
                                }
                            }}
                        />
                    </Styles.CcpInput>

                    {!isHexColorValid(color.hex) && (
                        <Styles.CcpError>
                            Invalid hex color code
                        </Styles.CcpError>
                    )}

                    <Styles.CcpRgbContainer>
                        <div>
                            <Styles.CcbRgbHeader>R</Styles.CcbRgbHeader>
                            <Styles.CcpRgbInput>
                                {color.rgba.r}
                            </Styles.CcpRgbInput>
                        </div>
                        <div>
                            <Styles.CcbRgbHeader>G</Styles.CcbRgbHeader>{' '}
                            <Styles.CcpRgbInput>
                                {color.rgba.g}
                            </Styles.CcpRgbInput>
                        </div>
                        <div>
                            <Styles.CcbRgbHeader>B</Styles.CcbRgbHeader>{' '}
                            <Styles.CcpRgbInput>
                                {color.rgba.b}
                            </Styles.CcpRgbInput>
                        </div>
                    </Styles.CcpRgbContainer>

                    <Styles.CcpHsvaContainer>
                        <div>
                            <Styles.CcbRgbHeader>H</Styles.CcbRgbHeader>
                            <Styles.CcpRgbInput>
                                {color.hsva.h.toFixed(1)}
                            </Styles.CcpRgbInput>
                        </div>
                        <div>
                            <Styles.CcbRgbHeader>S</Styles.CcbRgbHeader>{' '}
                            <Styles.CcpRgbInput>
                                {color.hsva.s.toFixed(1)}
                            </Styles.CcpRgbInput>
                        </div>
                        <div>
                            <Styles.CcbRgbHeader>V</Styles.CcbRgbHeader>{' '}
                            <Styles.CcpRgbInput>
                                {color.hsva.v.toFixed(1)}
                            </Styles.CcpRgbInput>
                        </div>
                        <div>
                            <Styles.CcbRgbHeader>A</Styles.CcbRgbHeader>{' '}
                            <Styles.CcpRgbInput>
                                {color.hsva.a.toFixed(1)}
                            </Styles.CcpRgbInput>
                        </div>
                    </Styles.CcpHsvaContainer>
                </div>
            </Styles.CcpContainer>

            {showResetBtn && (
                <Styles.ResetButton onClick={() => handleResetColor?.()}>
                    RESET
                </Styles.ResetButton>
            )}
        </div>
    );
};
