/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';

export const useClickOutside = (ref: any, func: () => void) => {
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            //@ts-ignore
            if (ref.current && !ref.current.contains(event.target)) {
                setTimeout(() => {
                    func();
                }, 0);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [func, ref]);
};
