import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import {
    useAddUserToProjectMutation,
    useDeleteUserFromProjectMutation,
    useGetProjectByIdQuery,
    useGetProjectPalletsQuery,
    useGetProjectUserQuery,
} from '@ui/services/project';
import UserPlaceholder from '@ui/images/user-placeholder.svg';
import { CustomModal } from '@ui/components/CustomModal';
import { NotFound } from '@ui/components/NotFound';
import { useGetAdditionalUsersQuery } from '@ui/services/team';
import { AddProjectMember } from '../../components/AddProjectMember';
import { RemoveProjectMember } from '../../components/RemoveProjectMember';
import { PaletteItem } from '../../components/PaletteItem';
import { DeletePalette } from '../../components/deletePalette';
import * as Styles from './style';
import { useDeletePaletteMutation } from '@ui/services/palette';
import { RenameProject } from '../../components/RenameProject';

type RouteParams = {
    projectId: string;
};

enum MODAL_STATUS {
    CLOSED = 0,
    ADD_MEMBER = 1,
    REMOVE_MEMBER = 2,
    DELETE_PALETTE = 3,
    RENAME_PROJECT = 4,
}

export const ProjectPage = () => {
    const [modalStatus, setModalStatus] = useState(MODAL_STATUS.CLOSED);

    const { projectId } = useParams<RouteParams>();

    const { data: project, refetch: refecthProjectDetails } =
        useGetProjectByIdQuery(parseInt(projectId as string));

    const { data: palettes, refetch: refetchPalettes } =
        useGetProjectPalletsQuery(parseInt(projectId as string));

    const { data: projectUsers, refetch } = useGetProjectUserQuery(
        projectId as string
    );

    const { data: teamMembers } = useGetAdditionalUsersQuery();

    const [addUserToProject, { isLoading: isAdding }] =
        useAddUserToProjectMutation();
    const [deleteProjectUser, { isLoading: isDeleting }] =
        useDeleteUserFromProjectMutation();

    const [deletePalette, { isLoading: isDeletingPalette }] =
        useDeletePaletteMutation();

    const [selectedEmail, setSelectedEmail] = useState('');
    const [selectedProfilePic, setSelectedProfilePic] = useState<string | null>(
        ''
    );

    const [selectedPaletteId, setSelectedPaletteId] = useState(0);

    const handleAddTeamMember = async (email: string) => {
        try {
            await addUserToProject({
                projectId: parseInt(projectId as string),
                newUserEmail: email,
            }).unwrap();

            toast.success('User added');
            setModalStatus(MODAL_STATUS.CLOSED);

            refetch();
        } catch (err) {
            console.log('Err ', err);
            toast.error('Something went wrong');
        }
    };

    const openRemoveModal = (email: string, profilePicSrc: string | null) => {
        setSelectedEmail(email);
        setModalStatus(MODAL_STATUS.REMOVE_MEMBER);
        setSelectedProfilePic(profilePicSrc);
    };

    const closeRemoveModal = () => {
        setSelectedEmail('');
        setSelectedProfilePic('');
        setModalStatus(MODAL_STATUS.CLOSED);
    };

    const handleDeletePalette = async () => {
        try {
            await deletePalette(selectedPaletteId).unwrap();

            toast.success('Palette Deleted');
            refetchPalettes();
            closeDeletePaletteModal();
        } catch (err) {
            console.log('err', err);
            toast.error('Something went wrong');
        }
    };

    const handleConfirmRemoveProjectMember = async () => {
        try {
            await deleteProjectUser({
                userEmail: selectedEmail,
                projectId: parseInt(projectId as string),
            }).unwrap();

            toast.success('Project member removed');
            refetch();
            closeRemoveModal();
        } catch (err) {
            console.log('err ', err);
            toast.error('Something went wrong');
        }
    };

    const openDeletePaletteModal = (palettedId: number) => {
        setSelectedPaletteId(palettedId);
        setModalStatus(MODAL_STATUS.DELETE_PALETTE);
    };

    const closeDeletePaletteModal = () => {
        setSelectedPaletteId(0);
        setModalStatus(MODAL_STATUS.CLOSED);
    };

    const handleConfirmRenameProject = () => {
        setModalStatus(MODAL_STATUS.CLOSED);
        refecthProjectDetails();
    };

    const renderProjectUsers = () => {
        if (Array.isArray(projectUsers)) {
            if (projectUsers.length) {
                return projectUsers.map((v) => (
                    <Styles.TeamListItem key={v.userId}>
                        <Styles.TpiName>
                            <Styles.TpiImage
                                src={v?.userProfileImagePath || UserPlaceholder}
                                alt="profile"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = UserPlaceholder;
                                }}
                            />
                            <div>
                                {v?.firstName} {v?.lastName}
                            </div>
                        </Styles.TpiName>
                        <div>{v?.email}</div>

                        <Styles.TeamListRightAligned
                            onClick={() =>
                                openRemoveModal(
                                    v?.email,
                                    v?.userProfileImagePath
                                )
                            }
                        >
                            <FontAwesomeIcon icon={faTrash} className="icon" />
                        </Styles.TeamListRightAligned>
                    </Styles.TeamListItem>
                ));
            }

            return (
                <NotFound
                    header="No project members found"
                    subHeader="We were unable to find any member associated with this project"
                />
            );
        }

        return (
            <NotFound
                header="No project members found"
                subHeader="We were unable to find any member associated with this project"
            />
        );
    };

    const renderPalettes = () => {
        if (Array.isArray(palettes)) {
            if (palettes?.length) {
                return (
                    <Styles.ProPaletteList>
                        {palettes?.map((v) => (
                            <PaletteItem
                                key={v?.id}
                                paletteName={v?.name}
                                palettedId={v.id}
                                projectId={projectId as string}
                                openDeletePaletteModal={openDeletePaletteModal}
                            />
                        ))}
                    </Styles.ProPaletteList>
                );
            }

            return (
                <NotFound
                    header="We could not find any palette"
                    subHeader="We were unable to find any palette associated with this project"
                />
            );
        }

        return (
            <NotFound
                header="We could not find any palette"
                subHeader="We were unable to find any palette associated with this project"
            />
        );
    };

    const renderApiKey = () => {
        if (!project?.apiKey) {
            return (
                <div>
                    <NotFound
                        header="No Api Key exists"
                        subHeader="Create an api key to get the generated css of this project"
                    />
                </div>
            );
        }

        return <Styles.ProjectApiKey>{project?.apiKey}</Styles.ProjectApiKey>;
    };

    return (
        <Styles.ProPage>
            <Styles.ProjectNameContainer>
                <Styles.ProjectName>
                    Project Name: <span>{project?.name}</span>
                </Styles.ProjectName>
                {/* <Styles.ProAddMemBtn
                    onClick={() => setModalStatus(MODAL_STATUS.RENAME_PROJECT)}
                >
                    Rename project
                </Styles.ProAddMemBtn> */}
            </Styles.ProjectNameContainer>

            <Styles.ProHeader>
                Palettes associated with this project
            </Styles.ProHeader>

            <Styles.ProPaletteContainer>
                {renderPalettes()}
            </Styles.ProPaletteContainer>

            {project?.isAdmin && (
                <Styles.ProHeader>
                    <span>Project Members:</span>
                    <Styles.ProAddMemBtn
                        onClick={() => setModalStatus(MODAL_STATUS.ADD_MEMBER)}
                    >
                        Add Member
                    </Styles.ProAddMemBtn>
                </Styles.ProHeader>
            )}

            <Styles.TeamListContainer>
                <Styles.TeamList>{renderProjectUsers()}</Styles.TeamList>
            </Styles.TeamListContainer>

            <Styles.ProHeader>API key</Styles.ProHeader>

            <Styles.ApiKeyContainer>{renderApiKey()}</Styles.ApiKeyContainer>

            <Styles.ApiKeyContainer>
                <Styles.ProAddMemBtn>View</Styles.ProAddMemBtn>
            </Styles.ApiKeyContainer>

            {modalStatus === MODAL_STATUS.ADD_MEMBER && (
                <CustomModal show={modalStatus === MODAL_STATUS.ADD_MEMBER}>
                    <AddProjectMember
                        handleCancel={() => setModalStatus(MODAL_STATUS.CLOSED)}
                        handleAdd={handleAddTeamMember}
                        teamMembers={teamMembers}
                        isAdding={isAdding}
                        projectUsers={projectUsers}
                    />
                </CustomModal>
            )}

            {modalStatus === MODAL_STATUS.REMOVE_MEMBER && (
                <CustomModal show={modalStatus === MODAL_STATUS.REMOVE_MEMBER}>
                    <RemoveProjectMember
                        profileImgSrc={selectedProfilePic}
                        handleCancel={() => closeRemoveModal()}
                        handleConfirm={() => handleConfirmRemoveProjectMember()}
                        selectedEmail={selectedEmail}
                        isDeleting={isDeleting}
                    />
                </CustomModal>
            )}

            {modalStatus === MODAL_STATUS.DELETE_PALETTE && (
                <CustomModal show={modalStatus === MODAL_STATUS.DELETE_PALETTE}>
                    <DeletePalette
                        handleCancel={() => closeDeletePaletteModal()}
                        handleConfirm={() => handleDeletePalette()}
                        isDeleting={isDeletingPalette}
                    />
                </CustomModal>
            )}

            {modalStatus === MODAL_STATUS.RENAME_PROJECT && (
                <CustomModal show={modalStatus === MODAL_STATUS.RENAME_PROJECT}>
                    <RenameProject
                        handleCancel={() => setModalStatus(MODAL_STATUS.CLOSED)}
                        handleConfirm={() => handleConfirmRenameProject()}
                        projectId={projectId!}
                        originalName={project?.name}
                    />
                </CustomModal>
            )}
        </Styles.ProPage>
    );
};
