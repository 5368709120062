import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { TextInput } from '@ui/components/TextInput';
import { useUpdatePasswordMutation } from '@ui/services/auth';
import { BtnLoader } from '@ui/components/Loaders';
import { UpdatePasswordData } from '@ui/types/LoginData';
import { useAppSelector } from '@ui/app/hooks';
import { selectUserEmail } from '@ui/slices/authSlice';
import { FormErrMsg } from '@ui/utils/globalStyles';
import * as Styles from './style';

interface UpdatePasswordInitialValues {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

const initialValues: UpdatePasswordInitialValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
};

const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old password is required'),
    newPassword: Yup.string().required('New password is required'),
    confirmNewPassword: Yup.string()
        .required('Confirm new password is required')
        .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});

export const UpdatePassword = () => {
    const [attemptUpdatePassword, { isLoading }] = useUpdatePasswordMutation();

    const email = useAppSelector(selectUserEmail);

    const handleSubmit = async (values: UpdatePasswordInitialValues) => {
        try {
            const updatePassword: UpdatePasswordData = {
                email: email,
                oldPassword: values.oldPassword,
                newPassword: values.newPassword,
            };

            await attemptUpdatePassword(updatePassword).unwrap();

            toast.success('Password updated!');
        } catch (err) {
            console.log('err ', err);
            toast.error('Something went wrong');
        }
    };

    return (
        <Styles.UpContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <Styles.RpFormControl>
                            <Styles.RpLabel htmlFor="oldPassword">
                                OLD PASSWORD
                            </Styles.RpLabel>

                            <Styles.RpFieldControl>
                                <TextInput
                                    type="password"
                                    name="oldPassword"
                                    id="oldPassword"
                                    showIcon={false}
                                />
                            </Styles.RpFieldControl>

                            <FormErrMsg>
                                <ErrorMessage
                                    name="oldPassword"
                                    component="div"
                                />
                            </FormErrMsg>
                        </Styles.RpFormControl>

                        <Styles.RpFormControl2>
                            <Styles.RpLabel htmlFor="newPassword">
                                NEW PASSWORD
                            </Styles.RpLabel>

                            <Styles.RpFieldControl>
                                <TextInput
                                    type="password"
                                    name="newPassword"
                                    id="newPassword"
                                    showIcon={false}
                                />
                            </Styles.RpFieldControl>

                            <FormErrMsg>
                                <ErrorMessage
                                    name="newPassword"
                                    component="div"
                                />
                            </FormErrMsg>
                        </Styles.RpFormControl2>

                        <Styles.RpFormControl2>
                            <Styles.RpLabel htmlFor="confirmNewPassword">
                                CONFIRM NEW PASSWORD
                            </Styles.RpLabel>

                            <Styles.RpFieldControl>
                                <TextInput
                                    type="password"
                                    name="confirmNewPassword"
                                    id="confirmNewPassword"
                                    showIcon={false}
                                />
                            </Styles.RpFieldControl>

                            <FormErrMsg>
                                <ErrorMessage
                                    name="confirmNewPassword"
                                    component="div"
                                />
                            </FormErrMsg>
                        </Styles.RpFormControl2>

                        <Styles.RpRegisterBtn
                            type="submit"
                            isDisabled={isLoading}
                        >
                            {isLoading ? <BtnLoader /> : ' Update Password'}
                        </Styles.RpRegisterBtn>
                    </Form>
                )}
            </Formik>
        </Styles.UpContainer>
    );
};
