import { Button } from '@ui/components/Button';
import styled from 'styled-components';

export const DpContainer = styled.div`
    width: 600px;
    background-color: white;
    padding: 30px;

    @media (max-width: 700px) {
        width: 100%;
        padding: 30px 20px;
    }
`;

export const DpHeader = styled.div`
    font-size: 24px;
    font-family: 'Poppins-Regular';
    color: #2a2a30;
`;

export const DpLine = styled.div`
    margin: 10px 0;
    width: 100%;
    height: 1px;
    background-color: #707070;
`;

export const DpConfirm = styled.div`
    font-size: 21px;
    font-family: 'Poppins-ExtraLight';
    color: #2a2a30;
`;

export const DpBtns = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 60px 0 0 0;
`;

export const DpBtn = styled(Button)`
    width: 220px;
    height: 56px;

    @media (max-width: 700px) {
        width: 100%;
    }
`;

export const DpDeleteBtn = styled(Button)`
    width: 220px;
    height: 56px;
    background-color: red;

    @media (max-width: 700px) {
        width: 100%;
    }
`;

export const CpErrors = styled.div`
    color: #df27e8;
    margin-top: 8px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
`;
