import { useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { Navbar } from '@ui/components/Navbar';
import { useGetColorsFromHueQuery } from '@ui/services/color';
import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import HueMenu from '@ui/components/HueMenu/HueMenu';
import {
    setColorStudio,
    resetColorStudio,
    setCssProperty,
} from '@ui/slices/colorStudioSlice';
import * as Styles from './style';
import { fromHex } from '../ColorPicker/ColorRepresentations';
import { isHexColorValid } from '@ui/utils/helpers';
import { COLOR_PLACEMENT, DEFAULT_COLOR } from '@ui/types/types';
import { Color } from '@ui/types/Color';
import { useGetHueByIdQuery } from '@ui/services/hue';

export const ColorLayout = () => {
    const { projectId, paletteId, hueId } = useParams();

    const navigate = useNavigate();

    const { data: colors, isLoading } = useGetColorsFromHueQuery(
        parseInt(hueId as string)
    );

    const { data: hue, isLoading: isHueLoading } = useGetHueByIdQuery(
        parseInt(hueId as string)
    );

    const { id } = useAppSelector((state) => state.colorStudio);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (Array.isArray(colors) && !isLoading) {
            if (colors.length > 0) {
                if (id === -1) {
                    const firstElement = colors[0];

                    handleColor(firstElement);
                } else {
                    const color = colors.find((color) => color.id == id);

                    if (color) {
                        handleColor(color);
                    }
                }
            }
        }
    }, [colors, colors?.length, isLoading]);

    useEffect(() => {
        if (Array.isArray(hue?.cssProperties) && !isHueLoading) {
            dispatch(setCssProperty(hue?.cssProperties));
        }
    }, [hue?.cssProperties, hue?.cssProperties?.length, isHueLoading]);

    useEffect(() => {
        return () => {
            dispatch(resetColorStudio());
            dispatch(setCssProperty([]));
        };
    }, []);

    const handleColor = (color: Color) => {
        dispatch(
            setColorStudio({
                ...color,
                trueColor: fromHex(color.hex),
                colorDescriptors: color?.colorDescriptors
                    ? color.colorDescriptors.split(',')
                    : [],

                colorTags: color?.colorTags ? color.colorTags.split(',') : [],

                aaColor: isHexColorValid(color?.aaHex)
                    ? fromHex(color.aaHex)
                    : fromHex('#000000'),
                aaaColor: isHexColorValid(color?.aaaHex)
                    ? fromHex(color.aaaHex)
                    : fromHex('#000000'),

                aaHexOL: color?.colorSetting?.aaHexOL || false,
                aaaHexOL: color?.colorSetting?.aaaHexOL || false,
                colorPlacement:
                    color?.colorSetting?.colorPlacement ||
                    COLOR_PLACEMENT.FOREGROUND,
                contrastColor: isHexColorValid(color?.colorSetting?.contrastHex)
                    ? fromHex(color?.colorSetting?.contrastHex)
                    : fromHex('#F1F67B'),
                defaultColor:
                    color?.colorSetting?.defaultColor ||
                    DEFAULT_COLOR.TRUE_COLOR,
                isGradient: Boolean(color?.isGradient),
                gradientHex: isHexColorValid(color?.gradientHex)
                    ? fromHex(color?.gradientHex)
                    : fromHex('#000000'),
            })
        );
    };

    const menuItemChange = (value: string) => {
        const colorId = parseInt(value);
        if (value === 'add') {
            dispatch(resetColorStudio());
            dispatch(setCssProperty(hue?.cssProperties));
            navigate(`/color/${projectId}/${paletteId}/${hueId}`);
        } else if (value == 'rules') {
            navigate(`/color/${projectId}/${paletteId}/${hueId}/rules`);
        } else if (colorId > 0) {
            navigate(`/color/${projectId}/${paletteId}/${hueId}`);
            const color = colors!.find((color) => color.id == colorId);

            if (color) {
                handleColor(color);
            }
        }
    };

    return (
        <div>
            <Styles.ClContainer>
                <div>
                    <HueMenu colors={colors!} onMenuChange={menuItemChange} />
                </div>
                <div>
                    <Navbar />
                    <Styles.ClOutlet>
                        <Outlet />
                    </Styles.ClOutlet>
                </div>
            </Styles.ClContainer>
        </div>
    );
};
