import styled from 'styled-components';
import { Button } from '@ui/components/Button';

export const CsPage = styled.div``;

export const CsContainer = styled.div`
    padding: 40px;
    border: 1px solid #e7e7e7;
    border-radius: 24px;
    background-color: #ffffff;

    @media (max-width: 700px) {
        padding: 20px 10px;
        border-radius: 8px;
    }
`;

export const CsHeader = styled.div`
    margin-top: 9px;
    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-Bold';
`;

export const CsLine = styled.div`
    margin: 9px 0;
    width: 100%;
    height: 1px;
    background: #707070;
`;

export const CsSubHeader = styled.div`
    font-size: 15px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';
`;

export const CsopContainer = styled.div`
    margin-top: 30px;
`;

export const CsopHeader = styled.div`
    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-Medium';
`;

export const CsDesc = styled.div`
    margin-top: 5px;
    font-size: 15px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';
`;

export const CsCheckBox = styled.div`
    margin-top: 23px;
    display: flex;
    align-items: center;
    gap: 100px;
`;

export const CsDropdownContainer = styled.div`
    margin-top: 20px;
`;

export const CsDropdownItem = styled.div`
    width: 270px;

    @media (max-width: 700px) {
        width: 100%;
    }
`;

export const CsRadioBtn = styled.div`
    margin-top: 25px;
    display: flex;
    align-items: center;
    gap: 78px;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
`;

export const CsCcpContainer = styled.div`
    margin-top: 26px;
`;

export const TcSaveBtnContainer = styled.div`
    margin: 82px 0 0 0;
    display: flex;
    justify-content: center;
`;

export const TcSaveBtn = styled(Button)`
    width: 150px;
    height: 56px;
    border-radius: 4px;
`;
