import { useState } from 'react';
import { Field } from 'formik';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputUserIcon from '@ui/images/input-user-icon.svg';
import InputPasswordIcon from '@ui/images/input-password-icon.svg';
import * as Styles from './style';

interface ITextInputProps {
    type?: string;
    name: string;
    id: string;
    placeholder?: string;
    showIcon: boolean;
}

const PasswordInput = (props: ITextInputProps) => {
    const { type, name, id, placeholder, showIcon } = props;

    const [fieldType, setFieldType] = useState(type ?? 'password');

    const toggleField = () => {
        fieldType === 'text' ? setFieldType('password') : setFieldType('text');
    };

    const getIcon = () => {
        return fieldType === 'text' ? faEye : faEyeSlash;
    };

    return (
        <Styles.TiPasswordContainer
            className="text-input-container"
            $showIcon={showIcon}
        >
            {showIcon && (
                <Styles.TiIconContainer>
                    <img src={InputPasswordIcon} alt="password" />
                </Styles.TiIconContainer>
            )}

            <Field
                type={fieldType}
                name={name}
                id={id}
                placeholder={placeholder ?? ''}
            />
            <Styles.TiPasswordIcon onClick={toggleField}>
                <FontAwesomeIcon icon={getIcon()} className="icon" />
            </Styles.TiPasswordIcon>
        </Styles.TiPasswordContainer>
    );
};

export const TextInput = (props: ITextInputProps) => {
    const { type, name, id, placeholder, showIcon } = props;

    const renderContent = () => {
        switch (type) {
            case 'password':
                return <PasswordInput {...props} />;

            case 'text':
            default:
                return (
                    <Styles.TiContainer
                        className="text-input-container"
                        $showIcon={showIcon}
                    >
                        {showIcon && (
                            <Styles.TiIconContainer>
                                <img src={InputUserIcon} alt="user" />
                            </Styles.TiIconContainer>
                        )}

                        <Field
                            type={type ?? 'text'}
                            name={name}
                            id={id}
                            placeholder={placeholder ?? ''}
                        />
                    </Styles.TiContainer>
                );
        }
    };

    return renderContent();
};
