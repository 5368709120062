import { skeletonAnimation } from '@ui/utils/animations';
import styled from 'styled-components';

export const HlContainer = styled.div`
    cursor: pointer;
    padding: 14px 12px 18px 12px;
    border-radius: 10px;
    opacity: 0.9;
    background: rgba(255, 255, 255, 0.9);
    transition: 300ms all;
    text-decoration: none !important;

    &:hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
`;

export const HlHeaderContainer = styled.div`
    margin-top: 16px;
    display: grid;
    grid-template-columns: 16px minmax(0, 1fr);
    align-items: center;
    grid-gap: 6px;
`;

export const HlHeaderContainer2 = styled(HlHeaderContainer)`
    margin-top: 14px;
`;

export const HlHeaderIcon = styled.div`
    width: 16px;
    height: 16px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const HlHeaderText = styled.div`
    color: #2a2a30;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    opacity: 0.7;
`;

export const HlLine = styled.div`
    width: 100%;
    height: 1px;
    margin: 18px 0;
    background-color: #e9edf1;
`;

type HcColorProps = {
    $index: number;
};

export const HlColor = styled.div<HcColorProps>`
    height: 53px;
    width: 53px;
    border-radius: 50%;
    border: 2px solid #2a2a30;
    background: linear-gradient(270deg, #dcdcdc, #e8e8e8, #dcdcdc);
    background-size: 200% 200%;
    animation: ${skeletonAnimation} 1s infinite;

    position: absolute;
    left: ${({ $index }) => `${$index * 38}px`};
    transition: 300ms all;

    &:hover {
        transform: scale(1.2);
        z-index: 1000;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 16px;
        color: rgb(0, 0, 0);
        font-family: 'Poppins-Bold';
    }
`;

export const HlColorContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: 53px;
`;

export const HlProjectName = styled.div`
    width: 70%;
    height: 30.4px;
    border-radius: 8px;
    background: linear-gradient(270deg, #dcdcdc, #e8e8e8, #dcdcdc);
    background-size: 200% 200%;
    animation: ${skeletonAnimation} 1s infinite;
`;

export const HlPalletName = styled(HlProjectName)`
    margin-left: 22px;
    height: 24px;
`;
