import { Outlet, Navigate } from 'react-router-dom';
import { useAppSelector } from '@ui/app/hooks';
import { selectToken } from '@ui/slices/authSlice';
import { ROUTES } from '@ui/types/types';

export const AuthLayout = () => {
    const token = useAppSelector(selectToken);

    if (!token) {
        return <Navigate to={ROUTES.ROOT} />;
    }

    return <Outlet />;
};
