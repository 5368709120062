import { Link } from 'react-router-dom';
import PaletteIcon from '@ui/images/hue-pallet-icon.svg';
import HueIcon from '@ui/images/hue-hue-icon.svg';
import * as Styles from './style';
import { UserCreatedHue } from '@ui/types/types';

interface HueCardProps {
    hue: UserCreatedHue;
}

const HueCard = (props: HueCardProps) => {
    const { hue } = props;

    const renderColor = () => {
        if (Array.isArray(hue?.hueColors)) {
            if (hue.hueColors.length > 4) {
                const colors = hue.hueColors
                    .slice(0, 4)
                    .map((item, index) => (
                        <Styles.HcColor
                            key={item.id}
                            $backgroundColor={item?.hex}
                            $index={index}
                        />
                    ));

                return (
                    <>
                        {colors}
                        <Styles.HcColor
                            key={4}
                            $backgroundColor={'#ffffff'}
                            $index={4}
                        >
                            <span>+{hue.hueColors.length - 4}</span>
                        </Styles.HcColor>
                    </>
                );
            }

            return hue.hueColors.map((item, index) => (
                <Styles.HcColor
                    key={item.id}
                    $backgroundColor={item?.hex}
                    $index={index}
                />
            ));
        }

        return null;
    };

    return (
        <Styles.HcWrapper>
            <Link
                to={`/color/${hue.projectDetails.id}/${hue.paletteDetails.id}/${hue.id}`}
            >
                <Styles.HcContainer>
                    <Styles.HcProjectName>
                        {hue?.projectDetails?.name}
                    </Styles.HcProjectName>
                    <Styles.HcHeaderContainer>
                        <Styles.HcHeaderIcon>
                            <img src={PaletteIcon} alt="palette" />
                        </Styles.HcHeaderIcon>
                        <Styles.HcHeaderText>Palette Name</Styles.HcHeaderText>
                    </Styles.HcHeaderContainer>
                    <Styles.HcNameText>
                        {hue?.paletteDetails?.name}
                    </Styles.HcNameText>
                    <Styles.HcHeaderContainer2>
                        <Styles.HcHeaderIcon>
                            <img src={HueIcon} alt="hue" />
                        </Styles.HcHeaderIcon>
                        <Styles.HcHeaderText>Hue</Styles.HcHeaderText>
                    </Styles.HcHeaderContainer2>
                    <Styles.HcNameText>{hue?.name}</Styles.HcNameText>
                    <Styles.HcLine />
                    <Styles.HcColorContainer>
                        {renderColor()}
                    </Styles.HcColorContainer>
                </Styles.HcContainer>
            </Link>
        </Styles.HcWrapper>
    );
};

export { HueCard };
