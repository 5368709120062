import styled from 'styled-components';
import { skeletonAnimation } from '@ui/utils/animations';

export const PilContainer = styled.div`
    cursor: pointer;
    min-height: 100px;
    padding: 14px 12px 18px 12px;
    border-radius: 10px;

    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: 300ms all;

    &:hover {
        box-shadow: rgba(149, 157, 165, 0.35) 0px 10px 20px;
    }
`;

export const PilName = styled.div`
    width: 70%;
    height: 21.2px;
    border-radius: 8px;
    background: linear-gradient(270deg, #dcdcdc, #e8e8e8, #dcdcdc);
    background-size: 200% 200%;
    animation: ${skeletonAnimation} 1s infinite;
`;

export const PilApiKey = styled(PilName)`
    width: 100%;
    margin-top: 10px;
`;
