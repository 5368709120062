import { useState } from 'react';
import { PROFILE_TABS } from '@ui/types/Profile';
import * as Styles from './style';
import { ProfileTabs } from '../../components/ProfileTabs';
import { UpdatePassword } from '../../components/UpdatePassword';
import { EditProfile } from '../../components/EditProfile';
import { About } from '../../components/About';
import { Subscription } from '../../components/Subscription';

export const ProfilePage = () => {
    const [activeTab, setActiveTab] = useState(PROFILE_TABS.ABOUT);

    const handleTabSwitch = (val: PROFILE_TABS) => {
        setActiveTab(val);
    };

    const renderTabs = () => {
        switch (activeTab) {
            case PROFILE_TABS.SUBSCRIPTION:
                return <Subscription />;

            case PROFILE_TABS.EDIT:
                return <EditProfile />;

            case PROFILE_TABS.UPDATE_PASSWORD:
                return <UpdatePassword />;

            case PROFILE_TABS.ABOUT:
            default:
                return <About />;
        }
    };

    return (
        <Styles.PpWrapper>
            <Styles.PpContainer>
                <ProfileTabs
                    activeTab={activeTab}
                    handleTabSwitch={handleTabSwitch}
                />

                {renderTabs()}
            </Styles.PpContainer>
        </Styles.PpWrapper>
    );
};
