import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { TextInput } from '@ui/components/TextInput';
import { BtnLoader } from '@ui/components/Loaders';
import { useAppDispatch } from '@ui/app/hooks';
import { useAuthloginMutation } from '@ui/services/auth';
import { authLogin, authLogout } from '@ui/slices/authSlice';
import LoginData from '@ui/types/LoginData';
import OnHueLogoRound from '@ui/images/onHueLogoRound.svg';
import RightArrow from '@ui/images/right-arrow-icon.svg';
import * as Styles from './style';

const initialValues: LoginData = {
    username: '',
    password: '',
};

const validationSchema = Yup.object().shape({
    username: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
});

const LoginForm = () => {
    const dispatch = useAppDispatch();
    const [attemptLogin, { isLoading }] = useAuthloginMutation();

    const navigate = useNavigate();

    const handleLogin = async ({ username, password }: LoginData) => {
        const newLogin: LoginData = {
            username: username,
            password: password,
        };
        try {
            const data = await attemptLogin(newLogin).unwrap();

            console.log('data ', data);

            dispatch(authLogin({ ...data, email: username }));
            toast.success('Login success');

            navigate('/dashboard');
        } catch (e) {
            toast.error('Unable to login');
            dispatch(authLogout());
            console.log('err ', e);
        }
    };

    return (
        <Styles.LfContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
            >
                {() => (
                    <Form>
                        <div>
                            <Styles.LfLogo src={OnHueLogoRound} alt="onhue" />
                            <Styles.LfHeader>
                                <span>We are</span>
                                <Styles.LfHeaderText>onHue</Styles.LfHeaderText>
                            </Styles.LfHeader>

                            <Styles.LfSubHeader>
                                Welcome Back, Please login to your <br />{' '}
                                account.
                            </Styles.LfSubHeader>
                        </div>
                        <Styles.LoginFormInnerContainer>
                            <Styles.LfControl>
                                <Styles.LoginLabel htmlFor="username">
                                    USERNAME
                                </Styles.LoginLabel>

                                <Styles.LfFieldControl>
                                    <TextInput
                                        type="text"
                                        name="username"
                                        id="username"
                                        placeholder="Email"
                                        showIcon={true}
                                    />
                                </Styles.LfFieldControl>

                                <Styles.LFErrMsg>
                                    <ErrorMessage
                                        name="username"
                                        component="div"
                                    />
                                </Styles.LFErrMsg>
                            </Styles.LfControl>

                            <Styles.LfControl2>
                                <Styles.LoginLabel htmlFor="username">
                                    PASSWORD
                                </Styles.LoginLabel>

                                <Styles.LfFieldControl>
                                    <TextInput
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="Password"
                                        showIcon={true}
                                    />
                                </Styles.LfFieldControl>

                                <Styles.LFErrMsg>
                                    <ErrorMessage
                                        name="password"
                                        component="div"
                                    />
                                </Styles.LFErrMsg>
                            </Styles.LfControl2>

                            <Styles.LfForgotPassword to="/forgot-password">
                                Forgot password?
                            </Styles.LfForgotPassword>

                            <Styles.LfBtn type="submit" isDisabled={isLoading}>
                                {isLoading ? (
                                    <BtnLoader />
                                ) : (
                                    <>
                                        <div>Login to onHue</div>
                                        <img src={RightArrow} alt="arrow" />
                                    </>
                                )}
                            </Styles.LfBtn>

                            <Styles.LfSignupText>
                                Don&apos;t have an account?{' '}
                                <Link to={'/sign-up'}>Signup Now</Link>
                            </Styles.LfSignupText>
                        </Styles.LoginFormInnerContainer>
                    </Form>
                )}
            </Formik>
        </Styles.LfContainer>
    );
};

export { LoginForm };
