import { Palette, CreatePalette } from '@ui/types/Palette';
import { AddPaletteResponse } from '@ui/types/Palette/Palette';
import { PaletteHues } from '@ui/types/types';
import { api } from './api';

export const paletteApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPalettes: builder.query<Palette[], void>({
            query: () => ({ url: `palettes/getPalletsByUser` }),
            providesTags: (result = []) => [
                ...result.map(({ id }) => ({ type: 'Palettes', id } as const)),
                { type: 'Palettes', id: 'LIST' },
            ],
        }),
        getPaletteById: builder.query<Palette, number>({
            query: (id) => ({
                url: `palettes/getPaletteById?paletteId=${id.toString()}`,
            }),
            providesTags: (_palette, _error, id) => [{ type: 'Palettes', id }],
        }),
        getPalettesFromProject: builder.query<Palette[], number>({
            query: (id) => ({ url: `projects/${id}/palettes` }),
            providesTags: (result = []) => [
                ...result.map(({ id }) => ({ type: 'Palettes', id } as const)),
                { type: 'Palettes', id: 'LIST' },
            ],
        }),
        addPalette: builder.mutation<AddPaletteResponse, CreatePalette>({
            query: (palette) => ({
                url: `palettes/createPalette`,
                method: 'POST',
                body: palette,
            }),
            invalidatesTags: [{ type: 'Palettes', id: 'LIST' }],
        }),
        updatePalette: builder.mutation<void, Palette>({
            query: (palette) => ({
                url: `palette/${palette['id']}`,
                method: 'PUT',
                body: palette,
            }),
            invalidatesTags: (_, _error, palette) => [
                { type: 'Palettes', id: palette.id },
            ],
        }),
        deletePalette: builder.mutation<void, number>({
            query: (id) => ({ url: `palettes/${id}`, method: 'DELETE' }),
            invalidatesTags: (_, _error, id) => [
                { type: 'Palettes', id },
                { type: 'Palettes', id: 'LIST' },
                { type: 'Hues', id: 'LIST' },
            ],
        }),
        getPaletteHues: builder.query<PaletteHues[], number>({
            query: (id) => ({ url: `palettes/${id}/hues` }),
        }),
    }),
});

export const {
    useGetPalettesQuery,
    useGetPaletteByIdQuery,
    useGetPalettesFromProjectQuery,
    useAddPaletteMutation,
    useDeletePaletteMutation,
    useUpdatePaletteMutation,
    useGetPaletteHuesQuery,
} = paletteApi;
