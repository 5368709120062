import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import { store } from '@ui/app/store';

import { useAddHueMutation } from '@ui/services/hue';
import {
    useAddPaletteMutation,
    useGetPalettesFromProjectQuery,
} from '@ui/services/palette';
import {
    projectApi,
    useAddProjectMutation,
    useGetProjectsQuery,
} from '@ui/services/project';
import { setHue } from '@ui/slices/hueSlice';
import {
    resetNewHue,
    selectNewHue,
    setHueName,
    setPaletteId,
    setPaletteName,
    setProjectId,
    setProjectName,
} from '@ui/slices/newHueSlice';
import type { CreateHue } from '@ui/types/Hue';
import { Palette } from '@ui/types/Palette';
import { Project } from '@ui/types/Project';
import RightArrow from '@ui/images/right-arrow-icon.svg';
import CreateProjectIcon from '@ui/images/create-project-icon.svg';
import PaletteIcon from '@ui/images/hue-pallet-icon.svg';
import HueIcon from '@ui/images/hue-hue-icon.svg';
import { CustomDropdown } from '@ui/components/CustomDropdown';

import * as Styles from './style';
import toast from 'react-hot-toast';

export async function loader() {
    const projectPromise = store.dispatch(
        projectApi.endpoints.getProjects.initiate()
    );

    try {
        await projectPromise.unwrap();
    } catch (e) {
        console.log(e);
    } finally {
        projectPromise.unsubscribe();
    }

    return {};
}

export const CreateHuePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const newHueForm = useAppSelector(selectNewHue);
    const [apiAddProject] = useAddProjectMutation();
    const [apitAddPalette] = useAddPaletteMutation();
    const [apiAddHue] = useAddHueMutation();
    const [errors, setErrors] = useState([] as string[]);
    const { data: projects } = useGetProjectsQuery();
    const { data: palettes } = useGetPalettesFromProjectQuery(
        newHueForm.projectId > 0 ? newHueForm.projectId : skipToken
    );

    const compareByName = (a: { name: string }, b: { name: string }) => {
        return a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());
    };

    let projectList: Project[] = [];
    if (projects) {
        projectList = [...projects].sort(compareByName);
    }

    let paletteList: Palette[] = [];
    if (newHueForm.projectId > 0 && palettes) {
        paletteList = [...palettes].sort(compareByName) ?? [];
    }

    const createHue = async () => {
        try {
            const newErrors = [] as string[];
            if (newHueForm.projectId == -1 && newHueForm.projectName == '') {
                newErrors.push('You must select or enter a project');
            }
            if (newHueForm.paletteId == -1 && newHueForm.paletteName == '') {
                newErrors.push('You must select or enter a palette');
            }
            if (newHueForm.hueName == '') {
                newErrors.push('You must enter a hue name');
            }

            if (newErrors.length > 0) {
                setErrors(newErrors);
                return;
            }

            let projectId = newHueForm.projectId;
            if (newHueForm.projectName != '') {
                const newProject = await apiAddProject({
                    name: newHueForm.projectName,
                }).unwrap();
                projectId = newProject.id;
            }

            let paletteId = newHueForm.paletteId;
            if (newHueForm.paletteName != '') {
                const newPalette = await apitAddPalette({
                    name: newHueForm.paletteName,
                    projectId,
                }).unwrap();
                paletteId = newPalette.paletteId;
            }

            const newHue: CreateHue = {
                name: newHueForm.hueName,
                paletteId,
                cssProperties: [],
            };

            const addedHue = await apiAddHue(newHue).unwrap();

            dispatch(setHue(addedHue));
            navigate(`/color/${projectId}/${paletteId}/${addedHue.id}`);
        } catch (err) {
            toast.error('Something went wrong');
        }
    };

    const goBack = () => {
        navigate('/dashboard');
    };

    useEffect(() => {
        dispatch(resetNewHue());
    }, []);

    return (
        <Styles.ChpContainer>
            <Styles.ChpHeader>
                Craft Your New Hue: Embark on Your <br /> Creative Journey
            </Styles.ChpHeader>
            <Styles.ChpSubHeader>
                Select the project and palette from the dropdowns or enter names
                to create new ones
            </Styles.ChpSubHeader>

            <Styles.ChpLine />

            <div>
                <Styles.ChpLabelContainer>
                    <Styles.ChpLabelBox>
                        <img src={CreateProjectIcon} alt="project" />
                    </Styles.ChpLabelBox>
                    <Styles.ChpLabelText htmlFor="new-hue__form-clientName">
                        Your Project Name
                    </Styles.ChpLabelText>
                </Styles.ChpLabelContainer>

                <Styles.ChpFormInputContainer>
                    <div>
                        <Styles.ChpTextInput>
                            <input
                                id="new-hue__form-clientName"
                                name="projectName"
                                value={newHueForm.projectName}
                                onChange={(e) =>
                                    dispatch(setProjectName(e.target.value))
                                }
                                placeholder="Project Name"
                            />
                        </Styles.ChpTextInput>
                    </div>
                    <Styles.ChpOrText>or</Styles.ChpOrText>
                    <div>
                        <CustomDropdown
                            placeholder="Select Existing Project"
                            options={projectList?.map((v) => ({
                                name: v.name,
                                value: v?.id,
                            }))}
                            onChange={(value) => {
                                dispatch(setProjectId(value));
                            }}
                        />
                    </div>
                </Styles.ChpFormInputContainer>
            </div>

            <Styles.ChpFormControl>
                <Styles.ChpLabelContainer>
                    <Styles.ChpLabelBox>
                        <img src={PaletteIcon} alt="palette" />
                    </Styles.ChpLabelBox>
                    <Styles.ChpLabelText htmlFor="new-hue__form-hueFamily">
                        Palette Name
                    </Styles.ChpLabelText>
                </Styles.ChpLabelContainer>

                <Styles.ChpFormInputContainer>
                    <div>
                        <Styles.ChpTextInput>
                            <input
                                id="new-hue__form-hueFamily"
                                name="paletteName"
                                value={newHueForm.paletteName}
                                onChange={(e) =>
                                    dispatch(setPaletteName(e.target.value))
                                }
                                placeholder="Palette Name"
                            />
                        </Styles.ChpTextInput>
                    </div>
                    <Styles.ChpOrText>or</Styles.ChpOrText>
                    <div>
                        <CustomDropdown
                            placeholder="Select Existing Palette"
                            options={paletteList?.map((v) => ({
                                name: v.name,
                                value: v?.id,
                            }))}
                            onChange={(value) => {
                                dispatch(setPaletteId(value));
                            }}
                        />
                    </div>
                </Styles.ChpFormInputContainer>
            </Styles.ChpFormControl>

            <Styles.ChpFormControl>
                <Styles.ChpLabelContainer>
                    <Styles.ChpLabelBox>
                        <img src={HueIcon} alt="Hue" />
                    </Styles.ChpLabelBox>
                    <Styles.ChpLabelText htmlFor="new-hue-name">
                        Hue Name
                    </Styles.ChpLabelText>
                </Styles.ChpLabelContainer>

                <Styles.ChpFormInputContainer>
                    <div>
                        <Styles.ChpTextInput>
                            <input
                                required
                                id="new-hue-name"
                                value={newHueForm.hueName}
                                onChange={(e) =>
                                    dispatch(setHueName(e.target.value))
                                }
                                placeholder="Hue name"
                            />
                        </Styles.ChpTextInput>
                    </div>
                    <Styles.ChpOrText></Styles.ChpOrText>
                    <div></div>
                </Styles.ChpFormInputContainer>
            </Styles.ChpFormControl>

            <Styles.ChpLine />

            <Styles.ChpFormErrors>
                <ul>
                    {errors.map((e) => (
                        <li key={e}>{e}</li>
                    ))}
                </ul>
            </Styles.ChpFormErrors>

            <Styles.ChpBtnContainer>
                <Styles.ChpCancelBtn onClick={goBack}>
                    Cancel
                </Styles.ChpCancelBtn>

                <Styles.ChpCreateBtn onClick={createHue}>
                    <div>Create</div>
                    <img src={RightArrow} alt="arrow" />
                </Styles.ChpCreateBtn>
            </Styles.ChpBtnContainer>
        </Styles.ChpContainer>
    );
};

CreateHuePage.displayName = 'NewHuePage';
