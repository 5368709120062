import { createSlice } from '@reduxjs/toolkit';

interface AppStateSlice {
    showSideBar: boolean;
}

const initialState: AppStateSlice = {
    showSideBar: false,
};

export const appStateSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            state.showSideBar = !state.showSideBar;
        },
    },
});

export const { toggleSidebar } = appStateSlice.actions;

export default appStateSlice.reducer;
