import styled from 'styled-components';

export const PrimaryButton = styled.button`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 2px;
    background: #2a2a30;
    color: #ffffff;
    cursor: pointer;
    font-size: 12px;
    font-family: 'Poppins-Medium';
    line-height: 22px;
    transition: 300ms all;

    &:hover {
        background-color: #ff7b02;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

export const SecondaryButton = styled.button`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 2px solid #2a2a30;
    background: #ffffff;
    color: #2a2a30;
    cursor: pointer;
    font-size: 12px;
    font-family: 'Poppins-Medium';
    line-height: 22px;
    transition: 300ms all;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;
