import { Button } from '@ui/components/Button';
import styled from 'styled-components';

export const TcPage = styled.div``;

export const TcContainer = styled.div`
    padding: 40px;
    border: 1px solid #e7e7e7;
    border-radius: 24px;
    background-color: #ffffff;

    @media (max-width: 700px) {
        padding: 20px 10px;
        border-radius: 8px;
    }
`;

export const TcHeader = styled.div`
    font-size: 18px;
    font-family: 'Poppins-Bold';
    color: #2a2a30;
`;

export const TcLine = styled.div`
    margin: 3px 0 5px 0;
    width: 100%;
    height: 2px;
    background-color: #707070;
`;

export const TcSubHeader = styled.div`
    font-size: 15px;
    font-family: 'Poppins-Regular';
    color: #2a2a30;
`;

export const TcSubHeader2 = styled(TcSubHeader)`
    margin: 5px 0 0 0;
`;

export const TcContainer2 = styled.div`
    margin: 46px 0 0 0;
`;

export const TcContainer3 = styled.div`
    margin: 34px 0 0 0;
`;

export const TcContainer4 = styled.div`
    margin: 115px 0 0 0;
`;

export const TcContainer5 = styled.div`
    margin: 56px 0 0 0;
`;

export const TcColorPickerContainer = styled.div`
    margin: 39px 0 0 0;
`;

export const TcColorPickerContainer2 = styled.div`
    margin: 22px 0 0 0;
`;

export const TcTextAreaContainer = styled.div`
    margin-top: 24px;

    textarea {
        width: 100%;
        height: 222px;
        padding: 30px;
        border: 1px solid #2a2a30;
        border-radius: 6px;
        transition: 300ms all;
        resize: none;
        font-size: 14px;
        font-family: 'Poppins-Medium';
        color: #2a2a30;
        border-radius: 2px;

        &:hover {
            border-color: #4096ff;
        }

        &:focus {
            border-color: #4096ff;
            box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
            outline: 0;
        }
    }
`;

export const TcSaveInput = styled.textarea``;

export const TcDescriptors = styled.div`
    margin: 16px 0 0 0;
`;

export const TcResultDesc = styled.div`
    margin: 84px 0 0 0;
    font-size: 15px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';
`;

export const TcColorTagContainer = styled.div`
    margin: 40px 0 0 0;
`;

export const TcSaveBtnContainer = styled.div`
    margin: 72px 0 0 0;
    display: flex;
    justify-content: center;
`;

export const TcSaveBtn = styled(Button)`
    width: 150px;
    height: 56px;
    border-radius: 4px;
`;

interface GradientPreviewProps {
    $startColor: string;
    $endColor: string;
}

export const GradientPreviewContainer = styled.div`
    margin-top: 40px;
`;

export const GradientPreview = styled.div<GradientPreviewProps>`
    margin-top: 40px;
    width: 100%;
    border-radius: 8px;
    height: 300px;
    background-image: ${({ $startColor, $endColor }) => `linear-gradient(
        90deg,
        ${$startColor},
        ${$endColor}
    )`};
    transition: 300ms all;
`;
