import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { TextInput } from '@ui/components/TextInput';
import { RegisterData } from '@ui/types/LoginData';
import { useAuthRegisterMutation } from '@ui/services/auth';
import { BtnLoader } from '@ui/components/Loaders';
import toast from 'react-hot-toast';
import { useAppDispatch } from '@ui/app/hooks';
import { authLogin, authLogout } from '@ui/slices/authSlice';
import OnHueLogoRound from '@ui/images/onHueLogoRound.svg';
import RightArrow from '@ui/images/right-arrow-icon.svg';
import * as Styles from './style';
import { useCreateUserSubscriptionMutation } from '@ui/services/subscription';

interface SignupInitialValues {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

const initialValues: SignupInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
};

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().required('Email is required').email('Invalid email'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().required('Confirm password is required'),
});

export const RegisterForm = () => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const [attemptRegister, { isLoading }] = useAuthRegisterMutation();

    const [createSubscription] = useCreateUserSubscriptionMutation();

    const handleSubmit = async (values: SignupInitialValues) => {
        try {
            const registerData: RegisterData = {
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password,
                email: values.email,
            };

            const data = await attemptRegister(registerData).unwrap();

            dispatch(authLogin({ ...data, email: values.email }));
            toast.success('Register success');

            createSubscription({ subscriptionPlanId: 1 });

            navigate('/dashboard');
        } catch (err) {
            console.log('error register', err);
            toast.error('Something went wrong');
            dispatch(authLogout());
        }
    };

    return (
        <Styles.RfContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <div>
                            <Styles.RfLogo src={OnHueLogoRound} alt="onhue" />
                            <Styles.RfHeader>
                                <span>Signup to</span>
                                <Styles.RfHeaderText>onHue</Styles.RfHeaderText>
                            </Styles.RfHeader>

                            <Styles.RfSubHeader>
                                By continuing, you agree to our Terms and <br />{' '}
                                Conditions and our Privacy Policy
                            </Styles.RfSubHeader>
                        </div>

                        <Styles.RfWrapper>
                            <Styles.RfInputRow>
                                <Styles.RfFormControl>
                                    <Styles.RfLabel htmlFor="firstName">
                                        FIRST NAME
                                    </Styles.RfLabel>

                                    <Styles.RfFieldControl>
                                        <TextInput
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            placeholder="Enter First Name"
                                            showIcon={true}
                                        />
                                    </Styles.RfFieldControl>

                                    <Styles.RfErrMsg>
                                        <ErrorMessage
                                            name="firstName"
                                            component="div"
                                        />
                                    </Styles.RfErrMsg>
                                </Styles.RfFormControl>
                                <Styles.RfFormControl>
                                    <Styles.RfLabel htmlFor="lastName">
                                        LAST NAME
                                    </Styles.RfLabel>

                                    <Styles.RfFieldControl>
                                        <TextInput
                                            type="text"
                                            name="lastName"
                                            id="lastName"
                                            placeholder="Enter Last Name"
                                            showIcon={true}
                                        />
                                    </Styles.RfFieldControl>

                                    <Styles.RfErrMsg>
                                        <ErrorMessage
                                            name="lastName"
                                            component="div"
                                        />
                                    </Styles.RfErrMsg>
                                </Styles.RfFormControl>
                            </Styles.RfInputRow>
                        </Styles.RfWrapper>

                        <Styles.RfFormControl2>
                            <Styles.RfLabel htmlFor="email">
                                EMAIL
                            </Styles.RfLabel>

                            <Styles.RfFieldControl>
                                <TextInput
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Enter Your Email"
                                    showIcon={true}
                                />
                            </Styles.RfFieldControl>

                            <Styles.RfErrMsg>
                                <ErrorMessage name="email" component="div" />
                            </Styles.RfErrMsg>
                        </Styles.RfFormControl2>

                        <Styles.RfFormControl2>
                            <Styles.RfLabel htmlFor="password">
                                PASSWORD
                            </Styles.RfLabel>

                            <Styles.RfFieldControl>
                                <TextInput
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Enter Your Password"
                                    showIcon={true}
                                />
                            </Styles.RfFieldControl>

                            <Styles.RfErrMsg>
                                <ErrorMessage name="password" component="div" />
                            </Styles.RfErrMsg>
                        </Styles.RfFormControl2>

                        <Styles.RfFormControl2>
                            <Styles.RfLabel htmlFor="confirmPassword">
                                CONFIRM PASSWORD
                            </Styles.RfLabel>

                            <Styles.RfFieldControl>
                                <TextInput
                                    type="password"
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    placeholder="Confirm Your Password"
                                    showIcon={true}
                                />
                            </Styles.RfFieldControl>

                            <Styles.RfErrMsg>
                                <ErrorMessage
                                    name="confirmPassword"
                                    component="div"
                                />
                            </Styles.RfErrMsg>
                        </Styles.RfFormControl2>

                        <Styles.RfSubmitBtn
                            type="submit"
                            isDisabled={isLoading}
                        >
                            {isLoading ? (
                                <BtnLoader />
                            ) : (
                                <>
                                    <div>Signup to onHue</div>
                                    <img src={RightArrow} alt="arrow" />
                                </>
                            )}
                        </Styles.RfSubmitBtn>

                        <Styles.RfLoginText>
                            Already have an account?{' '}
                            <Link to={'/'}>Login Now</Link>
                        </Styles.RfLoginText>
                    </Form>
                )}
            </Formik>
        </Styles.RfContainer>
    );
};
