import { useState } from 'react';
import toast from 'react-hot-toast';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    useCreateAdditionalUserMutation,
    useDeleteAdditionalUsersMutation,
    useGetAdditionalUsersQuery,
} from '@ui/services/team';
import { CustomModal } from '@ui/components/CustomModal';
import { NotFound } from '@ui/components/NotFound';
import { USER_IMAGE_FOLDER } from '@ui/utils/constants';
import UserPlaceholder from '@ui/images/user-placeholder.svg';
import * as Styles from './style';
import { AddTeamMember } from './components/AddTeamMember';
import { RemoveTeamMember } from './components/RemoveTeamMember';

enum MODAL_STATUS {
    CLOSED = 0,
    OPEN_REMOVE = 1,
}

export const Team = () => {
    const [modalStatus, setModalStatus] = useState<MODAL_STATUS>(
        MODAL_STATUS.CLOSED
    );

    const [selectedEmail, setSelectedEmail] = useState('');
    const [selectedProfilePic, setSelectedProfilePic] = useState<string | null>(
        ''
    );

    const { data: teamMembers, refetch } = useGetAdditionalUsersQuery();

    const [createAdditionalUser, { isLoading: addingTeamMember }] =
        useCreateAdditionalUserMutation();

    const [deleteAdditionalUser] = useDeleteAdditionalUsersMutation();

    const handleCreateAdditionalUser = async (email: string) => {
        try {
            await createAdditionalUser({ userEmail: email }).unwrap();

            toast.success('Team members added');
            refetch();
        } catch (err) {
            console.log('err handleCreateAdditionalUser ', err);
            toast.error('Something went wrong');
        }
    };

    const openRemoveModal = (email: string, profilePicSrc: string | null) => {
        setSelectedEmail(email);
        setModalStatus(MODAL_STATUS.OPEN_REMOVE);
        setSelectedProfilePic(profilePicSrc);
    };

    const closeRemoveModal = () => {
        setSelectedEmail('');
        setSelectedProfilePic('');
        setModalStatus(MODAL_STATUS.CLOSED);
    };

    const handleConfirmRemoveTeamMember = async () => {
        try {
            await deleteAdditionalUser({ userEmail: selectedEmail }).unwrap();
            toast.success('Team member removed');
            refetch();
            closeRemoveModal();
        } catch (err) {
            console.log('err ', err);
            toast.error('Something went wrong');
        }
    };

    const renderExistingTeamMembers = () => {
        if (Array.isArray(teamMembers?.activeManagedUsers)) {
            if (teamMembers?.activeManagedUsers?.length) {
                return teamMembers?.activeManagedUsers?.map((v) => (
                    <Styles.TeamListItem key={v.adminUserId}>
                        <Styles.TpiName>
                            <Styles.TpiImage
                                src={
                                    v?.userProfileImagePath
                                        ? `${USER_IMAGE_FOLDER}${v?.userProfileImagePath}`
                                        : UserPlaceholder
                                }
                                alt="profile"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = UserPlaceholder;
                                }}
                            />
                            <div>
                                {v?.firstName} {v?.lastName}
                            </div>
                        </Styles.TpiName>
                        <div>{v?.userEmail}</div>

                        <Styles.TeamListRightAligned
                            onClick={() =>
                                openRemoveModal(
                                    v?.userEmail,
                                    v?.userProfileImagePath
                                )
                            }
                        >
                            <FontAwesomeIcon icon={faTrash} className="icon" />
                        </Styles.TeamListRightAligned>
                    </Styles.TeamListItem>
                ));
            }

            return (
                <NotFound
                    header="No current team member yet"
                    subHeader="There is no team member added yet, Click on “Add team member” button to add new members into team"
                />
            );
        }

        return (
            <NotFound
                header="No current team member yet"
                subHeader="There is no team member added yet, Click on “Add team member” button to add new members into team"
            />
        );
    };

    const renderNonExistingTeamMembers = () => {
        if (Array.isArray(teamMembers?.invitedManagedUsers)) {
            if (teamMembers?.invitedManagedUsers?.length) {
                return teamMembers?.invitedManagedUsers.map((v) => (
                    <Styles.TeamListItem key={v?.adminUserId}>
                        <Styles.TpiName>
                            <Styles.TpiImage
                                src={UserPlaceholder}
                                alt="profile"
                            />
                            <div></div>
                        </Styles.TpiName>
                        <div>{v?.userEmail}</div>

                        <Styles.TeamListRightAligned
                            onClick={() => openRemoveModal(v?.userEmail, null)}
                        >
                            <FontAwesomeIcon icon={faTrash} className="icon" />
                        </Styles.TeamListRightAligned>
                    </Styles.TeamListItem>
                ));
            }

            return (
                <NotFound
                    header="No pending team member yet"
                    subHeader="There is no team member added yet, Click on “Add team member” button to add new members into team"
                />
            );
        }

        return (
            <NotFound
                header="No pending team member yet"
                subHeader="There is no team member added yet, Click on “Add team member” button to add new members into team"
            />
        );
    };

    const disabledAddBtn = () => {
        if (Array.isArray(teamMembers?.activeManagedUsers)) {
            const totalMembers = teamMembers?.activeManagedUsers?.length ?? 0;

            return totalMembers >= 4;
        }

        return false;
    };

    return (
        <Styles.TpPage>
            <AddTeamMember
                handleAdd={handleCreateAdditionalUser}
                disabledAddBtn={disabledAddBtn() || addingTeamMember}
            />

            <Styles.TpHeader2>Current Team Members:</Styles.TpHeader2>

            <Styles.TeamList>{renderExistingTeamMembers()}</Styles.TeamList>

            <Styles.TpHeader2>Pending Team Members:</Styles.TpHeader2>

            <Styles.TeamList>{renderNonExistingTeamMembers()}</Styles.TeamList>

            {modalStatus === MODAL_STATUS.OPEN_REMOVE && (
                <CustomModal show={modalStatus === MODAL_STATUS.OPEN_REMOVE}>
                    <RemoveTeamMember
                        profileImgSrc={selectedProfilePic}
                        handleCancel={() => closeRemoveModal()}
                        handleConfirm={() => handleConfirmRemoveTeamMember()}
                        selectedEmail={selectedEmail}
                    />
                </CustomModal>
            )}
        </Styles.TpPage>
    );
};
