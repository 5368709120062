import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PiContainer = styled.div`
    position: relative;
    cursor: pointer;
    padding: 14px 12px 18px 12px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.9);
    transition: 300ms all;
    text-decoration: none !important;
    max-height: 375px;
    overflow-y: auto;

    &:hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

export const PiHeader = styled.div`
    color: #2a2a30;
    font-family: 'Poppins-Bold';
    font-size: 20px;
`;

export const PiHueName = styled.div`
    color: #2a2a30;
    font-family: 'Poppins-Medium';
    font-size: 16px;
`;

export const PiHueListItem = styled.div``;

export const PiHueList = styled.div`
    margin-top: 10px;

    ${PiHueListItem}:not(:first-child) {
        margin-top: 10px;
        border-top: 1px solid #e9edf1;
        padding-top: 10px;
    }
`;

export const PiColorContainer = styled(Link)`
    display: flex;
    margin-top: 10px;
    align-items: center;
    position: relative;
    height: 53px;
`;

type PiColorProps = {
    $backgroundColor: string;
    $index: number;
};

export const PiColor = styled.div<PiColorProps>`
    height: 53px;
    width: 53px;
    border-radius: 50%;
    border: 2px solid #2a2a30;
    background-color: ${({ $backgroundColor }) => $backgroundColor};

    position: absolute;
    left: ${({ $index }) => `${$index * 38}px`};
    transition: 300ms all;

    &:hover {
        transform: scale(1.2);
        z-index: 1000;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 16px;
        color: rgb(0, 0, 0);
        font-family: 'Poppins-Bold';
    }
`;

export const PiTrashIcon = styled.div`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: red;
`;
