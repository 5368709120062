import { RoundCheckbox } from '@ui/components/RoundCheckbox';
import { toggleAaHexOl, toggleAaaHexOl } from '@ui/slices/colorStudioSlice';
import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import * as Styles from './style';

export const ColorOptimization = () => {
    const { aaHexOL, aaaHexOL } = useAppSelector((state) => state.colorStudio);

    const dispatch = useAppDispatch();

    return (
        <Styles.CoContainer>
            <Styles.CoHeader>OPTIMIZATION:</Styles.CoHeader>

            <Styles.CoDesc>
                Eyesight varies, therefore sharper contrasts might be needed for
                individuals to properly distinguish colors. To ensure the best
                contrast results, select which type of optimization standard you
                want to achieve. This will also apply to all your color
                corrections.
            </Styles.CoDesc>

            <Styles.CoCheckBox>
                <RoundCheckbox
                    labelText="AA"
                    checked={aaHexOL}
                    onChange={() => dispatch(toggleAaHexOl(!aaHexOL))}
                />
                <RoundCheckbox
                    labelText="AAA"
                    checked={aaaHexOL}
                    onChange={() => dispatch(toggleAaaHexOl(!aaaHexOL))}
                />
            </Styles.CoCheckBox>
        </Styles.CoContainer>
    );
};
