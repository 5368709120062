import { Button } from '@ui/components/Button';
import styled from 'styled-components';

export const AtmBtn = styled(Button)`
    width: 100%;
`;

export const AtmContainer = styled.div`
    width: 600px;
    background-color: white;
    padding: 30px;

    @media (max-width: 700px) {
        width: 100%;
        padding: 30px 20px;
    }
`;

export const AtmHeader = styled.div`
    text-align: center;
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    color: #2a2a30;
`;

export const AtmHeader2 = styled.div`
    margin: 10px 0 0 0;
    text-align: center;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    color: #2a2a30;
`;

export const AtmEmail = styled.div`
    margin: 30px 0 0 0;
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    color: #2a2a30;
`;

export const AtmBtns = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 250px 0 0 0;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const AtmInputContainer = styled.div`
    margin: 20px 0 0 0;
`;
