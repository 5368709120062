import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import { setColorRationale } from '@ui/slices/colorStudioSlice';
import * as Styles from './style';

export const ColorRationale = () => {
    const { rationale } = useAppSelector((state) => state.colorStudio);

    const dispatch = useAppDispatch();

    return (
        <Styles.CrContainer>
            <Styles.CrHeader>RATIONALE:</Styles.CrHeader>

            <Styles.CrSubHeader>
                The choice of a color is very important, therefore it would be
                very valuable for all to see the rationale behind its choice
            </Styles.CrSubHeader>

            <Styles.CrTextAreaContainer>
                <Styles.CrTextArea
                    value={rationale}
                    onChange={(e) =>
                        dispatch(setColorRationale(e.target.value))
                    }
                />
            </Styles.CrTextAreaContainer>
        </Styles.CrContainer>
    );
};
