import UserPlaceholder from '@ui/images/user-placeholder.svg';
import * as Styles from './style';

interface RemoveTeamMemberProps {
    profileImgSrc?: string | null;
    selectedEmail: string;
    handleCancel: () => void;
    handleConfirm: () => void;
}

export const RemoveTeamMember = (props: RemoveTeamMemberProps) => {
    const { profileImgSrc, handleConfirm, handleCancel, selectedEmail } = props;

    return (
        <Styles.RtmContainer>
            <Styles.RtmHeader>Remove member</Styles.RtmHeader>

            <Styles.RtmLine />

            <Styles.RtmConfirm>
                Are you sure you want to remove:{' '}
            </Styles.RtmConfirm>

            <Styles.RtmName>
                <Styles.RtmImage
                    src={
                        profileImgSrc
                            ? `https://onhue-api.rapidinnovation.tech/userImageUploads/${profileImgSrc}`
                            : UserPlaceholder
                    }
                    alt="profile"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = UserPlaceholder;
                    }}
                />
                <div>{selectedEmail}</div>
            </Styles.RtmName>

            <Styles.RtmBtns>
                <Styles.RtmBtn onClick={handleCancel}>Cancel</Styles.RtmBtn>
                <Styles.RtmBtn onClick={handleConfirm}>Confirm</Styles.RtmBtn>
            </Styles.RtmBtns>
        </Styles.RtmContainer>
    );
};
