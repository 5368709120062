import { api } from './api';

export const statsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTotalApiCalls: builder.query<number, void>({
            query: () => ({
                url: 'users/getTotalApiCalls',
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetTotalApiCallsQuery } = statsApi;
