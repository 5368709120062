import * as Styles from './style';

interface RoundCheckboxProps {
    labelText: string;
    checked: boolean;
    onChange: () => void;
}

export const RoundCheckbox = (props: RoundCheckboxProps) => {
    const { labelText, checked, onChange } = props;

    return (
        <Styles.RcContainer>
            <Styles.RcSwitchLabel>
                <Styles.RcInput
                    type="checkbox"
                    checked={checked}
                    onChange={() => onChange()}
                />
                <Styles.RcSlider />
            </Styles.RcSwitchLabel>
            <Styles.RcText>{labelText}</Styles.RcText>
        </Styles.RcContainer>
    );
};
