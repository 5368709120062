import styled from 'styled-components';

export const CtcContainer = styled.div``;

export const CtcHeader = styled.div`
    font-size: 18px;
    font-family: 'Poppins-Bold';
    color: #2a2a30;
`;

export const CtcDesc = styled.div`
    margin-top: 5px;
    font-size: 15px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';
`;

export const CtcCheckBox = styled.div`
    margin-top: 23px;
    display: flex;
    align-items: center;
    gap: 100px;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
`;
