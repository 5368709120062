import { PROFILE_TABS } from '@ui/types/Profile';
import * as Styles from './style';

interface ProfileTabProps {
    activeTab: PROFILE_TABS;
    handleTabSwitch: (val: PROFILE_TABS) => void;
}

export const ProfileTabs = (props: ProfileTabProps) => {
    const { activeTab, handleTabSwitch } = props;

    return (
        <Styles.PtContainer>
            <Styles.PtItem
                selected={activeTab === PROFILE_TABS.ABOUT}
                onClick={() => handleTabSwitch(PROFILE_TABS.ABOUT)}
            >
                About
            </Styles.PtItem>
            <Styles.PtItem
                selected={activeTab === PROFILE_TABS.EDIT}
                onClick={() => handleTabSwitch(PROFILE_TABS.EDIT)}
            >
                Edit
            </Styles.PtItem>
            <Styles.PtItem
                selected={activeTab === PROFILE_TABS.UPDATE_PASSWORD}
                onClick={() => handleTabSwitch(PROFILE_TABS.UPDATE_PASSWORD)}
            >
                Password
            </Styles.PtItem>
        </Styles.PtContainer>
    );
};
