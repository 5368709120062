import { useGetUserNotificationsQuery } from '@ui/services/notifications';
import * as Styles from './style';
import { NotFound } from '@ui/components/NotFound';

export const NotificationsPage = () => {
    const { data: notifications } = useGetUserNotificationsQuery();

    const renderNotifications = () => {
        if (Array.isArray(notifications)) {
            if (notifications.length) {
                return notifications.map((v, k) => (
                    <Styles.NpItem key={k}>
                        {v.notificationMessage}
                    </Styles.NpItem>
                ));
            }

            return (
                <NotFound
                    header="No Notifications"
                    subHeader="We could not find any notifications"
                />
            );
        }

        return (
            <NotFound
                header="No Notifications"
                subHeader="We could not find any notifications"
            />
        );
    };

    return (
        <Styles.NpPage>
            <Styles.NpHeader>Notifications</Styles.NpHeader>
            <Styles.NpContainer>{renderNotifications()}</Styles.NpContainer>
        </Styles.NpPage>
    );
};
