import styled from 'styled-components';
import { Button } from '@ui/components/Button/Button';

export const CdContainer = styled.div`
    width: 600px;
    background-color: white;
    padding: 30px;

    @media (max-width: 700px) {
        width: 100%;
        padding: 30px 20px;
    }
`;

export const CdHeader = styled.div`
    font-size: 18px;
    font-family: 'Poppins-Regular';
    color: #2a2a30;
    text-align: center;

    @media (max-width: 700px) {
        font-size: 14px;
    }
`;

export const CdInfo = styled.div`
    margin-top: 50px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
`;

interface CdColorProps {
    $backgroundColor: string;
}

export const CdColor = styled.div<CdColorProps>`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const CdName = styled.div`
    font-size: 16px;
    font-family: 'Poppins-Regular';
    color: #2a2a30;
`;

export const CdBtns = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 60px 0 0 0;
`;

export const CdCancelBtn = styled(Button)`
    width: 220px;
    height: 56px;

    @media (max-width: 700px) {
        width: 100%;
    }
`;

export const CdConfirmBtn = styled(Button)`
    width: 220px;
    height: 56px;
    background-color: red;

    @media (max-width: 700px) {
        width: 100%;
    }
`;
