import styled from 'styled-components';

export const HelpPage = styled.div`
    padding: 100px 0;
    min-height: 100vh;
`;

export const HelpHeader = styled.h1`
    font-size: 24px;
    font-family: 'Poppins-SemiBold';
    color: #2a2a30;
`;

export const HelpList = styled.ul`
    list-style-position: inside;
    padding: 20px;

    li {
        margin-top: 8px;
        font-size: 16px;
        color: #2a2a30;
        font-family: 'Poppins-Regular';
    }

    code {
        display: block;
        padding: 10px;
        background: #757575;
        color: white;
        border-radius: 8px;
    }
`;
