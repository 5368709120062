import PaletteIcon from '@ui/images/hue-pallet-icon.svg';
import HueIcon from '@ui/images/hue-hue-icon.svg';
import * as Styles from './style';

const colors = [0, 1, 2, 3, 4];

export const HueLoader = () => {
    const renderColor = () => {
        return colors.map((v) => (
            <Styles.HlColor key={v} $index={v}>
                <span></span>
            </Styles.HlColor>
        ));
    };

    return (
        <Styles.HlContainer>
            <Styles.HlProjectName />
            <Styles.HlHeaderContainer>
                <Styles.HlHeaderIcon>
                    <img src={PaletteIcon} alt="palette" />
                </Styles.HlHeaderIcon>
                <Styles.HlHeaderText>Palette Name</Styles.HlHeaderText>
            </Styles.HlHeaderContainer>
            <Styles.HlPalletName />
            <Styles.HlHeaderContainer2>
                <Styles.HlHeaderIcon>
                    <img src={HueIcon} alt="hue" />
                </Styles.HlHeaderIcon>
                <Styles.HlHeaderText>Hue</Styles.HlHeaderText>
            </Styles.HlHeaderContainer2>
            <Styles.HlPalletName />
            <Styles.HlLine />
            <Styles.HlColorContainer>{renderColor()}</Styles.HlColorContainer>
        </Styles.HlContainer>
    );
};
