import {
    CREATE_ADDITIONAL_USER,
    DELETE_ADDITIONAL_USER,
    TEAM_MEMBERS,
} from '@ui/types/Team';
import { api } from './api';

export const teamApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAdditionalUsers: builder.query<TEAM_MEMBERS, void>({
            query: () => ({
                url: 'users/getAdditionalUsers',
                method: 'GET',
            }),
        }),
        createAdditionalUser: builder.mutation<unknown, CREATE_ADDITIONAL_USER>(
            {
                query: (createAdditionalUser) => ({
                    url: `users/createAdditionalUser`,
                    method: 'POST',
                    body: createAdditionalUser,
                }),
            }
        ),
        deleteAdditionalUsers: builder.mutation<
            unknown,
            DELETE_ADDITIONAL_USER
        >({
            query: (deleteAdditionalUser) => ({
                url: '/users/deleteAdditionalUsers',
                method: 'DELETE',
                body: deleteAdditionalUser,
            }),
        }),
    }),
});

export const {
    useGetAdditionalUsersQuery,
    useCreateAdditionalUserMutation,
    useDeleteAdditionalUsersMutation,
} = teamApi;
