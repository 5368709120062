import styled from 'styled-components';

interface TiContainerProps {
    $showIcon: boolean;
}

export const TiContainer = styled.div<TiContainerProps>`
    position: relative;
    border: 1px solid #2a2a30;
    border-radius: 2px;
    transition: 300ms all;

    &:hover {
        border-color: #4096ff;
    }

    &:focus-within {
        border-color: #4096ff;
        box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
        outline: 0;
    }

    input {
        width: 100%;
        padding: ${({ $showIcon }) =>
            $showIcon ? '12px 14px 12px 36px' : '12px 14px 12px 14px'};
        color: #2a2a30;
        font-family: 'Poppins-Medium';
        font-size: 14px;

        background-color: transparent;
        border: 0;

        &:focus {
            border: 0;
            border-color: transparent;
            outline: 0;
        }

        &::placeholder {
            color: #2a2a30;
            font-family: 'Poppins-Medium';
            font-size: 14px;
        }
    }
`;

export const TiIconContainer = styled.div`
    pointer-events: none;
    width: 36px;
    padding-right: 6px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const TiPasswordContainer = styled.div<TiContainerProps>`
    position: relative;
    border: 1px solid #2a2a30;
    border-radius: 2px;
    transition: 300ms all;

    &:hover {
        border-color: #4096ff;
    }

    &:focus-within {
        border-color: #4096ff;
        box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
        outline: 0;
    }

    input {
        width: 100%;
        padding: ${({ $showIcon }) =>
            $showIcon ? '12px 14px 12px 36px' : '12px 14px 12px 14px'};
        color: #2a2a30;
        font-family: 'Poppins-Medium';
        font-size: 14px;
        font-weight: 500;
        background-color: transparent;
        border: 0;

        &:focus {
            border: 0;
            border-color: transparent;
            outline: 0;
        }

        &::placeholder {
            color: #2a2a30;
            font-family: 'Poppins-Medium';
            font-size: 14px;
            font-weight: 500;
        }
    }
`;

export const TiPasswordIcon = styled.div`
    cursor: pointer;
    width: 40px;
    height: 100%;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
`;
