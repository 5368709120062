import { DropdownOptions } from '@ui/types/types';

export const CSS_PROPERTIES: DropdownOptions[] = [
    { name: 'Color', value: 'color' },
    { name: 'Background Color', value: 'background-color' },
    { name: 'Border Color', value: 'border-color' },
    { name: 'Outline Color', value: 'outline-color' },
    { name: 'Linear Gradient', value: 'background-image' },
    { name: 'Fill Color', value: 'fill' },
    { name: 'Stroke Color', value: 'stroke' },
    { name: 'Box Shadow Color', value: 'box-shadow' },
    { name: 'Text Shadow Color', value: 'text-shadow' },
    { name: 'Border Top Color', value: 'border-top-color' },
    { name: 'Border Right Color', value: 'border-right-color' },
    { name: 'Border Bottom Color', value: 'border-bottom-color' },
    { name: 'Border Left Color', value: 'border-left-color' },
    { name: 'Column Rule Color', value: 'column-rule-color' },
    { name: 'Caret Color', value: 'caret-color' },
    { name: 'Text Decoration Color', value: 'text-decoration-color' },
    { name: 'Text Emphasis Color', value: 'text-emphasis-color' },
    { name: 'Stop Color', value: 'stop-color' },
    { name: 'Flood Color', value: 'flood-color' },
    { name: 'Lighting Color', value: 'lighting-color' },
    { name: 'Scrollbar Color', value: 'scrollbar-color' },
];
