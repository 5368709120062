import styled from 'styled-components';

export const ClContainer = styled.div`
    display: grid;
    grid-template-columns: 220px minmax(0, 1fr);

    @media (max-width: 1000px) {
        grid-template-columns: 0 minmax(0, 1fr);
    }
`;

export const ClOutlet = styled.div`
    padding: 0 50px;

    @media (max-width: 1000px) {
        padding: 0 20px;
    }
`;
