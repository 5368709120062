import styled from 'styled-components';

export const CsPage = styled.div`
    padding: 100px 0;
    min-height: 100vh;
`;

export const CsContent = styled.div`
    margin: 27px 0 0 0;
`;
