/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { TextInput } from '@ui/components/TextInput';
import {
    useGetProfileQuery,
    useUpdateProfileMutation,
} from '@ui/services/profile';
import { BtnLoader } from '@ui/components/Loaders';
import { FormErrMsg } from '@ui/utils/globalStyles';
import * as Styles from './style';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

interface EditProfileInitialValues {
    firstName: string;
    lastName: string;
    bio: string;
    designation: string;
    profileImage: string;
    profileImageFile: FileList | null;
    coverImage: string;
    coverImageFile: FileList | null;
}

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    bio: Yup.string().required('Bio is required'),
    designation: Yup.string().required('Designation is required'),
    profileImage: Yup.string(),
    profileImageFile: Yup.mixed()
        .nullable()
        //@ts-ignore
        .test('fileType', 'File is larger than 1mb', (value: File[]) => {
            if (value?.length) {
                return value[0].size <= 1024 * 1024;
            }

            return true;
        })
        .test('fileType', 'Unsupported file type', (value) => {
            if (Array.isArray(value)) {
                return SUPPORTED_FORMATS.includes(value[0].type);
            }

            return true;
        }),
    coverImage: Yup.string(),
    coverImageFile: Yup.mixed()
        .nullable()
        //@ts-ignore
        .test('fileType', 'File is larger than 1mb', (value: File[]) => {
            if (value?.length) {
                return value[0].size <= 1024 * 1024;
            }

            return true;
        })
        .test('fileType', 'Unsupported file type', (value) => {
            if (Array.isArray(value)) {
                return SUPPORTED_FORMATS.includes(value[0].type);
            }

            return true;
        }),
});

export const EditProfile = () => {
    const { data: profile, refetch } = useGetProfileQuery();

    const initialValues: EditProfileInitialValues = {
        firstName: profile?.userFirstName ?? '',
        lastName: profile?.userLastName ?? '',
        bio: profile?.userBio ?? '',
        designation: profile?.userDesignation ?? '',
        profileImage: profile?.userProfileImagePath ?? '',
        profileImageFile: null,
        coverImage: profile?.userBannerImagePath ?? '',
        coverImageFile: null,
    };

    const [updateProfile, { isLoading }] = useUpdateProfileMutation();

    const handleSubmit = async (values: EditProfileInitialValues) => {
        try {
            const formdata = new FormData();

            const profileImageFile = values?.profileImageFile?.[0];
            const coverImageFile = values?.coverImageFile?.[0];

            console.log('profileImageFile ', profileImageFile);

            formdata.append('UserFirstName', values.firstName);
            formdata.append('UserLastName', values.lastName);
            formdata.append('UserBio', values.bio);
            formdata.append('UserDesignation', values.designation);

            if (profile?.userEmail) {
                formdata.append('UserEmail', profile?.userEmail);
            }

            if (profileImageFile) {
                formdata.append('UserProfileImage', profileImageFile);
            }

            if (coverImageFile) {
                formdata.append('UserBannerImage', coverImageFile);
            }

            if (profile?.userEmail) {
                await updateProfile(formdata).unwrap();

                toast.success('Profile updated');

                refetch();
            } else {
                toast.error('Email not found');
            }
        } catch (err) {
            toast.error('Something went wrong');
        }
    };

    return (
        <Styles.UpContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values, errors }) => {
                    const handleUserProfileImage = (
                        e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                        const files = e?.target?.files;

                        if (files) {
                            setFieldValue(
                                'profileImage',
                                URL.createObjectURL(files[0])
                            );

                            setFieldValue('profileImageFile', files);
                        }
                    };

                    console.log('errors ', errors);

                    const handleUserProfileCover = (
                        e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                        const files = e?.target?.files;

                        if (files) {
                            setFieldValue(
                                'coverImage',
                                URL.createObjectURL(files[0])
                            );

                            setFieldValue('coverImageFile', files);
                        }
                    };

                    return (
                        <Form>
                            <Styles.RpFormControl>
                                <Styles.RpLabel htmlFor="firstName">
                                    FIRST NAME
                                </Styles.RpLabel>

                                <Styles.RpFieldControl>
                                    <TextInput
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        showIcon={false}
                                    />
                                </Styles.RpFieldControl>

                                <FormErrMsg>
                                    <ErrorMessage
                                        name="firstName"
                                        component="div"
                                    />
                                </FormErrMsg>
                            </Styles.RpFormControl>

                            <Styles.RpFormControl2>
                                <Styles.RpLabel htmlFor="lastName">
                                    LAST NAME
                                </Styles.RpLabel>

                                <Styles.RpFieldControl>
                                    <TextInput
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        showIcon={false}
                                    />
                                </Styles.RpFieldControl>

                                <FormErrMsg>
                                    <ErrorMessage
                                        name="lastName"
                                        component="div"
                                    />
                                </FormErrMsg>
                            </Styles.RpFormControl2>

                            <Styles.RpFormControl2>
                                <Styles.RpLabel htmlFor="bio">
                                    BIO
                                </Styles.RpLabel>

                                <Styles.RpFieldControl>
                                    <TextInput
                                        type="text"
                                        name="bio"
                                        id="bio"
                                        showIcon={false}
                                    />
                                </Styles.RpFieldControl>

                                <FormErrMsg>
                                    <ErrorMessage name="bio" component="div" />
                                </FormErrMsg>
                            </Styles.RpFormControl2>

                            <Styles.RpFormControl2>
                                <Styles.RpLabel htmlFor="bio">
                                    Designation
                                </Styles.RpLabel>

                                <Styles.RpFieldControl>
                                    <TextInput
                                        type="text"
                                        name="designation"
                                        id="designation"
                                        showIcon={false}
                                    />
                                </Styles.RpFieldControl>

                                <FormErrMsg>
                                    <ErrorMessage
                                        name="designation"
                                        component="div"
                                    />
                                </FormErrMsg>
                            </Styles.RpFormControl2>

                            <Styles.RpFileFormControl>
                                <Styles.RpLabel htmlFor="profileImageFile">
                                    PROFILE PICTURE
                                    <Styles.EpUploadPic>
                                        {values?.profileImage ? (
                                            <Styles.EpUploadPicPreview
                                                alt="preview"
                                                src={values.profileImage}
                                            />
                                        ) : (
                                            <>Upload profile picture</>
                                        )}
                                    </Styles.EpUploadPic>
                                </Styles.RpLabel>

                                <input
                                    type="file"
                                    name="profileImageFile"
                                    id="profileImageFile"
                                    onChange={handleUserProfileImage}
                                    accept="image/png, image/gif, image/jpeg, image/jpg"
                                />
                            </Styles.RpFileFormControl>

                            <FormErrMsg>
                                <ErrorMessage
                                    name="profileImageFile"
                                    component="div"
                                />
                            </FormErrMsg>

                            <Styles.RpFileFormControl>
                                <Styles.RpLabel htmlFor="coverImageFile">
                                    COVER PICTURE
                                    <Styles.EpUploadPic>
                                        {values?.coverImage ? (
                                            <Styles.EpUploadCoverPreview
                                                alt="preview"
                                                src={values.coverImage}
                                            />
                                        ) : (
                                            <>Upload cover picture</>
                                        )}
                                    </Styles.EpUploadPic>
                                </Styles.RpLabel>

                                <input
                                    type="file"
                                    name="coverImageFile"
                                    id="coverImageFile"
                                    onChange={handleUserProfileCover}
                                    accept="image/png, image/gif, image/jpeg, image/jpg"
                                />
                            </Styles.RpFileFormControl>

                            <FormErrMsg>
                                <ErrorMessage
                                    name="coverImageFile"
                                    component="div"
                                />
                            </FormErrMsg>

                            <Styles.RpRegisterBtn
                                type="submit"
                                isDisabled={isLoading}
                            >
                                {isLoading ? <BtnLoader /> : 'Update Profile'}
                            </Styles.RpRegisterBtn>
                        </Form>
                    );
                }}
            </Formik>
        </Styles.UpContainer>
    );
};
