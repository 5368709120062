import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavbarWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 200px;
    right: 0;
    z-index: 100;
    user-select: none;

    @media (max-width: 1000px) {
        left: 0;
    }
`;

export const NavbarContainer = styled.div`
    height: 60px;
    background-color: #2a2a30;
    display: flex;
    align-items: center;

    padding: 0 50px;

    @media (max-width: 1000px) {
        padding: 0 20px;
    }
`;

export const NavHamburger = styled.div`
    cursor: pointer;
    width: 60px;
    height: 60px;

    display: none;
    align-items: center;
    justify-content: flex-start;

    font-size: 30px;
    color: #ffffff;

    @media (max-width: 1000px) {
        display: flex;
    }
`;

export const NavbarUserInfo = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`;

export const NavbarNotification = styled.img`
    width: 24px;
    height: 24px;
`;

export const NavbarLine = styled.div`
    width: 1px;
    height: 22px;
    margin: 0 24px;
    opacity: 0.2;
    background: #e9edf1;
`;

export const NavbarUserName = styled.div`
    width: 60px;
    color: #ffffff;
    font-family: 'Poppins-SemiBold';
    font-size: 14px;
`;

export const NavbarProfilePic = styled.div`
    position: relative;
    cursor: pointer;
    padding: 0 3px 0 0;
    height: 32px;
    display: flex;
    align-items: center;
`;

export const NavbarImg = styled.img`
    margin-left: 17px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
`;

type NavbarDropdownArrowProps = {
    open: boolean;
};

export const NavbarDropdownArrow = styled.img<NavbarDropdownArrowProps>`
    margin-left: 6px;
    transition: 300ms all;

    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const NavbarDropdown = styled.div`
    position: absolute;
    top: 46px;
    right: 0;
    width: 238px;
    border-radius: 2px;
    background: #ffffff;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
`;

export const NavbarDropdownLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: #e9edf1;
`;

export const NavbarDropdownContainer = styled.div`
    padding: 11px 0;
`;

export const NavbarDropdownItem = styled(Link)`
    padding: 0 22px;
    height: 38px;
    display: flex;
    align-items: center;
    color: #2a2a30;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    font-weight: 500;
    transition: 300ms all;
    text-decoration: none;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

export const NavbarLogout = styled.div`
    padding: 16px 22px;
    color: #ff1a1a;
    font-size: 14px;
    font-family: 'Poppins-SemiBold';
    transition: 300ms all;

    &:hover {
        background-color: #ff1a1a;
        color: #ffffff;
    }
`;
