import { useRef, useState } from 'react';
import DropdownArrow from '@ui/images/dropdown-arrow.svg';
import { DropdownOptions } from '@ui/types/types';
import { useClickOutside } from '@ui/utils/hooks/useClickOutside';
import * as Styles from './style';
import { NotFound } from '../NotFound';

type CustomDropdownProps = {
    placeholder: string;
    options: DropdownOptions[];
    onChange: (value: string | number) => void;
    value?: string | number;
    className?: string;
    onBlur?: () => void;
};

export const CustomDropdown = (props: CustomDropdownProps) => {
    const { placeholder, options, onChange, value, onBlur, ...rest } = props;

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<string | number>('');

    const handleSelect = (name: string, value: string | number) => {
        onChange(value);
        setSelected(name);
    };

    const dropdownRef = useRef(null);

    const closeDropdown = () => {
        setOpen(false);

        if (onBlur) {
            onBlur();
        }
    };

    useClickOutside(dropdownRef, closeDropdown);

    const renderValue = () => {
        const selectedOption = options.filter((v) => v.value === value);

        if (selectedOption.length) {
            return selectedOption[0].name;
        } else {
            return (
                selected || (
                    <Styles.CpPlaceHolder>{placeholder}</Styles.CpPlaceHolder>
                )
            );
        }
    };

    const renderOptions = () => {
        if (options.length) {
            return (
                <Styles.DpItemList>
                    {options.map((v, k) => (
                        <Styles.DpItemListItem
                            key={k}
                            onClick={() => handleSelect(v.name, v.value)}
                        >
                            {v.name}
                        </Styles.DpItemListItem>
                    ))}
                </Styles.DpItemList>
            );
        }

        return (
            <Styles.DpEmptyList>
                <NotFound header="No options available" />
            </Styles.DpEmptyList>
        );
    };

    return (
        <Styles.CdContainer
            onClick={() => setOpen((v) => !v)}
            ref={dropdownRef}
            {...rest}
        >
            <Styles.CpTextBox>
                <span>{renderValue()}</span>
                <Styles.CpArrow src={DropdownArrow} alt="arrow" open={open} />
            </Styles.CpTextBox>

            {open && renderOptions()}
        </Styles.CdContainer>
    );
};
