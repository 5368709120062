import { COLOR_CONTRAST_RESULT } from '@ui/types/types';
import styled from 'styled-components';

export const CcWrapper = styled.div`
    display: grid;
    grid-template-columns: 277px 350px minmax(0, 1fr);
    grid-gap: 51px;
    align-items: center;

    @media (max-width: 1300px) {
        grid-template-columns: 200px 250px minmax(0, 1fr);
    }

    @media (max-width: 1200px) {
        grid-template-columns: 1fr;
    }
`;

interface CcCircle2Props {
    $bgColor: string;
}

export const CcCircle = styled.div<CcCircle2Props>`
    width: 100%;
    height: 277px;
    background-color: ${({ $bgColor }) => $bgColor};
    border-radius: 50%;
    position: relative;

    @media (max-width: 1300px) {
        height: 200px;
    }

    @media (max-width: 1200px) {
        width: 277px;
        height: 277px;
        margin: 0 auto;
    }

    @media (max-width: 600px) {
        width: 200px;
        height: 200px;
        margin: 0 auto;
    }
`;

export const CcCircle2 = styled.div<CcCircle2Props>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 138px;
    height: 138px;
    background-color: ${(props) => props.$bgColor};
    border-radius: 50%;
`;

export const CcHeader = styled.div`
    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-Bold';

    @media (max-width: 1200px) {
        text-align: center;
    }
`;

interface CcTextProps {
    $colorContrastResult: COLOR_CONTRAST_RESULT;
}

export const CcText = styled.div<CcTextProps>`
    margin: 35px 0 0 0;

    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';

    span {
        font-family: 'Poppins-Bold';
        color: ${({ $colorContrastResult }) =>
            $colorContrastResult === COLOR_CONTRAST_RESULT.PASS
                ? 'green'
                : 'red'};
    }

    @media (max-width: 1200px) {
        text-align: center;
    }
`;

export const CcTextContainer = styled.div<CcCircle2Props>`
    width: 100%;
    padding: 10px 0;
    background-color: ${({ $bgColor }) => $bgColor};
`;

interface CcFontProps {
    $fontColor: string;
}

export const CcSmallText = styled.div<CcFontProps>`
    font-size: 16px;
    font-family: 'Poppins-Regular';
    color: ${({ $fontColor }) => $fontColor};
    text-align: center;
`;

export const CcLargeText = styled.div<CcFontProps>`
    margin-top: 10px;
    font-size: 32px;
    font-family: 'Poppins-Regular';
    color: ${({ $fontColor }) => $fontColor};
    text-align: center;
`;
