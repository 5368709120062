import { Outlet } from 'react-router-dom';
import { Navbar } from '@ui/components/Navbar';
import { Sidebar } from './components/Sidebar';
import * as Styles from './style';

export const SidebarLayout = () => {
    return (
        <div>
            <Styles.SblContainer>
                <div>
                    <Sidebar />
                </div>
                <div>
                    <Navbar />
                    <Styles.SblOutlet>
                        <Outlet />
                    </Styles.SblOutlet>
                </div>
            </Styles.SblContainer>
        </div>
    );
};
