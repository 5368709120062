import styled, { keyframes } from 'styled-components';

export const SpinAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
`;

export const BtnLoader = styled.div`
    width: 20px;
    height: 20px;
    border: 3px solid white;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-radius: 50%;
    animation: ${SpinAnimation} 700ms linear infinite;
`;
