import * as Styles from './style';

interface CustomRadioBtnProps {
    label: string;
    id: string;
    value: string;
    checked: boolean;
    handleChange: <t>(value: t) => void;
}

export const CustomRadioBtn = (props: CustomRadioBtnProps) => {
    const { label, id, checked, value, handleChange } = props;

    return (
        <Styles.CrbContainer>
            {label}
            <Styles.CrbInput
                type="radio"
                checked={checked}
                name="radio"
                id={id}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
            />
            <Styles.CrbCheckMark />
        </Styles.CrbContainer>
    );
};
