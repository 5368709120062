import * as Styles from './style';

export const Help = () => {
    return (
        <Styles.HelpPage>
            <Styles.HelpHeader>
                Steps to the get the css associated with your project
            </Styles.HelpHeader>

            <Styles.HelpList>
                <li>
                    get your project api key by visiting the project specific
                    page
                </li>
                <li>the api key is located at the bottom of the page</li>
                <li>
                    use your api key to fetch the css associated with your
                    project
                </li>
                <li>
                    access your true colors on this endpoint{' '}
                    <code>
                        https://api.onhue.com/api/css?locale=en-US&time=00:00&accessibility=True%20Color&apiKey=&quot;PROJECT_API_KEY&quot;
                    </code>
                </li>
                <li>
                    access your true colors on this endpoint{' '}
                    <code>
                        https://api.onhue.com/api/css?locale=en-US&time=00:00&accessibility=AA&apiKey=&quot;PROJECT_API_KEY&quot;
                    </code>
                </li>
                <li>
                    access your true colors on this endpoint{' '}
                    <code>
                        https://api.onhue.com/api/css?locale=en-US&time=00:00&accessibility=AAA&apiKey=&quot;PROJECT_API_KEY&quot;
                    </code>
                </li>

                <li>
                    insert all the css in the style tag inside the head
                    component
                </li>
                <li>attach the classnames to the html tags</li>
            </Styles.HelpList>
        </Styles.HelpPage>
    );
};
