import styled from 'styled-components';

export const CdContainer = styled.div`
    margin: 34px 0 0 0;
`;

export const CdList = styled.div`
    margin: 16px 0 0 0;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
`;

export const CdHeader = styled.div`
    font-size: 18px;
    font-family: 'Poppins-Bold';
    color: #2a2a30;
`;

export const CdSubHeader = styled.div`
    margin: 5px 0 0 0;
    font-size: 15px;
    font-family: 'Poppins-Regular';
    color: #2a2a30;
`;

interface CdItemProps {
    $isSelected: boolean;
}

export const CdItem = styled.div<CdItemProps>`
    cursor: pointer;
    padding: 7px 15px;
    font-size: 12px;
    font-family: 'Poppins-Regular';
    color: ${({ $isSelected }) => ($isSelected ? '#ffffff' : '#2a2a30')};
    background-color: ${({ $isSelected }) =>
        $isSelected ? '#2a2a30' : '#ffffff'};
    border: 1px solid #dce2ea;
    box-shadow: 5px 5px 10px 1px #dce2ea;
    border-radius: 50px;
    transition: 300ms all;
`;
