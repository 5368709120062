import { Project, CreateProject } from '@ui/types/Project';
import { Palette } from '@ui/types/Palette';
import {
    AddUserToProject,
    DeleteUserFromProject,
    UpdateProject,
} from '@ui/types/Project/Project';
import { Projects, ProjectUsers } from '@ui/types/types';
import { api } from './api';

export const projectApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getProjects: builder.query<Project[], void>({
            query: () => ({ url: `projects/getProjectsByUser` }),
            providesTags: (result = []) => [
                ...result.map(({ id }) => ({ type: 'Projects', id } as const)),
                { type: 'Projects', id: 'LIST' },
            ],
        }),
        getProjectById: builder.query<Projects, number>({
            query: (id) => ({ url: `projects/${id}` }),
            providesTags: (_project, _error, id) => [{ type: 'Projects', id }],
        }),
        getProjectPallets: builder.query<Palette[], number>({
            query: (id) => ({ url: `projects/${id}/palettes` }),
        }),
        addProject: builder.mutation<Project, CreateProject>({
            query: (project) => ({
                url: `projects/createProject`,
                method: 'POST',
                body: project,
            }),
            invalidatesTags: [{ type: 'Projects', id: 'LIST' }],
        }),
        updateProject: builder.mutation<void, UpdateProject>({
            query: (project) => ({
                url: `projects/updateProjectDetails`,
                method: 'PUT',
                body: project,
            }),
            invalidatesTags: (_, _error, project) => [
                { type: 'Projects', id: project.projectId },
            ],
        }),
        deleteProject: builder.mutation<void, number>({
            query: (id) => ({ url: `projects/${id}`, method: 'DELETE' }),
            invalidatesTags: (_, _error, id) => [
                { type: 'Projects', id },
                { type: 'Projects', id: 'LIST' },
            ],
        }),

        getProjectUser: builder.query<ProjectUsers[], string>({
            query: (id) => ({
                url: `projects/getProjectUsers?projectId=${id}`,
            }),
        }),

        addUserToProject: builder.mutation<void, AddUserToProject>({
            query: (projectUser) => ({
                url: `projects/createProjectUser`,
                method: 'POST',
                body: projectUser,
            }),
        }),

        deleteUserFromProject: builder.mutation<void, DeleteUserFromProject>({
            query: (projectUser) => ({
                url: `projects/deleteProjectUser`,
                method: 'DELETE',
                body: projectUser,
            }),
        }),
    }),
});

export const {
    useGetProjectsQuery,
    useGetProjectByIdQuery,
    useAddProjectMutation,
    useDeleteProjectMutation,
    useUpdateProjectMutation,
    useGetProjectPalletsQuery,
    useAddUserToProjectMutation,
    useGetProjectUserQuery,
    useDeleteUserFromProjectMutation,
} = projectApi;
