import { Color, ColorRulesParameter, CreateColor } from '@ui/types/Color';
import { AddColorFromStudio, UpdateColorFromStudio } from '@ui/types/types';
import { api } from './api';

export const colorApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getColors: builder.query<Color[], void>({
            query: () => ({ url: `colors` }),
            providesTags: (result = []) => [
                ...result.map(({ id }) => ({ type: 'Colors', id } as const)),
                { type: 'Colors', id: 'LIST' },
            ],
        }),
        getColorById: builder.query<Color, number>({
            query: (id) => ({ url: `colors/${id}` }),
            providesTags: (_hue, _error, id) => [{ type: 'Colors', id }],
        }),
        addColor: builder.mutation<Color, CreateColor>({
            query: (color) => ({
                url: `colors`,
                method: 'POST',
                body: color,
            }),
            invalidatesTags: (_, _error, color) => [
                { type: 'Colors', id: 'LIST' },
                { type: 'Hues', id: color.hueId },
            ],
        }),
        updateColor: builder.mutation<void, Color>({
            query: (color) => ({
                url: `colors/${color['id']}`,
                method: 'PUT',
                body: color,
            }),
            invalidatesTags: (_, _error, color) => [
                { type: 'Colors', id: color.id },
                { type: 'Hues', id: color.hueId },
            ],
        }),
        deleteColor: builder.mutation<void, number>({
            query: (id) => ({ url: `colors/${id}`, method: 'DELETE' }),
            invalidatesTags: (_, _error, id) => [
                { type: 'Colors', id },
                { type: 'Colors', id: 'LIST' },
            ],
        }),
        getColorsFromHue: builder.query<Color[], number>({
            query: (id) => ({ url: `hues/${id}/colors` }),
            providesTags: (result = []) => [
                ...result.map(({ id }) => ({ type: 'Colors', id } as const)),
                { type: 'Colors', id: 'LIST' },
            ],
        }),
        updateColorRules: builder.mutation<void, ColorRulesParameter>({
            query: ({ colorId, rules }) => ({
                url: `colors/${colorId}/save-rules`,
                method: 'PUT',
                body: rules,
            }),
            invalidatesTags: (_, _error, { colorId }) => [
                { type: 'Colors', colorId },
                { type: 'Colors', id: 'LIST' },
            ],
        }),
        addColorFromStudio: builder.mutation<Color, AddColorFromStudio>({
            query: (color) => ({
                url: `colors`,
                method: 'POST',
                body: color,
            }),
            invalidatesTags: (_, _error, color) => [
                { type: 'Colors', id: 'LIST' },
                { type: 'Hues', id: color.hueId },
            ],
        }),
        updateColorFromStudio: builder.mutation<void, UpdateColorFromStudio>({
            query: (color) => ({
                url: `colors/${color['id']}`,
                method: 'PUT',
                body: color,
            }),
            invalidatesTags: (_, _error, color) => [
                { type: 'Colors', id: color.id },
                { type: 'Hues', id: color.hueId },
            ],
        }),
    }),
});

export const {
    useGetColorsQuery,
    useGetColorByIdQuery,
    useAddColorMutation,
    useUpdateColorMutation,
    useDeleteColorMutation,
    useGetColorsFromHueQuery,
    useUpdateColorRulesMutation,
    useAddColorFromStudioMutation,
    useUpdateColorFromStudioMutation,
} = colorApi;
