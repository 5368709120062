import type { Color } from '@ui/types/Color';
import PlusIconBlack from '@ui/images/plus-icon-black.svg';
import RulesIconBlack from '@ui/images/rules-icon-black.svg';
import OnHueLogoRound from '@ui/images/onHueLogoRound.svg';
import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import { toggleSidebar } from '@ui/slices/appStateSlice';
import * as Styles from './style';

type HueMenuProps = {
    colors: Color[];
    onMenuChange: (colorId: string) => void;
};

const HueMenu = (props: HueMenuProps) => {
    const { colors, onMenuChange } = props;

    const { id } = useAppSelector((state) => state.colorStudio);

    const { showSideBar } = useAppSelector((state) => state.appState);

    const dispatch = useAppDispatch();

    const closeSideBar = () => {
        dispatch(toggleSidebar());
    };

    let sortedColors: Color[] = [];

    if (colors !== undefined) {
        sortedColors = colors.slice().sort((a, b) => {
            if (a.isDefault) {
                return -1;
            }

            return a.id - b.id;
        });
    }

    const renderColors = () => {
        if (Array.isArray(sortedColors)) {
            return sortedColors.map((v) => (
                <Styles.HmColorItem
                    key={v?.id}
                    onClick={() => {
                        onMenuChange(v?.id?.toString());
                        closeSideBar();
                    }}
                    $isSelected={v?.id === id}
                >
                    <div>
                        <Styles.HmColor $backgroundColor={v?.hex} />
                    </div>
                    <div>{v?.name}</div>
                </Styles.HmColorItem>
            ));
        }

        return null;
    };

    return (
        <Styles.HmContainer $showSideBar={showSideBar}>
            <Styles.HmLogoContainer to={'/dashboard'}>
                <img src={OnHueLogoRound} alt="on-hue" />
            </Styles.HmLogoContainer>
            <Styles.HmColorContainer>
                <Styles.HsmiColorList>{renderColors()}</Styles.HsmiColorList>

                <Styles.HmColorItem
                    onClick={() => {
                        onMenuChange('add');
                        closeSideBar();
                    }}
                    $isSelected={false}
                >
                    <div>
                        <Styles.HmAddColor>
                            <img src={PlusIconBlack} alt="plus" />
                        </Styles.HmAddColor>
                    </div>
                    <div>ADD COLOR</div>
                </Styles.HmColorItem>
            </Styles.HmColorContainer>
            <div>
                <Styles.HmRulesItem
                    onClick={() => {
                        onMenuChange('rules');
                        closeSideBar();
                    }}
                >
                    <div>
                        <Styles.HmAddColor>
                            <img src={RulesIconBlack} alt="rules" />
                        </Styles.HmAddColor>
                    </div>
                    <div>Rules</div>
                </Styles.HmRulesItem>
            </div>
        </Styles.HmContainer>
    );
};

export default HueMenu;
