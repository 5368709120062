import styled from 'styled-components';
import { Button } from '@ui/components/Button';

export const UpContainer = styled.div`
    padding: 20px 0;
    width: 500px;

    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const RpFormControl = styled.div``;

export const RpFormControl2 = styled.div`
    margin-top: 22px;
`;

export const RpFieldControl = styled.div`
    margin-top: 11px;
`;

export const RpLabel = styled.label`
    font: normal normal bold 16px/19px Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
`;

export const RpRegisterBtn = styled(Button)`
    margin-top: 21px;
`;
