import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { TextInput } from '@ui/components/TextInput';
import { usePasswordResetRequestMutation } from '@ui/services/auth';
import { BtnLoader } from '@ui/components/Loaders';

import { PasswordResetRequestData } from '@ui/types/LoginData';

import OnHueLogoRound from '@ui/images/onHueLogoRound.svg';
import RightArrow from '@ui/images/right-arrow-icon.svg';
import BlackLeftArrow from '@ui/images/black-left-arrow.svg';
import * as Styles from '../style';

interface ResetRequestFormInitiailValues {
    email: string;
}

const initialValues: ResetRequestFormInitiailValues = {
    email: '',
};

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Invalid email'),
});

interface ResetRequestFormProps {
    handleResetRequestSuccess: () => void;
}

export const ResetRequestForm = (props: ResetRequestFormProps) => {
    const { handleResetRequestSuccess } = props;

    const [attemptRequest, { isLoading }] = usePasswordResetRequestMutation();

    const handleSubmit = async (values: ResetRequestFormInitiailValues) => {
        try {
            const resetRequestData: PasswordResetRequestData = {
                emailId: values.email,
            };

            await attemptRequest(resetRequestData).unwrap();

            toast.success('Email sent');

            handleResetRequestSuccess();

            localStorage.setItem('recover-account-email', values.email);
        } catch (err) {
            console.log('err', err);
            toast.error('Something went wrong');
        }
    };

    return (
        <Styles.RfContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <div>
                            <Styles.RfLogo src={OnHueLogoRound} alt="onhue" />
                            <Styles.RfHeader>Forgot Password?</Styles.RfHeader>
                            <Styles.RfSubHeader>
                                Unlock Your Account, Rediscover Access through{' '}
                                <br /> Password Recovery
                            </Styles.RfSubHeader>

                            <Styles.RfFormContainer>
                                <Styles.RfFormControl>
                                    <Styles.RfLabel htmlFor="email">
                                        EMAIL
                                    </Styles.RfLabel>

                                    <Styles.RfFieldControl>
                                        <TextInput
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Enter Your Email ID"
                                            showIcon={true}
                                        />
                                    </Styles.RfFieldControl>

                                    <Styles.RfErrMsg>
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                        />
                                    </Styles.RfErrMsg>
                                </Styles.RfFormControl>
                            </Styles.RfFormContainer>

                            <Styles.RfSubmitBtn
                                type="submit"
                                isDisabled={isLoading}
                            >
                                {isLoading ? (
                                    <BtnLoader />
                                ) : (
                                    <>
                                        <div>Send Link</div>
                                        <img src={RightArrow} alt="arrow" />
                                    </>
                                )}
                            </Styles.RfSubmitBtn>
                        </div>

                        <Styles.RfReturnText to={'/'}>
                            <img src={BlackLeftArrow} alt="back" />
                            <span>Return to Sign in</span>
                        </Styles.RfReturnText>
                    </Form>
                )}
            </Formik>
        </Styles.RfContainer>
    );
};
