import styled from 'styled-components';

export const CdContainer = styled.div`
    width: 100%;
    border-radius: 2px;
    border: 1px solid #2a2a30;
    position: relative;
`;

export const CpTextBox = styled.div`
    height: 41px;
    padding: 10px 43px 10px 15px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    color: rgb(0, 0, 0);
    cursor: pointer;
`;

export const CpPlaceHolder = styled.div`
    font-family: 'Poppins-Medium';
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
`;

type CpArrowProps = {
    open: boolean;
};

export const CpArrow = styled.img<CpArrowProps>`
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%)
        ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
    width: 13px;
    height: 13px;
    object-fit: cover;
    transition: 300ms all;
`;

export const DpItemList = styled.div`
    position: absolute;
    top: 51px;
    left: 0;
    right: 0;
    max-height: 220px;
    border-radius: 2px;
    border: 1px solid #2a2a30;
    background-color: white;
    z-index: 200;
    overflow: auto;
`;

export const DpEmptyList = styled.div`
    position: absolute;
    top: 51px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid #2a2a30;
    background-color: white;
    z-index: 200;
`;

export const DpItemListItem = styled.div`
    cursor: pointer;
    height: 41px;
    padding: 10px 43px 10px 15px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    color: rgba(0, 0, 0);
    transition: 300ms all;

    &:hover {
        background: black;
        color: white;
    }
`;
