import * as Styles from './style';

export const ProjectItemLoader = () => {
    return (
        <Styles.PilContainer>
            <Styles.PilName />

            <Styles.PilApiKey />
        </Styles.PilContainer>
    );
};
