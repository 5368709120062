import { CustomColorPicker } from '@ui/components/CustomColorPicker';
import {
    ColorRepresentations,
    fromHex,
} from '@ui/components/ColorPicker/ColorRepresentations';
import { AddColorFromStudio, COLOR_CONTRAST_TYPES } from '@ui/types/types';

import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import toast from 'react-hot-toast';
import {
    useAddColorFromStudioMutation,
    useUpdateColorFromStudioMutation,
} from '@ui/services/color';
import { useParams } from 'react-router-dom';
import { BtnLoader } from '@ui/components/Loaders';
import {
    setAaColor,
    setAaaColor,
    setColorId,
    setGradientHex,
    setTrueColor,
} from '@ui/slices/colorStudioSlice';
import { useGetProfileQuery } from '@ui/services/profile';
import { EditHue } from '@ui/types/Hue';
import { useGetHueByIdQuery, useUpdateHueMutation } from '@ui/services/hue';
import * as Styles from './style';
import { ColorContrast } from '../ColorContrast';
import { ColorDescriptors } from '../ColorDescriptors';
import { ColorTags } from '../ColorTags';
import { ColorRationale } from '../ColorRationale';
import { ColorTypeChoice } from '../ColorTypeChoice';

export const TrueColor = () => {
    const { hueId, paletteId } = useParams();

    const {
        id,
        isDefault,
        name,
        trueColor,
        rationale,
        colorDescriptors,
        colorTags,
        aaColor,
        aaaColor,
        contrastColor,
        colorPlacement,
        aaHexOL,
        aaaHexOL,
        defaultColor,
        cssProperties,
        isGradient,
        gradientHex,
    } = useAppSelector((state) => state.colorStudio);

    const { data: profile } = useGetProfileQuery();

    const { data: hue } = useGetHueByIdQuery(parseInt(hueId!));

    const dispatch = useAppDispatch();

    const [addColorFromStudio, { isLoading: addColorLoading }] =
        useAddColorFromStudioMutation();

    const [updateColorFromStudio, { isLoading: updateColorLoading }] =
        useUpdateColorFromStudioMutation();

    const [updateHue, { isLoading: updateHueLoading }] = useUpdateHueMutation();

    const handleSaveColor = async () => {
        const colorArgs: AddColorFromStudio = {
            isDefault: isDefault,
            name: name,
            rationale: rationale,
            hex: trueColor.hex,
            hueId: parseInt(hueId!),
            colorDescriptors: colorDescriptors.toString(),
            colorTags: colorTags.toString(),
            aaHex: aaHexOL ? aaColor.hex : trueColor.hex,
            aaaHex: aaaHexOL ? aaaColor.hex : trueColor.hex,
            colorSetting: {
                aaHexOL: aaHexOL,
                aaaHexOL: aaaHexOL,
                colorPlacement: colorPlacement,
                defaultColor: defaultColor,
                contrastHex: contrastColor.hex,
            },
            userEmail: profile?.userEmail as string,
            isGradient: isGradient,
            gradientHex: gradientHex.hex,
        };

        const savingHue: EditHue = {
            id: parseInt(hueId!),
            name: hue?.name as string,
            paletteId: parseInt(paletteId!),
            cssProperties: cssProperties,
        };
        try {
            await updateHue(savingHue).unwrap();
        } catch (error) {
            toast.error(`Error saving hue`);
            return;
        }

        try {
            if (id === -1) {
                const addResponse = await addColorFromStudio(
                    colorArgs
                ).unwrap();

                toast.success('Color added');

                dispatch(setColorId(addResponse.id));
            } else {
                await updateColorFromStudio({
                    ...colorArgs,
                    id: id,
                }).unwrap();

                toast.success('Color Updated');
            }
        } catch {
            toast.error('Something went wrong');
        }
    };

    return (
        <Styles.TcPage>
            <Styles.TcContainer>
                <ColorTypeChoice />

                <Styles.TcContainer2>
                    <Styles.TcHeader>
                        {isGradient
                            ? 'GRADIENT START COLOR:'
                            : 'DEFAULT TRUE COLOR:'}
                    </Styles.TcHeader>

                    <Styles.TcLine />

                    <Styles.TcSubHeader>
                        This is the default color a user will see when they are
                        not in a particular locale or have not selected any
                        correction or optimization.
                    </Styles.TcSubHeader>

                    <Styles.TcColorPickerContainer>
                        <CustomColorPicker
                            color={trueColor}
                            handleColorChange={(
                                value: ColorRepresentations
                            ) => {
                                dispatch(setTrueColor(value));
                                dispatch(setAaColor(value));
                                dispatch(setAaaColor(value));
                            }}
                            showHue={true}
                            showResetBtn={true}
                            handleResetColor={() =>
                                dispatch(setTrueColor(fromHex('#000000')))
                            }
                        />
                    </Styles.TcColorPickerContainer>
                </Styles.TcContainer2>

                {isGradient && (
                    <Styles.TcContainer2>
                        <Styles.TcHeader>GRADIENT END COLOR:</Styles.TcHeader>

                        <Styles.TcLine />

                        <Styles.TcSubHeader>
                            Select linear gradient from css properties in color
                            settings tab
                        </Styles.TcSubHeader>

                        <Styles.TcColorPickerContainer>
                            <CustomColorPicker
                                color={gradientHex}
                                handleColorChange={(
                                    value: ColorRepresentations
                                ) => {
                                    dispatch(setGradientHex(value));
                                }}
                                showHue={true}
                                showResetBtn={true}
                                handleResetColor={() => {
                                    dispatch(
                                        setGradientHex(fromHex('#000000'))
                                    );
                                }}
                            />
                        </Styles.TcColorPickerContainer>
                    </Styles.TcContainer2>
                )}

                {isGradient && (
                    <Styles.GradientPreviewContainer>
                        <Styles.TcHeader>GRADIENT PREVIEW:</Styles.TcHeader>
                        <Styles.TcLine />
                        <Styles.GradientPreview
                            $startColor={trueColor.hex}
                            $endColor={gradientHex.hex}
                        />
                    </Styles.GradientPreviewContainer>
                )}

                <ColorRationale />

                <Styles.TcContainer3>
                    <Styles.TcDescriptors>
                        <ColorDescriptors />
                    </Styles.TcDescriptors>

                    <Styles.TcColorTagContainer>
                        <ColorTags />
                    </Styles.TcColorTagContainer>
                </Styles.TcContainer3>

                {aaHexOL && (
                    <Styles.TcContainer4>
                        <Styles.TcHeader>
                            DEFAULT TRUE COLOR AA OPTIMIZATION:
                        </Styles.TcHeader>

                        <Styles.TcLine />

                        <Styles.TcSubHeader>
                            Based on your settings, your true color will be in
                            the foreground in relation to the contrasting color.
                        </Styles.TcSubHeader>

                        <Styles.TcContainer2>
                            <ColorContrast
                                bgColor={aaColor}
                                contrastType={COLOR_CONTRAST_TYPES.AA}
                                contrastColor={contrastColor}
                                colorPlacement={colorPlacement}
                            />
                        </Styles.TcContainer2>

                        <Styles.TcResultDesc>
                            Based on the results above, adjust your color or
                            leave as is.
                        </Styles.TcResultDesc>

                        <Styles.TcColorPickerContainer2>
                            <CustomColorPicker
                                color={aaColor}
                                handleColorChange={(
                                    value: ColorRepresentations
                                ) => {
                                    dispatch(setAaColor(value));
                                }}
                                showHue={true}
                                showResetBtn={true}
                                handleResetColor={() =>
                                    dispatch(setAaColor(trueColor))
                                }
                            />
                        </Styles.TcColorPickerContainer2>
                    </Styles.TcContainer4>
                )}

                {aaaHexOL && (
                    <Styles.TcContainer5>
                        <Styles.TcHeader>
                            DEFAULT TRUE COLOR AAA OPTIMIZATION:
                        </Styles.TcHeader>

                        <Styles.TcLine />

                        <Styles.TcSubHeader>
                            Based on your settings, your true color will be in
                            the foreground in relation to the contrasting color.
                        </Styles.TcSubHeader>

                        <Styles.TcContainer2>
                            <ColorContrast
                                bgColor={aaaColor}
                                contrastType={COLOR_CONTRAST_TYPES.AAA}
                                contrastColor={contrastColor}
                                colorPlacement={colorPlacement}
                            />
                        </Styles.TcContainer2>

                        <Styles.TcResultDesc>
                            Based on the results above, adjust your color or
                            leave as is.
                        </Styles.TcResultDesc>

                        <Styles.TcColorPickerContainer2>
                            <CustomColorPicker
                                color={aaaColor}
                                handleColorChange={(
                                    value: ColorRepresentations
                                ) => {
                                    dispatch(setAaaColor(value));
                                }}
                                showHue={true}
                                showResetBtn={true}
                                handleResetColor={() =>
                                    dispatch(setAaaColor(trueColor))
                                }
                            />
                        </Styles.TcColorPickerContainer2>
                    </Styles.TcContainer5>
                )}

                <Styles.TcSaveBtnContainer>
                    <Styles.TcSaveBtn
                        onClick={handleSaveColor}
                        isDisabled={
                            addColorLoading ||
                            updateColorLoading ||
                            updateHueLoading
                        }
                    >
                        {addColorLoading ||
                        updateColorLoading ||
                        updateHueLoading ? (
                            <BtnLoader />
                        ) : (
                            'SAVE'
                        )}
                    </Styles.TcSaveBtn>
                </Styles.TcSaveBtnContainer>
            </Styles.TcContainer>
        </Styles.TcPage>
    );
};
