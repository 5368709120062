import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import { setColorName } from '@ui/slices/colorStudioSlice';
import * as Styles from './style';

export const ColorName = () => {
    const { name } = useAppSelector((state) => state.colorStudio);

    const dispatch = useAppDispatch();

    const [showInput, setShowInput] = useState(false);

    const renderContent = () => {
        if (showInput) {
            return (
                <Styles.CnEditNameInput>
                    <input
                        type="text"
                        value={name}
                        onBlur={() => setShowInput((v) => !v)}
                        onChange={(e) => dispatch(setColorName(e.target.value))}
                    />
                </Styles.CnEditNameInput>
            );
        } else {
            return (
                <>
                    <Styles.CnName>{name}:</Styles.CnName>
                    <Styles.CnEditText onClick={() => setShowInput((v) => !v)}>
                        Edit Color Name
                    </Styles.CnEditText>
                </>
            );
        }
    };

    return <Styles.CnContainer>{renderContent()}</Styles.CnContainer>;
};
