import OnHueLogoRound from '@ui/images/onHueLogoRound.svg';
import RightArrow from '@ui/images/right-arrow-icon.svg';
import BlackLeftArrow from '@ui/images/black-left-arrow.svg';
import * as Styles from '../style';

type ResetRequestSuccessProps = {
    handleGoToEditEmailStep: () => void;
};
export const ResetRequestSuccess = (props: ResetRequestSuccessProps) => {
    const { handleGoToEditEmailStep } = props;

    return (
        <Styles.RfContainer2>
            <Styles.RfLogo src={OnHueLogoRound} alt="onhue" />
            <Styles.RfHeader>Email Sent</Styles.RfHeader>
            <Styles.RfSubHeader>
                We have sent you mail reset link over your <br /> email, Please
                check to continue
            </Styles.RfSubHeader>

            <Styles.RfSubmitBtn type="button">
                <div>View Mail</div>
                <img src={RightArrow} alt="arrow" />
            </Styles.RfSubmitBtn>

            <Styles.RfReturnText2 onClick={handleGoToEditEmailStep}>
                <img src={BlackLeftArrow} alt="back" />
                <span>Return to Sign in</span>
            </Styles.RfReturnText2>
        </Styles.RfContainer2>
    );
};
