import styled from 'styled-components';

export const CtHeader = styled.div`
    font-size: 15px;
    color: #2a2a30;
    font-family: 'Poppins-Regular';
`;

export const CtContainer = styled.div`
    margin: 27px 0 0 0;
    display: grid;
    grid-template-columns: 360px minmax(0, 1fr);
    grid-gap: 50px;
    align-items: flex-start;

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
`;

export const CtForm = styled.div`
    button {
        display: none;
    }
`;

export const CtTagsList = styled.div`
    padding: 9px 0;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
`;

export const CtTagItem = styled.div`
    cursor: pointer;
    padding: 7px 15px;
    font-size: 12px;
    font-family: 'Poppins-Regular';
    color: #ffffff;
    background-color: #2a2a30;
    border: 1px solid #dce2ea;
    box-shadow: 5px 5px 10px 1px #dce2ea;
    border-radius: 50px;
    transition: 300ms all;
`;

export const CtError = styled.div`
    margin-top: 10px;
    color: #df27e8;
    margin-top: 8px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
`;
