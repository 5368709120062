import styled from 'styled-components';

export const PsContainer = styled.div`
    padding: 20px 0;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 25px;
`;
