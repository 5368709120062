import { Button } from '@ui/components/Button';
import styled from 'styled-components';

export const RtmContainer = styled.div`
    width: 600px;
    background-color: white;
    padding: 30px;

    @media (max-width: 700px) {
        width: 100%;
        padding: 30px 20px;
    }
`;

export const RtmHeader = styled.div`
    font-size: 24px;
    font-family: 'Poppins-Regular';
    color: #2a2a30;
`;

export const RtmLine = styled.div`
    margin: 10px 0;
    width: 100%;
    height: 1px;
    background-color: #707070;
`;

export const RtmConfirm = styled.div`
    font-size: 21px;
    font-family: 'Poppins-ExtraLight';
    color: #2a2a30;
`;

export const RtmName = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr);
    grid-gap: 20px;
    align-items: center;

    font-size: 21px;
    font-family: 'Poppins-ExtraLight';
    color: #2a2a30;

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        font-size: 14px;
    }
`;

export const RtmImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
`;

export const RtmBtns = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 60px 0 0 0;
`;

export const RtmBtn = styled(Button)`
    width: 220px;
    height: 56px;

    @media (max-width: 700px) {
        width: 100%;
    }
`;
