import { Formik, Form, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { TextInput } from '@ui/components/TextInput';
import * as Styles from './style';
import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import { addColorTag, removeColorTag } from '@ui/slices/colorStudioSlice';

interface TagData {
    tagvalue: string;
}

const initialValues: TagData = {
    tagvalue: '',
};

const validationSchema = Yup.object().shape({
    tagvalue: Yup.string().required('Tag name is required'),
});

export const ColorTags = () => {
    const { colorTags } = useAppSelector((state) => state.colorStudio);

    const dispatch = useAppDispatch();

    const handleSubmit = (
        values: TagData,
        formikHelpers: FormikHelpers<TagData>
    ) => {
        const { tagvalue } = values;

        if (!colorTags.includes(tagvalue)) {
            dispatch(addColorTag(values.tagvalue));

            formikHelpers.resetForm();
        } else {
            toast.error('Tag already added');
        }
    };

    return (
        <div>
            <Styles.CtHeader>
                We dont want to box you into those choices, so here you can add
                your own custom tags tied to this color:
            </Styles.CtHeader>
            <Styles.CtContainer>
                <Styles.CtForm>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {() => (
                            <Form>
                                <TextInput
                                    type="text"
                                    name="tagvalue"
                                    id="tagvalue"
                                    placeholder="Add a tag"
                                    showIcon={false}
                                />
                                <Styles.CtError>
                                    <ErrorMessage name="tagvalue" />
                                </Styles.CtError>
                                <button type="submit">submit</button>
                            </Form>
                        )}
                    </Formik>
                </Styles.CtForm>
                <Styles.CtTagsList>
                    {colorTags.map((v) => (
                        <Styles.CtTagItem
                            key={v}
                            onClick={() => dispatch(removeColorTag(v))}
                        >
                            {v}
                        </Styles.CtTagItem>
                    ))}
                </Styles.CtTagsList>
            </Styles.CtContainer>
        </div>
    );
};
