import { Button } from '@ui/components/Button';
import styled from 'styled-components';

export const ErrContainer = styled.div`
    display: grid;
    grid-template-columns: 60px minmax(0, 1fr) minmax(0, 2fr);
    grid-gap: 28px;
    align-items: center;
    margin-bottom: 30px;
`;

export const ErrColorDot = styled.div`
    border-radius: 50%;
    height: 60px;
    width: 60px;
`;

export const ErrColorNameContainer = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 1fr) 30px;
    grid-gap: 10px;
`;

export const ErrColorName = styled.div`
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    color: #2a2a30;
`;

export const ErrTrashIcon = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    font-size: 20px;
`;

export const ErrTagContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    flex-wrap: wrap;
`;

export const ErrInput = styled.div`
    width: 200px;
`;

export const ErrColorRule = styled.div`
    padding: 0 10px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #cccccc;
    background-color: #aaaaaa;
`;

export const ErrNewLocaleBtn = styled(Button)`
    width: 100px;
    height: 43px;
`;
