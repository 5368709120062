import styled from 'styled-components';
import { Button } from '@ui/components/Button';

export const AtmHeader = styled.div`
    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-Bold';
`;

export const AtmContainer = styled.div`
    margin-top: 13px;
    display: grid;
    grid-template-columns: 640px 220px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1250px) {
        grid-template-columns: 400px 220px;
    }

    @media (max-width: 800px) {
        grid-template-columns: minmax(0, 1fr) 200px;
        grid-gap: 20px;
    }

    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .text-input-container {
            width: 100%;
        }
    }
`;

export const AtmErrMsg = styled.div`
    color: #df27e8;
    margin-top: 8px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
`;

export const AtmSubmitBtn = styled(Button)`
    height: 56px;
`;
