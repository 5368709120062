import { useGetSubscriptionPlansQuery } from '@ui/services/subscription';
import { SubscriptionPlan } from '../SubscriptionPlan';
import * as Styles from './style';

export const Subscription = () => {
    const { data: subscriptionPlans } = useGetSubscriptionPlansQuery();

    const handleBuy = (planName: string) => {
        console.log('planName ', planName);
    };

    const renderSubscriptionPlans = () => {
        if (Array.isArray(subscriptionPlans)) {
            return subscriptionPlans.map((v, k) => (
                <SubscriptionPlan
                    key={k}
                    planName={v?.planName}
                    planPrice={v?.planPrice}
                    isPlanAvailable={v?.isPlanAvailable}
                    features={v?.features}
                    handleBuy={handleBuy}
                />
            ));
        }

        return null;
    };

    return <Styles.PsContainer>{renderSubscriptionPlans()}</Styles.PsContainer>;
};
