import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@ui/app/store';

interface HueDetails {
    id: number;
    name: string;
    projectId: number;
    paletteId: number;
    cssProperties: string[];
}

const initialState: HueDetails = {
    id: -1,
    name: '',
    projectId: -1,
    paletteId: -1,
    cssProperties: [],
};

export const hueSlice = createSlice({
    name: 'hue',
    initialState,
    reducers: {
        resetHue: () => {
            return { ...initialState };
        },
        setHue: (_, action) => {
            return { ...action.payload };
        },
        setHueId: (state, action) => {
            state.id = action.payload;
        },
        setHueName: (state, action) => {
            state.name = action.payload;
        },
        setProjectId: (state, action) => {
            state.projectId = action.payload;
        },
        setPaletteId: (state, action) => {
            state.paletteId = action.payload;
        },
        setHueCssProperties: (state, action) => {
            state.cssProperties = action.payload;
        },
    },
});

export const {
    resetHue,
    setHue,
    setHueId,
    setHueName,
    setProjectId,
    setPaletteId,
    setHueCssProperties,
} = hueSlice.actions;

export const selectHue = (state: RootState) => state.hue;
export const selectHueId = (state: RootState) => state.hue.id;

export default hueSlice.reducer;
