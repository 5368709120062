export enum RESET_PASSWORD_REQUEST_STEPS {
    REQUEST = 'REQUEST',
    SUCCESS = 'SUCCESS',
}

export type DropdownOptions = {
    name: string;
    value: string | number;
};

export type ProjectUsers = {
    email: string;
    firstName: string;
    lastName: string;
    projectId: number;
    userId: string;
    userProfileImagePath: string;
};

export enum COLOR_CONTRAST_TYPES {
    AA = 'AA',
    AAA = 'AAA',
}

export enum COLOR_SETTINGS_TABS {
    SETTINGS = 'SETTINGS',
    TRUE_COLOR = 'TRUE_COLOR',
}

export enum COLOR_CONTRAST_RESULT {
    PASS = 'PASS',
    FAIL = 'FAIL',
}

export enum COLOR_PLACEMENT {
    BACKGROUND = 'BACKGROUND',
    FOREGROUND = 'FOREGROUND',
}

export enum DEFAULT_COLOR {
    TRUE_COLOR = 'TRUE COLOR',
    AA = 'AA',
    AAA = 'AAA',
}

export type UserNotification = {
    createdDt: string;
    isRead: boolean;
    notificationMessage: string;
    userEmail: string;
};

export enum TOGGLE_VALUES {
    ON = 'ON',
    OFF = 'OFF',
}

export type AddColorSettings = {
    aaHexOL: boolean;
    aaaHexOL: boolean;
    colorPlacement: string;
    defaultColor: string;
    contrastHex: string;
};

export type AddColorFromStudio = {
    isDefault: boolean;
    name: string;
    rationale: string;
    hueId: number;
    hex: string;
    colorDescriptors: string;
    colorTags: string;
    aaHex: string;
    aaaHex: string;
    colorSetting: AddColorSettings;
    userEmail: string;
    isGradient: boolean;
    gradientHex: string;
};

export type UpdateColorFromStudio = {
    isDefault: boolean;
    name: string;
    rationale: string;
    hueId: number;
    hex: string;
    aaHex: string;
    aaaHex: string;
    id: number;
};

export type UserCreatedHueProject = {
    id: number;
    name: string;
    isAdmin: boolean;
};

export type UserCreatedHuePalette = {
    id: number;
    name: string;
};

export type UserCreatedHueColors = {
    id: number;
    name: string;
    hex: string;
    isDefault: boolean;
};

export type UserCreatedHue = {
    id: number;
    name: string;
    cssProperties: string;
    projectDetails: UserCreatedHueProject;
    paletteDetails: UserCreatedHuePalette;
    hueColors: UserCreatedHueColors[];
};

export type PaletteHues = {
    colors: UserCreatedHueColors[];
    name: string;
    id: string;
};

export const ROUTES = {
    ROOT: '/',
    SIGN_UP: '/sign-up',
    FORGOT_PASSWORD: '/forgot-password',
    PASSWORD_RESET: '/password-reset',
    DASHBOARD: '/dashboard',
    CREATE_HUE: '/new',
    PROFILE: '/profile',
    TEAM: '/team',
    SUBSCRIPTION: '/subscription',
    PROJECT: '/project',
    NOTIFICATIONS: '/notifications',
    DEMO: '/demo',
    COLOR_STUDIO: '/color',
    CREATE_PROJECT: '/create-project',
    HELP: '/help',
};

export type Projects = {
    createdDt: string;
    id: number;
    isAdmin: boolean;
    name: string;
    apiKey: string | null;
};

export type CreateUserSubscription = {
    subscriptionPlanId: number;
};
