import { UserNotification } from '@ui/types/types';
import { api } from './api';

export const notificationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUserNotifications: builder.query<UserNotification[], void>({
            query: () => ({
                url: 'notifications/getNotificationsByUser',
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetUserNotificationsQuery } = notificationApi;
