import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import listenerMiddleware from './listenerMiddleware';
import { api } from '@ui/services/api';
import authReducer from '@ui/slices/authSlice';
import colorReducer from '@ui/slices/colorSlice';
import colorListReducer from '@ui/slices/colorListSlice';
import hueReducer from '@ui/slices/hueSlice';
import newHueReducer from '@ui/slices/newHueSlice';
import AppStateReucer from '@ui/slices/appStateSlice';
import colorStudioSlice from '@ui/slices/colorStudioSlice';

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: authReducer,
        hue: hueReducer,
        color: colorReducer,
        colorList: colorListReducer,
        newHue: newHueReducer,
        appState: AppStateReucer,
        colorStudio: colorStudioSlice,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat([api.middleware])
            .prepend(listenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
