import * as Styles from '../../style';
import { ResetPasswordForm } from '../../components/ResetPasswordForm';

export const ResetPassword = () => {
    return (
        <Styles.FpPage>
            <Styles.FpContainer>
                <ResetPasswordForm />
                {/* <Styles.RpForm>
                    <Styles.RpLogoContainer>
                        <Styles.RpLogo alt="onHue-logo" src={OnHueLogo} />
                    </Styles.RpLogoContainer>
                    <Styles.RpFormContainer>
                        <Styles.RpWrapper>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {() => (
                                    <Form>
                                        <Styles.RpFormControl>
                                            <Styles.RpLabel htmlFor="email">
                                                EMAIL
                                            </Styles.RpLabel>

                                            <Styles.RpFieldControl>
                                                <TextInput
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                />
                                            </Styles.RpFieldControl>

                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                            />
                                        </Styles.RpFormControl>

                                        <Styles.RpFormControl2>
                                            <Styles.RpLabel htmlFor="password">
                                                PASSWORD
                                            </Styles.RpLabel>

                                            <Styles.RpFieldControl>
                                                <TextInput
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                />
                                            </Styles.RpFieldControl>

                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                            />
                                        </Styles.RpFormControl2>

                                        <Styles.RpFormControl2>
                                            <Styles.RpLabel htmlFor="confirmPassword">
                                                CONFIRM PASSWORD
                                            </Styles.RpLabel>

                                            <Styles.RpFieldControl>
                                                <TextInput
                                                    type="password"
                                                    name="confirmPassword"
                                                    id="confirmPassword"
                                                />
                                            </Styles.RpFieldControl>

                                            <ErrorMessage
                                                name="confirmPassword"
                                                component="div"
                                            />
                                        </Styles.RpFormControl2>

                                        <Styles.RpRegisterBtn type="submit">
                                            {isLoading ? (
                                                <BtnLoader />
                                            ) : (
                                                'Reset'
                                            )}
                                        </Styles.RpRegisterBtn>
                                    </Form>
                                )}
                            </Formik>
                        </Styles.RpWrapper>
                    </Styles.RpFormContainer>
                </Styles.RpForm> */}
            </Styles.FpContainer>
        </Styles.FpPage>
    );
};
