import { useParams } from 'react-router-dom';
import { useGetHueByIdQuery } from '@ui/services/hue';
import * as Styles from './style';

export const ColorStudioHeader = () => {
    const { hueId } = useParams();

    const { data: hue } = useGetHueByIdQuery(parseInt(hueId as string));

    return (
        <Styles.CshContainer>
            Color Studio:
            <Styles.CshTextContainer>
                <Styles.CshText>{hue?.palette?.project?.name}</Styles.CshText>
                <Styles.CshText>{hue?.palette?.name}</Styles.CshText>
                <Styles.CshText>{hue?.name}</Styles.CshText>
            </Styles.CshTextContainer>
        </Styles.CshContainer>
    );
};
