import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

import { RootState } from '@ui/app/store';
import { authLogin, authLogout } from '@ui/slices/authSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/`,

    // add authorization header to all queries
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token;

        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }

        return headers;
    },
});

// create new base query class that extends base query
const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    // attempt to complete request
    let result = await baseQuery(args, api, extraOptions);

    // error recieved, jwt expired
    if (result.error && result.error.status === 401) {
        // attempt to request a new token using old jwt and access token
        const refreshResult = await baseQuery(
            {
                url: 'users/refresh',
                method: 'POST',
                body: {
                    jwtAccessToken: localStorage.getItem('token'),
                    refreshToken: localStorage.getItem('refresh'),
                    refreshTokenExpiryDate: localStorage.getItem('refreshTime'),
                },
            },
            api,
            extraOptions
        );

        console.log('refreshResult ', refreshResult);

        // if successful add to store and local storage
        if (refreshResult.data) {
            api.dispatch(authLogin(refreshResult.data));

            // retry request with new token
            result = await baseQuery(args, api, extraOptions);
        }
        // if refresh token expired, remove from store and local storage
        else {
            api.dispatch(authLogout());
        }
    }
    return result;
};

export default baseQueryWithReauth;
