import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    ColorRepresentations,
    fromHex,
} from '@ui/components/ColorPicker/ColorRepresentations';
import { COLOR_PLACEMENT, DEFAULT_COLOR } from '@ui/types/types';

interface ColorStudio {
    id: number;
    isDefault: boolean;
    name: string;
    rationale: string;
    trueColor: ColorRepresentations;
    hueId: number;
    colorDescriptors: string[];
    colorTags: string[];
    aaColor: ColorRepresentations;
    aaaColor: ColorRepresentations;
    aaHexOL: boolean;
    aaaHexOL: boolean;
    colorPlacement: COLOR_PLACEMENT;
    contrastColor: ColorRepresentations;
    defaultColor: DEFAULT_COLOR;
    isGradient: boolean;
    gradientHex: ColorRepresentations;
}

interface HueCssProperties {
    cssProperties: string[];
}

const initialState: ColorStudio & HueCssProperties = {
    id: -1,
    hueId: 0,
    isDefault: false,
    trueColor: fromHex('#000000'),
    name: 'New Color',
    rationale: '',
    colorDescriptors: [],
    colorTags: [],
    aaColor: fromHex('#000000'),
    aaaColor: fromHex('#000000'),
    aaHexOL: false,
    aaaHexOL: false,
    colorPlacement: COLOR_PLACEMENT.FOREGROUND,
    contrastColor: fromHex('#F1F67B'),
    defaultColor: DEFAULT_COLOR.TRUE_COLOR,
    cssProperties: [],
    isGradient: false,
    gradientHex: fromHex('#000000'),
};

export const colorStudioSlice = createSlice({
    name: 'colorStudio',
    initialState,
    reducers: {
        resetColorStudio: () => {
            return { ...initialState };
        },
        setColorStudio: (state, action: PayloadAction<ColorStudio>) => {
            state.id = action.payload.id;
            state.hueId = action.payload.hueId;
            state.isDefault = action.payload.isDefault;
            state.trueColor = action.payload.trueColor;
            state.name = action.payload.name;
            state.rationale = action.payload.rationale;
            state.colorDescriptors = action.payload.colorDescriptors;
            state.colorTags = action.payload.colorTags;
            state.aaColor = action.payload.aaColor;
            state.aaaColor = action.payload.aaaColor;
            state.aaHexOL = action.payload.aaHexOL;
            state.aaaHexOL = action.payload.aaaHexOL;
            state.colorPlacement = action.payload.colorPlacement;
            state.contrastColor = action.payload.contrastColor;
            state.defaultColor = action.payload.defaultColor;
            state.isGradient = action.payload.isGradient;
            state.gradientHex = action.payload.gradientHex;
        },
        setTrueColor: (state, action: PayloadAction<ColorRepresentations>) => {
            state.trueColor = action.payload;
        },
        setColorName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        setColorRationale: (state, action: PayloadAction<string>) => {
            state.rationale = action.payload;
        },
        handleColorDescriptor: (state, action: PayloadAction<string>) => {
            if (state.colorDescriptors.includes(action.payload)) {
                state.colorDescriptors = state.colorDescriptors.filter(
                    (v) => v !== action.payload
                );
            } else {
                state.colorDescriptors = [
                    ...state.colorDescriptors,
                    action.payload,
                ];
            }
        },
        addColorTag: (state, action: PayloadAction<string>) => {
            state.colorTags = [...state.colorTags, action.payload];
        },
        removeColorTag: (state, action: PayloadAction<string>) => {
            state.colorTags = state.colorTags.filter(
                (v) => v !== action.payload
            );
        },
        setAaColor: (state, action: PayloadAction<ColorRepresentations>) => {
            state.aaColor = action.payload;
        },
        setAaaColor: (state, action: PayloadAction<ColorRepresentations>) => {
            state.aaaColor = action.payload;
        },
        setContrastColor: (
            state,
            action: PayloadAction<ColorRepresentations>
        ) => {
            state.contrastColor = action.payload;
        },
        handleColorPlacement: (
            state,
            action: PayloadAction<COLOR_PLACEMENT>
        ) => {
            state.colorPlacement = action.payload;
        },
        toggleAaHexOl: (state, action: PayloadAction<boolean>) => {
            state.aaHexOL = action.payload;
        },
        toggleAaaHexOl: (state, action: PayloadAction<boolean>) => {
            state.aaaHexOL = action.payload;
        },
        setCssProperty: (
            state,
            action: PayloadAction<string[] | undefined>
        ) => {
            if (Array.isArray(action.payload)) {
                state.cssProperties = action.payload;
            }
        },
        addCssProperty: (state, action: PayloadAction<string>) => {
            state.cssProperties = [...state.cssProperties, action.payload];
        },
        removeCssProperty: (state, action: PayloadAction<string>) => {
            state.cssProperties = state.cssProperties.filter(
                (v) => v !== action.payload
            );
        },
        toggleIsGradient: (state) => {
            state.isGradient = !state.isGradient;
        },
        setGradientHex: (
            state,
            action: PayloadAction<ColorRepresentations>
        ) => {
            state.gradientHex = action.payload;
        },
        setColorId: (state, action: PayloadAction<number>) => {
            state.id = action.payload;
        },
    },
});

export const {
    resetColorStudio,
    setColorStudio,
    setTrueColor,
    setColorName,
    setColorRationale,
    handleColorDescriptor,
    addColorTag,
    removeColorTag,
    setAaColor,
    setAaaColor,
    setContrastColor,
    handleColorPlacement,
    toggleAaHexOl,
    toggleAaaHexOl,
    setCssProperty,
    addCssProperty,
    removeCssProperty,
    toggleIsGradient,
    setGradientHex,
    setColorId,
} = colorStudioSlice.actions;

export default colorStudioSlice.reducer;
