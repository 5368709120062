import { Link } from 'react-router-dom';
import styled from 'styled-components';

type SbWrapperProps = {
    $showSideBar: boolean;
};

export const HmContainer = styled.div<SbWrapperProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 220px;
    height: 100vh;
    background-color: #e3e3e3;
    z-index: 200;
    transition: 300ms all;

    @media (max-width: 1000px) {
        width: 100%;
        transform: ${({ $showSideBar }) =>
            $showSideBar ? 'translateX(0)' : 'translateX(-100%)'};
    }
`;

export const HmLogoContainer = styled(Link)`
    width: 100%;
    padding: 24px 0 34px 17px;
    display: inline-block;
    cursor: pointer;
    transition: 300ms all;

    &:hover {
        background-color: #f6f6f6;
    }

    img {
        width: 54px;
        height: 54px;
        object-fit: cover;
        border: 3px solid rgba(255, 255, 255, 0.5);
        border-radius: 50%;
    }
`;

export const HsmiColorList = styled.div`
    max-height: 240px;
    overflow-y: auto;
`;

interface HmColorItemProps {
    $isSelected: boolean;
}

export const HmColorItem = styled.div<HmColorItemProps>`
    cursor: pointer;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: 40px minmax(0, 1fr);
    grid-gap: 20px;
    align-items: center;
    background-color: ${({ $isSelected }) =>
        $isSelected ? '#e3e3e3' : 'transparent'};
    transition: 300ms all;

    &:hover {
        background-color: #e3e3e3;
    }
`;

export const HmRulesItem = styled.div`
    cursor: pointer;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: 40px minmax(0, 1fr);
    grid-gap: 20px;
    background-color: #e3e3e3;
    align-items: center;
    transition: 300ms all;

    &:hover {
        background-color: #f6f6f6;
    }
`;

type HmColorProps = {
    $backgroundColor: string;
};

export const HmColor = styled.div<HmColorProps>`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const HmColorContainer = styled.div`
    background-color: #f6f6f6;
    border-bottom: 1px solid #707070;
`;

export const HmAddColor = styled.div`
    width: 40px;
    height: 40px;
    border: 1px solid #2a2a30;
    border-radius: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
`;
