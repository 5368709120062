import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@ui/app/store';
import { Color } from '@ui/types/Color';

const initialState: Color[] = [];

export const colorListSlice = createSlice({
    name: 'colorList',
    initialState,
    reducers: {
        resetColors: () => {
            return [...initialState];
        },
        setColors: (_, action) => {
            return [...action.payload];
        },
        addColorLocaleRule: (state, action) => {
            console.log(action.payload);
            const { colorId, locale } = action.payload;
            const color = state.find((c) => c.id == colorId);

            if (!color) {
                return;
            }

            if (!color.rules?.some((r) => r.locale == locale)) {
                color.rules!.push({ colorId, locale, id: -1 });
            }
        },
        removeColorLocaleRule: (state, action) => {
            console.log(action.payload);
            const { colorId, locale } = action.payload;
            const color = state.find((c) => c.id == colorId);

            if (!color) {
                return;
            }

            if (color.rules) {
                const rule = color.rules.find((r) => r.locale == locale);
                if (rule) {
                    const ruleIndex = color.rules.indexOf(rule);
                    color.rules.splice(ruleIndex, 1);
                }
            }
        },
    },
});

export const {
    resetColors,
    setColors,
    addColorLocaleRule,
    removeColorLocaleRule,
} = colorListSlice.actions;

export const selectColorList = (state: RootState) => state.colorList;

export default colorListSlice.reducer;
