import styled from 'styled-components';
import { Saturation, Hue } from '@uiw/react-color';
import { SecondaryButton } from '../Button';

export const CcpContainer = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 1fr) 260px;
    grid-gap: 30px;

    @media (max-width: 1300px) {
        display: grid;
        grid-template-columns: 1fr;
    }
`;

export const CcpWrapper = styled.div`
    display: grid;
    grid-template-columns: 200px minmax(0, 1fr);

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
`;

type CcpColorPreviewProps = {
    $bgColor: string;
};

export const CcpColorPreview = styled.div<CcpColorPreviewProps>`
    width: 100%;
    height: 300px;
    border-radius: 8px 0 0 8px;
    background-color: ${({ $bgColor }) => $bgColor};

    @media (max-width: 700px) {
        height: 100px;
        border-radius: 8px 8px 0px 0px;
    }
`;

export const CcpSaturation = styled(Saturation)`
    width: 100% !important;
    height: 300px !important;
    border-radius: 0 8px 8px 0 !important;

    @media (max-width: 700px) {
        height: 600px;
        border-radius: 0px 0px 8px 8px !important;
    }
`;

export const CcpHue = styled(Hue)`
    margin: 58px 0 0 0 !important;
    width: 100% !important;
    height: 20px !important;

    div:first-of-type {
        border-radius: 8px !important;
    }
`;

export const CcpHeader = styled.div`
    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-Bold';
`;

export const CcpInput = styled.div`
    margin-top: 4px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    input {
        width: 100%;
        height: 50px;
        border: 1px solid #999999;
        border-radius: 3px;
        color: #999999;
        font-size: 16px;
        font-family: 'Poppins-Regular';
        text-align: center;

        &:focus {
            border-color: #4096ff;
            box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
            outline: 0;
        }
    }
`;

export const CcpRgbContainer = styled.div`
    margin-top: 28px;
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const CcpHsvaContainer = styled(CcpRgbContainer)`
    margin-top: 24px;
`;

export const CcbRgbHeader = styled.div`
    font-size: 18px;
    color: #2a2a30;
    font-family: 'Poppins-Bold';
    text-align: center;
`;

export const CcpRgbInput = styled(CcpInput)`
    width: 50px;
    height: 50px;
    border: 1px solid #999999;
    border-radius: 3px;
    color: #999999;
`;

export const ResetButton = styled(SecondaryButton)`
    width: 140px;
    height: 34px;
    margin: 30px 0 0 0;
`;

export const CcpError = styled.div`
    color: #df27e8;
    margin-top: 8px;
    font-family: 'Poppins-Medium';
    font-size: 14px;
`;
