import styled from 'styled-components';

export const PtContainer = styled.div`
    display: flex;
    align-items: center;
`;

interface PtItemProps {
    selected: boolean;
}

export const PtItem = styled.div<PtItemProps>`
    cursor: pointer;
    padding: 20px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${({ selected }) =>
        selected ? '#2a2a30' : 'transparent'};
`;
