import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@ui/app/store';

interface AuthState {
    token: string | null;
    refresh: string | null;
    refreshTime: string | null;
    email: string;
}

const initialState: AuthState = {
    token: localStorage.getItem('token'),
    refresh: localStorage.getItem('refresh'),
    refreshTime: localStorage.getItem('refreshTime'),
    email: localStorage.getItem('email') ?? '',
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authLogin: (state, action) => {
            state.token = action.payload.jwtAccessToken;
            state.refresh = action.payload.refreshToken;
            state.refreshTime = action.payload.refreshTokenExpiryDate;
            state.email = action.payload.email;
        },
        authLogout: (state) => {
            state.token = null;
            state.refresh = null;
            state.refreshTime = null;
            state.email = '';
        },
    },
});

export const { authLogin, authLogout } = authSlice.actions;

export const selectToken = (state: RootState) => state.auth.token;
export const selectRefresh = (state: RootState) => state.auth.refresh;
export const selectRefreshTime = (state: RootState) => state.auth.refreshTime;
export const selectUserEmail = (state: RootState) => state.auth.email;

export default authSlice.reducer;
