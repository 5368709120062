import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import { handleColorDescriptor } from '@ui/slices/colorStudioSlice';
import * as Styles from './style';

export const ColorDescriptors = () => {
    const { colorDescriptors } = useAppSelector((state) => state.colorStudio);

    const dispatch = useAppDispatch();

    return (
        <Styles.CdContainer>
            <Styles.CdHeader>DESCRIPTORS:</Styles.CdHeader>

            <Styles.CdSubHeader>
                Psychologists have identified 27 different categories of
                emotions that humans may experience. Select which ones you would
                like your color to represent (there can be multiple emotions at
                any given time):
            </Styles.CdSubHeader>
            <Styles.CdList>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Admiration')}
                    onClick={() =>
                        dispatch(handleColorDescriptor('Admiration'))
                    }
                >
                    Admiration
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Adoration')}
                    onClick={() => dispatch(handleColorDescriptor('Adoration'))}
                >
                    Adoration
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes(
                        'Aesthetic Appreciation'
                    )}
                    onClick={() =>
                        dispatch(
                            handleColorDescriptor('Aesthetic Appreciation')
                        )
                    }
                >
                    Aesthetic Appreciation
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Amusement')}
                    onClick={() => dispatch(handleColorDescriptor('Amusement'))}
                >
                    Amusement
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Anxiety')}
                    onClick={() => dispatch(handleColorDescriptor('Anxiety'))}
                >
                    Anxiety
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Awe')}
                    onClick={() => dispatch(handleColorDescriptor('Awe'))}
                >
                    Awe
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Awkwardness')}
                    onClick={() =>
                        dispatch(handleColorDescriptor('Awkwardness'))
                    }
                >
                    Awkwardness
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Boredom')}
                    onClick={() => dispatch(handleColorDescriptor('Boredom'))}
                >
                    Boredom
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Calmness')}
                    onClick={() => dispatch(handleColorDescriptor('Calmness'))}
                >
                    Calmness
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Confusion')}
                    onClick={() => dispatch(handleColorDescriptor('Confusion'))}
                >
                    Confusion
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Craving')}
                    onClick={() => dispatch(handleColorDescriptor('Craving'))}
                >
                    Craving
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Disgust')}
                    onClick={() => dispatch(handleColorDescriptor('Disgust'))}
                >
                    Disgust
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Empathetic Pain')}
                    onClick={() =>
                        dispatch(handleColorDescriptor('Empathetic Pain'))
                    }
                >
                    Empathetic Pain
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Entrancement')}
                    onClick={() =>
                        dispatch(handleColorDescriptor('Entrancement'))
                    }
                >
                    Entrancement
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Envy')}
                    onClick={() => dispatch(handleColorDescriptor('Envy'))}
                >
                    Envy
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Excitement')}
                    onClick={() =>
                        dispatch(handleColorDescriptor('Excitement'))
                    }
                >
                    Excitement
                </Styles.CdItem>

                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Fear')}
                    onClick={() => dispatch(handleColorDescriptor('Fear'))}
                >
                    Fear
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Horror')}
                    onClick={() => dispatch(handleColorDescriptor('Horror'))}
                >
                    Horror
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Interest')}
                    onClick={() => dispatch(handleColorDescriptor('Interest'))}
                >
                    Interest
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Joy')}
                    onClick={() => dispatch(handleColorDescriptor('Joy'))}
                >
                    Joy
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Nostalgia')}
                    onClick={() => dispatch(handleColorDescriptor('Nostalgia'))}
                >
                    Nostalgia
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Romance')}
                    onClick={() => dispatch(handleColorDescriptor('Romance'))}
                >
                    Romance
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Sadness')}
                    onClick={() => dispatch(handleColorDescriptor('Sadness'))}
                >
                    Sadness
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Satisfaction')}
                    onClick={() =>
                        dispatch(handleColorDescriptor('Satisfaction'))
                    }
                >
                    Satisfaction
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Sexual Desire')}
                    onClick={() =>
                        dispatch(handleColorDescriptor('Sexual Desire'))
                    }
                >
                    Sexual Desire
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Sympathy')}
                    onClick={() => dispatch(handleColorDescriptor('Sympathy'))}
                >
                    Sympathy
                </Styles.CdItem>
                <Styles.CdItem
                    $isSelected={colorDescriptors.includes('Triumph')}
                    onClick={() => dispatch(handleColorDescriptor('Triumph'))}
                >
                    Triumph
                </Styles.CdItem>
            </Styles.CdList>
        </Styles.CdContainer>
    );
};
