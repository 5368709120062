import { createListenerMiddleware } from '@reduxjs/toolkit';
import { api } from '@ui/services/api';
import { authLogin, authLogout } from '@ui/slices/authSlice';

const listenerMiddleware = createListenerMiddleware();

// listenerMiddleware is used to update the
// local storage when certain actions are called

// it acts very similar to a persistant store

listenerMiddleware.startListening({
    actionCreator: authLogin,
    effect: async (action) => {
        localStorage.setItem('token', action.payload.jwtAccessToken);
        localStorage.setItem('refresh', action.payload.refreshToken);
        localStorage.setItem(
            'refreshTime',
            action.payload.refreshTokenExpiryDate
        );
        localStorage.setItem('email', action.payload.email);
    },
});

listenerMiddleware.startListening({
    actionCreator: authLogout,
    effect: async (_, listnerApi) => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        localStorage.removeItem('refreshTime');
        localStorage.removeItem('email');
        listnerApi.dispatch(api.util.resetApiState());
    },
});

export default listenerMiddleware;
