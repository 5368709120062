import toast from 'react-hot-toast';
import { useDeleteColorMutation } from '@ui/services/color';
import { BtnLoader } from '@ui/components/Loaders';
import * as Styles from './style';

interface ColorDeletionProps {
    colorId: number;
    colorName: string;
    colorHex: string;
    handleClose: () => void;
}

export const ColorDeletion = (props: ColorDeletionProps) => {
    const { colorId, colorName, colorHex, handleClose } = props;

    const [handleDelete, { isLoading }] = useDeleteColorMutation();

    const handleConfirm = async () => {
        try {
            if (colorId !== -1) {
                await handleDelete(colorId).unwrap();

                toast.success('Color deleted successfully');
                handleClose();
            } else {
                toast.error('Invalid color id');
            }
        } catch {
            toast.error('Something went wrong');
        }
    };

    return (
        <Styles.CdContainer>
            <Styles.CdHeader>
                Are you sure you want to delete this color ?
            </Styles.CdHeader>

            <Styles.CdInfo>
                <Styles.CdColor $backgroundColor={colorHex}></Styles.CdColor>
                <Styles.CdName>{colorName}</Styles.CdName>
            </Styles.CdInfo>

            <Styles.CdBtns>
                <Styles.CdCancelBtn onClick={handleClose}>
                    Cancel
                </Styles.CdCancelBtn>
                <Styles.CdConfirmBtn
                    onClick={handleConfirm}
                    isDisabled={isLoading}
                >
                    {isLoading ? <BtnLoader /> : 'Confirm'}
                </Styles.CdConfirmBtn>
            </Styles.CdBtns>
        </Styles.CdContainer>
    );
};
