import styled from 'styled-components';
import { Button } from '@ui/components/Button';

export const Errd = styled.div`
    padding: 100px 0 50px 0;
    min-height: 100vh;
`;

export const ErrdContainer = styled.div`
    border: 1px solid #d9d9d9;
    position: relative;
`;

export const ErrdRulesInformation = styled.div`
    padding: 30px;
`;

export const ErrdIcon = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const ErrdHeader1 = styled.div`
    font: normal normal bold 18px/24px Roboto;
`;

export const ErrHeader2 = styled.div`
    font: normal normal bold 16px/24px Roboto;
`;

export const ErrdRulesListInfo = styled.div`
    margin-top: 30px;
`;

export const ErrdRulesList = styled.div`
    margin-top: 30px;
    font: normal normal normal 16px/24px Roboto;
`;

export const ErrdSaveBtnContainer = styled.div`
    background-color: #e9e9e9;
    margin-top: 100px;
`;

export const ErrdSaveBtn = styled(Button)`
    margin-left: auto;
    width: 158px;
    height: 43px;
`;
