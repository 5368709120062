import { RoundCheckbox } from '@ui/components/RoundCheckbox';
import { useAppDispatch, useAppSelector } from '@ui/app/hooks';
import { toggleIsGradient } from '@ui/slices/colorStudioSlice';
import * as Styles from './style';

export const ColorTypeChoice = () => {
    const { isGradient } = useAppSelector((state) => state.colorStudio);

    const dispatch = useAppDispatch();

    return (
        <Styles.CtcContainer>
            <Styles.CtcHeader>COLOR TYPE</Styles.CtcHeader>

            <Styles.CtcDesc>
                Select between the single color or a gradient color
            </Styles.CtcDesc>

            <Styles.CtcCheckBox>
                <RoundCheckbox
                    labelText="SINGLE COLOR"
                    checked={!isGradient}
                    onChange={() => dispatch(toggleIsGradient())}
                />
                <RoundCheckbox
                    labelText="GRADIENT COLOR"
                    checked={isGradient}
                    onChange={() => dispatch(toggleIsGradient())}
                />
            </Styles.CtcCheckBox>
        </Styles.CtcContainer>
    );
};
