import styled from 'styled-components';

export const PpWrapper = styled.div`
    min-height: 100vh;
    padding: 100px 0 20px 0;
`;

export const PpContainer = styled.div`
    width: 100%;
`;
