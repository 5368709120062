export enum PROFILE_TABS {
    ABOUT = 'ABOUT',
    EDIT = 'EDIT',
    UPDATE_PASSWORD = 'UPDATE_PASSWORD',
    SUBSCRIPTION = 'SUBSCRIPTION',
}

export type UserProfile = {
    userFirstName: string;
    userLastName: string;
    userBio: string;
    userDesignation: string;
    userEmail: string;
    userProfileImagePath: string | undefined;
    userBannerImagePath: string | undefined;
};

export type SUB_FEATURES = {
    subFeatureName: string;
    isSubFeatureActive: boolean;
};

export type SUBSCRIPTION_PLANS_FEATURE = {
    featureName: string;
    featureDescription: string;
    isFeatureActive: boolean;
    subFeatures: SUB_FEATURES[];
};

export type SUBSCRIPTION_PLANS = {
    planName: string;
    isPlanAvailable: boolean;
    planPrice: string;
    features: SUBSCRIPTION_PLANS_FEATURE[];
};

export type CREATE_SUBSCRIPTION = {
    subscriptionPlanId: number;
};
