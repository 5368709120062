import { SUBSCRIPTION_PLANS, SUB_FEATURES } from '@ui/types/Profile';
import * as Styles from './style';

type PROPS = {
    handleBuy: (planName: string) => void;
};

type SUBSCRIPTION_PLAN_PROPS = SUBSCRIPTION_PLANS & PROPS;

export const SubscriptionPlan = (props: SUBSCRIPTION_PLAN_PROPS) => {
    const { planName, planPrice, features, handleBuy } = props;

    const renderSubFeature = (subFeature: SUB_FEATURES[]) => {
        if (Array.isArray(subFeature)) {
            return (
                <Styles.SpFeatureList>
                    {subFeature
                        .filter((v) => v?.isSubFeatureActive)
                        .map((v) => (
                            <Styles.SpSubFeatureDesc key={v?.subFeatureName}>
                                {v?.subFeatureName}
                            </Styles.SpSubFeatureDesc>
                        ))}
                </Styles.SpFeatureList>
            );
        }

        return null;
    };

    const renderFeatures = () => {
        if (Array.isArray(features)) {
            return features
                .filter((v) => v?.isFeatureActive)
                .map((v) => (
                    <Styles.SpFeatureDesc key={v?.featureDescription}>
                        {v?.featureDescription}

                        {renderSubFeature(v?.subFeatures)}
                    </Styles.SpFeatureDesc>
                ));
        }

        return null;
    };

    return (
        <Styles.SpContainer>
            <Styles.SpContainerHeader>{planName}</Styles.SpContainerHeader>

            <Styles.SpPriceText>
                {planPrice === 'TBD' ? 'CUSTOM' : `$ ${planPrice}`}
            </Styles.SpPriceText>

            {renderFeatures()}

            <Styles.SpBuyBtn onClick={() => handleBuy(planName)}>
                Contact Us
            </Styles.SpBuyBtn>
        </Styles.SpContainer>
    );
};
