import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { TextInput } from '@ui/components/TextInput';
import * as Styles from './style';

interface AddTeamMembersProps {
    handleAdd: (email: string) => void;
    disabledAddBtn: boolean;
}

interface AddTeamMemberValues {
    email: string;
}

const initialValues: AddTeamMemberValues = {
    email: '',
};

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter email').required('Email is required'),
});

export const AddTeamMember = (props: AddTeamMembersProps) => {
    const { handleAdd, disabledAddBtn } = props;

    const handleSubmit = (values: AddTeamMemberValues) => {
        handleAdd(values.email);
    };

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <Styles.AtmHeader>Add Team Members</Styles.AtmHeader>
                        <Styles.AtmContainer>
                            <TextInput
                                type="text"
                                name="email"
                                id="email"
                                placeholder="Enter Email"
                                showIcon={false}
                            />
                            <Styles.AtmSubmitBtn
                                type="submit"
                                isDisabled={disabledAddBtn}
                            >
                                Add Team Member
                            </Styles.AtmSubmitBtn>
                        </Styles.AtmContainer>

                        <Styles.AtmErrMsg>
                            <ErrorMessage name="email" />
                        </Styles.AtmErrMsg>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
