import styled from 'styled-components';

export const HcWrapper = styled.div`
    a {
        text-decoration: none;
    }
`;

export const HcContainer = styled.div`
    cursor: pointer;
    padding: 14px 12px 18px 12px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.9);
    transition: 300ms all;
    text-decoration: none !important;

    &:hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
`;

export const HcProjectName = styled.div`
    color: #2a2a30;
    font-family: 'Poppins-Bold';
    font-size: 20px;
`;

export const HcHeaderContainer = styled.div`
    margin-top: 16px;
    display: grid;
    grid-template-columns: 16px minmax(0, 1fr);
    align-items: center;
    grid-gap: 6px;
`;

export const HcHeaderContainer2 = styled(HcHeaderContainer)`
    margin-top: 14px;
`;

export const HcHeaderIcon = styled.div`
    width: 16px;
    height: 16px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const HcHeaderText = styled.div`
    color: #2a2a30;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    opacity: 0.7;
`;

export const HcNameText = styled.div`
    padding-left: 22px;
    color: #2a2a30;
    font-family: 'Poppins-Medium';
    font-size: 16px;
`;

export const HcLine = styled.div`
    width: 100%;
    height: 1px;
    margin: 18px 0;
    background-color: #e9edf1;
`;

type HcColorProps = {
    $backgroundColor: string;
    $index: number;
};

export const HcColor = styled.div<HcColorProps>`
    height: 53px;
    width: 53px;
    border-radius: 50%;
    border: 2px solid #2a2a30;
    background-color: ${({ $backgroundColor }) => $backgroundColor};

    position: absolute;
    left: ${({ $index }) => `${$index * 38}px`};
    transition: 300ms all;

    &:hover {
        transform: scale(1.2);
        z-index: 1000;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 16px;
        color: rgb(0, 0, 0);
        font-family: 'Poppins-Bold';
    }
`;

export const HcColorContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: 53px;
`;
